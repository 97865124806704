import axiosInstance from "@/service/AxiosInstance";

import {SEARCH_SALEINVOCE_RETURN_ACTION,GET_SALEINVOCE_return_MUTATION,GET_SALEINVOCE_return_ACTION,RETURN_SALEINVOCE_MUTATION,RETURN_SALEINVOCE_ACTION,DELETE_SALEINVOCE_ALL_ACTION,DELETE_SALEINVOCE_ACTION,DELETE_SALEINVOCE_MUTATION,EDIT_SALEINVOCE_MUTATION,EDIT_SALEINVOCE_ACTION,GET_SALEINVOCE_DETAIL_ACTION,GET_SALEINVOCE_DETAIL_MUTATION, SEARCH_SALEINVOCE_ACTION, GET_SALEINVOCE_ACTION, GET_SALEINVOCE_MUTATION, POST_SALEINVOCE_ACTION, POST_SALEINVOCE_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {

    async [GET_SALEINVOCE_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoices: response.data.data
            }

            context.commit(GET_SALEINVOCE_MUTATION, data)
        }
    },  
    
    async [GET_SALEINVOCE_return_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/return/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoicesReturn: response.data.data
            }

            context.commit(GET_SALEINVOCE_return_MUTATION, data)
        }
    }, 
    
    async [SEARCH_SALEINVOCE_RETURN_ACTION](context, payload) {
        let response = '';
        try {
            if(payload.sortDealer)
            {
                response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/return/index?sort_dealer=${payload.sortDealer}`);

            }else if(payload.sortProduct){

                response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/return/index?sort_product=${payload.sortProduct}`);
              }else{
                response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/return/index?search=${payload.text}`);

              }

        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoicesReturn: response.data.data
            }

            context.commit(GET_SALEINVOCE_return_MUTATION, data)
        }
    },
    
    async [RETURN_SALEINVOCE_ACTION](context, payload) {
        
        context.commit(RETURN_SALEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/sale_invoce/restore`,payload);
        } catch (e) {
                  
        context.commit(RETURN_SALEINVOCE_MUTATION, false)
            console.log(e);
            if (e.response.status = 408)
            throw e.response.data.message;

            return e
        }
        if (response.status == 200) {
            console.log(response.data);
       

            context.commit(RETURN_SALEINVOCE_MUTATION, false)
        }
    }, 
       
    async [EDIT_SALEINVOCE_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/edit/${payload}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoiceEdit: response.data.data
            }
            context.commit(EDIT_SALEINVOCE_MUTATION, data)
        }
    }, 
    
    async [GET_SALEINVOCE_DETAIL_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/invoce_detail/${payload.id}?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoiceDetails: response.data.data
            }
            context.commit(GET_SALEINVOCE_DETAIL_MUTATION, data)
        }
    }, 
    
    async [DELETE_SALEINVOCE_ACTION](context, payload) {
        context.commit(DELETE_SALEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/sale_invoce/delete/${payload.id}`);
        } catch (error) {
            context.commit(DELETE_SALEINVOCE_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
        
            context.commit(DELETE_SALEINVOCE_MUTATION, false)
        }
    },  
    
    async [DELETE_SALEINVOCE_ALL_ACTION](context, payload) {
        context.commit(DELETE_SALEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/sale_invoce/delete/all/${payload.id}`);
        } catch (error) {
            context.commit(DELETE_SALEINVOCE_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
        
            context.commit(DELETE_SALEINVOCE_MUTATION, false)
        }
    },

    async [SEARCH_SALEINVOCE_ACTION](context, payload) {
        let response = '';
        try {
            if(payload.sortDealer)
            {
                response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/index?sort_dealer=${payload.sortDealer}`);

            }else if(payload.sortProduct){

                response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/index?sort_product=${payload.sortProduct}`);
              }else{
                response = await axiosInstance.get(`${BASE_URL}/app/sale_invoce/index?search=${payload.text}`);

              }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoices: response.data.data
            }
            context.commit(GET_SALEINVOCE_MUTATION, data)
        }
    },

    async [POST_SALEINVOCE_ACTION](context, payload) {
        context.commit(POST_SALEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/sale_invoce/create`, payload);
        } catch (e) {
            context.commit(POST_SALEINVOCE_MUTATION, false)
            console.log(e);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
            return e
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_SALEINVOCE_MUTATION, false)
        }

    },




}