import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            dealerOpenings : [],
            reportData: [],
            dealerOpeninLoaded:false,
            deleting:false,
            reportLoaded:false,
            postOpen:false,
            sumCredit:0,
            sumDebit:0,
            openingPalance:{},

        }
    },
    mutations,
    getters,
    actions,
}
