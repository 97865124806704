import {DELETEING_WHOLESALE_MUTATION,POST_WHOLESALE_MUTATION,GET_PRODUCT_WHOLESALE_ORDER_MUTATION,GET_WHOLESALE_MUTATION,GET_ALL_WHOLESALE_MUTATION} from "@/store/storeConstants";
export default {
    [GET_WHOLESALE_MUTATION](state, payload) {
        state.wholeSales = payload.wholeSales;
        state.wholeSaleLoaded = payload.loaded;
    },
    [POST_WHOLESALE_MUTATION](state, payload) {
        state.postWholesale = payload
    },
    [DELETEING_WHOLESALE_MUTATION](state, payload) {
        state.deleteing = payload
    },
    [GET_ALL_WHOLESALE_MUTATION](state, payload) {
        state.AllwholeSales = payload.AllwholeSales
    },
     [GET_PRODUCT_WHOLESALE_ORDER_MUTATION](state, payload) {
        state.wholeSaleDetail = payload.wholeSaleDetail
    },
}