import axiosInstance from "@/service/AxiosInstance";
import External from "@/service/External";
import {GET_BOUNS_CLIENT_BY_ID_MUTATION,GET_BOUNS_CLIENT_BY_ID_ACTION,POST_BOUNS_CLIENT_MUTATION,GET_CLIENTS_DETAIL_BOUNS_MUTATION,GET_BOUNS_CLIENT_DETAIL_MUTATION,BOUNS_CLIENT_LOADED_MUTATION,SEARCH_BONUS_CLIENT_ACTION,DELETE_BOUNS_CLIENT_ONE_ACTION,DELETEING_BOUNS_CLIENT_ONE_MUTATION,DELETEING_BOUNS_CLIENT_MUTATION,DELETE_BOUNS_CLIENT_ACTION,UPDATE_BOUNS_CLIENT_ACTION,POST_CLIENT_BOUNS_ACTION,SEARCH_BONUS_CLIENT_DETAIL_ACTION,GET_CLIENTS_BONUS_DETAIL_ACTION,GET_BONUS_CLIENT_DETAIL_ACTION,GET_BONUS_CLIENT_ACTION, BASE_URL } from "@/store/storeConstants";
export default {

    async [GET_BONUS_CLIENT_ACTION](context, payload) {
       
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/bonus/index?page=${payload.currentPage}`);
        } catch (e) {
         
            return e;
        }
        if (response.status == 200) {
            console.log(response);
            context.commit(BOUNS_CLIENT_LOADED_MUTATION, {
                bonusClients: response.data.data,
                bonusClientLoaded: true
            });
          
        }

    },
    async [GET_BOUNS_CLIENT_BY_ID_ACTION](context, payload) {
    
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/bonus/get_bonus_clinet/${payload.productId}/${payload.clientId}`);
        } catch (e) {
           console.log(e);
            return e;
        }
        if (response.status == 200) {
          
            context.commit(GET_BOUNS_CLIENT_BY_ID_MUTATION, {
                protacionBonusId: response.data.data[0]? response.data.data[0] : 0,
            });
           External.emit('bounsLoaded')
        }

    },

     async [GET_BONUS_CLIENT_DETAIL_ACTION](context, payload) {
        context.commit(GET_BOUNS_CLIENT_DETAIL_MUTATION, {
            bonusClientDetailLoaded: false
        });
        const id = payload.id;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/bonus/show/${id}?page=${payload.currentPage}`);
        } catch (e) {
            context.commit(GET_BOUNS_CLIENT_DETAIL_MUTATION, {
                bonusClientDetailLoaded: true
            });
            console.log(e);
            return e;
        }
        if (response.status == 200) {
    
            context.commit(GET_BOUNS_CLIENT_DETAIL_MUTATION, {
                bonusClientDetails: response.data.data,
                bonusClientDetailLoaded: true
            });
    
          
        }

    }, 

    async [GET_CLIENTS_BONUS_DETAIL_ACTION](context, payload) {
    
        const id = payload.productId;
        let response = '';
        try {
          
            response = await axiosInstance.get(`${BASE_URL}/app/bonus/get_bonus/${id}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
       
            context.commit(GET_CLIENTS_DETAIL_BOUNS_MUTATION, {
                bonusClients: response.data.data,
                bonusClientsLoaded: true
            });

        }
    }, 

     async [SEARCH_BONUS_CLIENT_DETAIL_ACTION](context, payload) {
        
        const id = payload.id;

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/bonus/show/${id}?search=${payload.text}`);
        } catch (e) {

            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(GET_BOUNS_CLIENT_DETAIL_MUTATION, {
                bonusClientDetails: response.data.data,
                bonusClientDetailLoaded: true
            });
        }

    },

    async [POST_CLIENT_BOUNS_ACTION](context, payload) {
        context.commit(POST_BOUNS_CLIENT_MUTATION,true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/bonus/create`,payload);
        } catch (e) {
            context.commit(POST_BOUNS_CLIENT_MUTATION,false)
            console.log(e);
            if(e.response.status == 500){
                throw 'اسم المندوب مضاف مسبقا للموديل' ;
            }
           return 
        }
        if (response.status == 200) {
            context.commit(POST_BOUNS_CLIENT_MUTATION,false)
            console.log(response.data);
          
        }

    }, 
    
    async [UPDATE_BOUNS_CLIENT_ACTION](context, payload) {
        context.commit(POST_BOUNS_CLIENT_MUTATION,true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/bonus/update`,payload);
        } catch (e) {
            context.commit(POST_BOUNS_CLIENT_MUTATION,false)
            console.log(e);
            if(e.response.status == 500){
                throw 'اسم المندوب مضاف مسبقا للموديل' ;
            }
           return 
        }
        if (response.status == 200) {
            context.commit(POST_BOUNS_CLIENT_MUTATION,false)
            console.log(response.data);
          
        }

    },

    async [DELETE_BOUNS_CLIENT_ACTION](context, payload) {
        context.commit(DELETEING_BOUNS_CLIENT_MUTATION, true);
        const id = payload.bonusId;

        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/bonus/delete/${id}`)
        } catch (e) {
            context.commit(DELETEING_BOUNS_CLIENT_MUTATION, false)
           
            if(e.response.status == 500)
            throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            
            context.commit(DELETEING_BOUNS_CLIENT_MUTATION, false)
        }

    }, 

    async [DELETE_BOUNS_CLIENT_ONE_ACTION](context, payload) {
    
        context.commit(DELETEING_BOUNS_CLIENT_ONE_MUTATION, true);
 
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/bonus/delete_one/${payload.clientId}`)
        } catch (e) {
        console.log(e);
            context.commit(DELETEING_BOUNS_CLIENT_ONE_MUTATION, false)
            if(e.response.status == 500)
            throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(DELETEING_BOUNS_CLIENT_ONE_MUTATION, false)
        }

    },

    async [SEARCH_BONUS_CLIENT_ACTION](context, payload) {
        
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/bonus/index?search=${payload.text}`)
        } catch (e) {
           
            return e
            if(e.response.status == 500)
            throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(BOUNS_CLIENT_LOADED_MUTATION, {
                bonusClients: response.data.data,
                bonusClientLoaded: true
            });
          
        }

    },  


    
}