export const BASE_URL = 'https://app.f-dahab.com/api';

////////////////////Auth//////////////
export const EDIT_IMAGE_ACTION = '[actions] edit image acton';
export const EDIT_IMAGE_MUTATION = '[mutations] edit image acton';
export const LOGIN_ACTION = '[actions] login user';
export const SHOW_LOADING = '[mutations] show loading';
export const SET_USER_TOKEN_MUTATION = '[mutations] set user token data';
export const LOGGED_IN_USER = '[mutations] Loggedin user';
export const AUTO_LOGIN_USER = '[mutations] auto login user';
export const IS_USER_AUTH_GETTERs = '[getters] is user auth';
export const USER_LOG_OUT = '[actions] user logout';
export const GET_USERS_ACTION = '[actions] get users';
export const USERS_LOADED_MUTATION = '[actions] users loaded';
export const POST_USER_ACTION = '[actions] post user';
export const UPDATE_USER_ACTION = '[actions] update user';
export const POST_USER_MUTATION = '[mutations] update user';
export const DELETE_USER_ACTION = '[actions] delete user';
export const DELETING_USER_mutations = '[mutations] deleting user';
export const LOG_OUT_USER_MUTATION = '[mutations] logout user mutation';
export const SEARCH_USER_ACTION = '[actions] search user mutation';

////////////////////Dealer//////////////
export const GET_DEALER_ACTION = '[actions] get dealers';
export const GET_ALL_DEALER_ACTION = '[actions] get all dealers';
export const GET_ALL_DEALER_MUTATION = '[MUTATION] get all dealers';
export const DEALERS_LOADED_MUTATION = '[mutations] dealers loaded';
export const POST_DEALER_ACTION = '[actions] post dealer';
export const UPDATE_DEALER_ACTION = '[actions] update dealer';
export const POST_DEALER_MUTATION = '[mutations] post dealer';
export const DELETE_DEALER_ACTION = '[actions] delete dealer';
export const DELETING_DEALER_mutations = '[mutations] deleting dealer';
export const SEARCH_DEALER_ACTION = '[actions] search dealer mutation';

////////////////////Region//////////////
export const GET_REGION_INERNAL_ACTION = '[actions] get region internal';
export const GET_ALL_REGION_INERNAL_ACTION = '[actions] get all internal region';
export const GET_ALL_REGION_INERNAL_MUTATION = '[mutations] get all internal region';
export const GET_REGION_EXTERNAL_ACTION = '[actions] get region external';
export const GET_PROVINCE_ACTION = '[actions] get province external';
export const GET_PROVINCE_REGION_ACTION = '[actions] get province region external';
export const GET_PROVINCE_REGION_MUTATION = '[mutations] get province region external';
export const REGION_INERNAL_LOADED_MUTATION = '[mutations] region internal loaded';
export const REGION_EXTERNAL_LOADED_MUTATION = '[mutations] region external loaded';
export const PROVINCE_LOADED_MUTATION = '[mutations] province loaded';
export const POST_PROVINCE_ACTION = '[actions] post province';
export const POST_INTERNAL_REGION_ACTION = '[actions] post internal region';
export const POST_EXTERNA_REGION_ACTION = '[actions] post external region';
export const UPDATE_PROVINCE_ACTION = '[actions] update province';
export const UPDATE_INTERNAL_REGION_ACTION = '[actions] update internal region';
export const UPDATE_EXTERNAL_REGION_ACTION = '[actions] update external region';
export const POST_PROVINCE_MUTATION = '[mutations] post province';
export const POST_INTERNAL_REGION_MUTATION = '[mutations] post internal region ';
export const POST_EXTERNAL_REGION_MUTATION = '[mutations] post external region ';
export const DELETE_PROVINCE_ACTION = '[actions] delete province';
export const DELETE_REGION_ACTION = '[actions] delete region';
export const DELETING_PROVINCE_MUTATION = '[mutations] deleting province';
export const DELETING_REGION_MUTATION = '[mutations] deleting region';
export const SEARCH_REGION_INERNAL_ACTION = '[actions] search internal region province';
export const SEARCH_REGION_EXTERNAL_ACTION = '[actions] search external region province';
export const SEARCH_PROVINCE_ACTION = '[actions] search province ';

////////////////////Client//////////////
export const GET_CLIENT_ACTION = '[actions] get clients';
export const GET_ALL_CLIENT_ACTION = '[actions] get all clients';
export const GET_ALL_CLIENT_MUTATION = '[MUTATION] get all clients';
export const CLIENTS_LOADED_MUTATION = '[mutations] clients loaded';
export const POST_CLIENT_ACTION = '[actions] post client';
export const UPDATE_CLIENT_ACTION = '[actions] update client';
export const POST_CLIENT_MUTATION = '[mutations] post client';
export const DELETE_CLIENT_ACTION = '[actions] delete client';
export const DELETING_CLIENT_mutations = '[mutations] deleting client';
export const SEARCH_CLIENT_ACTION = '[actions] search client mutation';


////////////////////Bonus//////////////
export const GET_BONUS_CLIENT_ACTION = '[actions] get protaction client';
export const GET_BONUS_CLIENT_DETAIL_ACTION = '[actions] get protaction bonus detail client';
export const GET_CLIENTS_BONUS_DETAIL_MUTATION = '[mutations] get clients bonus detail';
export const GET_CLIENTS_BONUS_DETAIL_ACTION = '[actions] get clients bonus detail';
export const SEARCH_BONUS_CLIENT_ACTION = '[actions] search protaction bonus client';
export const SEARCH_BONUS_CLIENT_DETAIL_ACTION = '[actions] search protaction bonus client detail';
export const PROTACTION_BONUS_CLIENT_LOADED_MUTATION = '[mutations] protaction bonus client loaded';
export const POST_CLIENT_BOUNS_ACTION = '[actions] post client bonus';
export const POST_CLIENT_BOUNS_MUTATION = '[mutations] post client bonus';
export const UPDATE_BOUNS_CLIENT_ACTION = '[actions] update bonus client';
export const DELETE_BOUNS_CLIENT_ACTION = '[actions] delete bonus client action';
export const DELETE_BOUNS_CLIENT_ONE_ACTION = '[actions] delete bonus client one action';
export const DELETEING_BOUNS_CLIENT_MUTATION = '[mutations] deleteing bonus client';
export const DELETEING_BOUNS_CLIENT_ONE_MUTATION = '[mutations] deleteing bonus one client';
export const BOUNS_CLIENT_LOADED_MUTATION = '[mutations] bonus client loaded';
export const GET_BOUNS_CLIENT_DETAIL_MUTATION = '[mutations] get bonus detail client';
export const GET_CLIENTS_DETAIL_BOUNS_MUTATION = '[mutations] get clients bonus detail';
export const POST_BOUNS_CLIENT_MUTATION = '[mutations] post bonus client';
export const GET_BOUNS_CLIENT_BY_ID_ACTION = '[actions] get bonus client by id';
export const GET_BOUNS_CLIENT_BY_ID_MUTATION = '[MUTATION ] get bonus client by id';

////////////////////Protaction//////////////
export const GET_PROTACTION_CLIENT_ACTION = '[actions] get protaction client';
export const GET_PROTACTION_DEALER_ACTION = '[actions] get protaction dealer ';
export const GET_PROTACTION_CLIENT_DETAIL_ACTION = '[actions] get protaction detail client';
export const GET_PROTACTION_DEALER_DETAIL_ACTION = '[actions] get protaction detail dealer';
export const GET_CLIENTS_DETAIL_ACTION = '[actions] get clients detail';
export const GET_DEALERS_DETAIL_ACTION = '[actions] get dealers detail';
export const GET_CLIENTS_DETAIL_MUTATION = '[mutations] get clients detail';
export const GET_DEALERS_DETAIL_MUTATION = '[mutations] get dealers detail';
export const SEARCH_PROTACTION_CLIENT_ACTION = '[actions] search protaction client';
export const SEARCH_PROTACTION_DEALER_ACTION = '[actions] search protaction dealer';
export const SEARCH_PROTACTION_CLIENT_DETAIL_ACTION = '[actions] search protaction client detail';
export const SEARCH_PROTACTION_DEALER_DETAIL_ACTION = '[actions] search protaction dealer detail';
export const GET_PROTACTION_CLIENT_DETAIL_MUTATION = '[mutations] get protaction detail client';
export const GET_PROTACTION_DEALER_DETAIL_MUTATION = '[mutations] get protaction detail dealer';
export const PROTACTION_CLIENT_LOADED_MUTATION = '[mutations] protaction client loaded';
export const PROTACTION_DEALER_LOADED_MUTATION = '[mutations] protaction dealer loaded';
export const GET_PROTACTION_CLIENT_BY_ID_ACTION = '[actions] get protaction client by id';
export const GET_PROTACTION_DEALER_BY_ID_ACTION = '[actions] get protaction dealer by id';
export const GET_PROTACTION_DEALER_BY_ID_MUTATION = '[actions] get protaction dealer by id';
export const GET_PROTACTION_CLIENT_BY_ID_MUTATION = '[MUTATION ] get protaction client by id';
export const POST_PROTACTION_CLIENT_ACTION = '[actions] post protaction client';
export const POST_PROTACTION_DEALER_ACTION = '[actions] post protaction dealer';
export const UPDATE_PROTACTION_CLIENT_ACTION = '[actions] update protaction client';
export const UPDATE_PROTACTION_DEALER_ACTION = '[actions] update protaction dealer';
export const DELETE_PROTACTION_CLIENT_ACTION = '[actions] delete protaction client action';
export const DELETE_PROTACTION_DEALER_ACTION = '[actions] delete protaction dealer action';
export const DELETE_PROTACTION_CLIENT_ONE_ACTION = '[actions] delete protaction client one action';
export const DELETE_PROTACTION_DEALER_ONE_ACTION = '[actions] delete protaction dealer one action';
export const DELETEING_PROTACTION_CLIENT_MUTATION = '[mutations] deleteing protaction client';
export const DELETEING_PROTACTION_DEALER_MUTATION = '[mutations] deleteing protaction dealer';
export const DELETEING_PROTACTION_CLIENT_ONE_MUTATION = '[mutations] deleteing protaction one client';
export const DELETEING_PROTACTION_DEALER_ONE_MUTATION = '[mutations] deleteing protaction one dealer';
export const POST_PROTACTION_CLIENT_MUTATION = '[mutations] post protaction client';
export const POST_PROTACTION_DEALER_MUTATION = '[mutations] post protaction dealer';


//////////////////Categories/////////////////

export const GET_CATEGORIES_ACTION = '[actions] get categorais';
export const CATEGORY_LOADED_MUTATION = '[mutations] category loaded mutation';


////////////Products/////////////////

export const GET_PRODUCTS_ACTION = '[actions] get products action';
export const GET_PRODUCT_MOVMENT_ACTION = '[actions] get products movment action';
export const GET_PRODUCT_MOVMENT_MUTATION = '[mutation] get products movment action';
export const ALL_PRODUCTS_ACTION = '[actions] get all product action';
export const GET_PRODUCTS_BY_ID_INVOICE_RETURN_ACTION = '[actions] get all product return invoce action';
export const ALL_PRODUCTS_MUTATION = '[mutation] get all product mutation';
export const GET_PRODUCTS_BY_ID_ACTION = '[actions] get products by id action';
export const GET_PRODUCTS_BY_ID_INVOICE_ACTION = '[actions] get products by id invoce action';
export const GET_PRODUCTS_BY_ID_INVOICE_MUTATION = '[mutation] get products by invoce id action';
export const GET_PRODUCTS_BY_ID_MUTATION = '[mutation] get products by id action';
export const GET_ALL_PRODUCTS_ACTION = '[actions] get all products action';
export const GET_ALL_PRODUCTS_WITHOUT_PROTACTION_CLIENT_ACTION = '[actions] get all  products without protaction client action';
export const GET_ALL_PRODUCTS_WITHOUT_PROTACTION_DEALER_ACTION = '[actions] get all  products without protaction dealer action';
export const GET_PRODUCTS_MUTATION = '[mutation] get products mutation';
export const GET_PRODUCTS_DEALER_MUTATION = '[mutation] get products dealer mutation';
export const GET_ALL_PRODUCTS_MUTATION = '[mutation] get all products mutation';
export const SEARCH_PRODUCT_ACTION = '[actions] search product  action';
export const FILTER_PRODUCT_ACTION = '[actions] filter product  action';
export const POST_PRODUCT_ACTION = '[actions] post product action';
export const EDIT_PRODUCT_ACTION = '[actions] edit product ';
export const DELETE_PRODUCT_COLOR_ACTION = '[action] delete products color action';
export const DELETE_PRODUCT_SIZE_ACTION = '[action] delete products size action';
export const PODUCT_DETAIL_ACTION = '[action] product detail action';
export const PODUCT_DETAIL_MUTATION = '[mutation] product detail mutation';
export const SEARCH_PRODUCT_NUMBER_ACTION = '[actions] search product number action';
export const GET_COUNT_PRODUCT_ACTION = ' [actions] get count product action';
export const GET_COUNT_PRODUCT_MUTATION = ' [mutations] get count product action';
export const GET_SIZE_ACTION = ' [actions] get size action';
export const SIZE_LOADED_MUTATION = '[mutations] size loaded';
export const COLOR_LOADED_MUTATION = '[mutations] color loaded';
export const POSTING_PORDUCT_MUTATION = '[mutation] posting product mutation';
export const UPDATING_PORDUCT_MUTATION = '[mutation] updating product mutation';
export const DELETE_PRODUCT_ACTION = '[action] delete products action';
export const GET_ID_PRODUCT_GETTERS = '[getter] get id product';
export const GET_ID_PRODUCT_INVOCE_GETTERS = '[getter] get id product invoice';
export const GET_COLOR_PRODUCT_GETTERS = '[getter] get clolor product';
export const PODUCT_DELETING_DETAIL_MUTATION = '[mutation] product deleting detail mutation';


//////////////productNumber//////////////
export const POST_PRODUCT_CODE_ACTION = '[actions] post product code';
export const GET_CODE_BY_ID_ACTION = '[actions] get code by id';
export const GET_CODE_BY_ID_MUTATION = '[mutations] get code by id';
export const GET_CODE_PRODUCT_ACTION = '[actions] get code product';
export const GET_ALL_CODE_PRODUCT_ACTION = '[actions] get all code product';
export const GET_ALL_CODE_PRODUCT_MUTATION = '[MUTATION] get all code product';
export const CODE_LOADED_MUTATION = '[mutations] code loaded';
export const POST_PRODUCT_CODE_MUTATION = '[mutations] post product mutation';
export const DELETING_PRODUCT_CODE_MUTATION = '[mutations] deleting product mutation';
export const EDIT_PRODUCT_CODE_ACTION = '[actions] edit product code';
export const DELETE_PRODUCT_CODE_ACTION = '[actions] delete product code';



///////////////////Color/////////////
export const GET_COLOR_PAGE_ACTION = '[actions] get color page action';
export const POST_COLOR_ACTION = '[actions] post color';
export const SEARCH_COLOR_ACTION = '[actions] search color';
export const EDIT_COLOR_ACTION = '[actions] edit color';
export const DELETE_COLOR_ACTION = '[actions] delete color';

///////////////////Size/////////////
export const GET_SIZE_PAGE_ACTION = '[actions] get size page action';
export const GET_SIZE_BY_ID_ACTION = '[actions] get  size by id action';
export const GET_SIZE_BY_ID_MUTATION = '[mutation] get  size by id action';
export const POST_SIZE_ACTION = '[actions] post size ';
export const SEARCH_SIZE_ACTION = '[actions] search size ';
export const POST_SIZE_MUTATION = '[mutation] post size ';
export const EDIT_SIZE_ACTION = '[actions] edit size ';
export const DELETE_SIZE_ACTION = '[actions] delete size ';


///////////////////Order/////////////
export const GET_ORDER_INERNAL_ACTION = '[actions] get order internal action';
export const RETURN_ONE_ORDER_ACTION = '[actions] return one order  action';
export const RETURN_ONE_ORDER_MUTATION = '[mutations] return one order action';
export const GET_ORDER_INERNAL_MUTATION = '[mutations] get order internal mutations';
export const GET_RETURN_ORDER_INERNAL_ACTION = '[actions] get return order internal action';
export const GET_RETURN_ORDER_EXTERNAL_ACTION = '[actions] get return order external action';
export const GET_RETURN_ORDER_EXTERNAL_MUTATION = '[mutations] get return order external mutations';
export const GET_RETURN_ORDER_INERNAL_MUTATION = '[mutations] get return order internal mutations';
export const RETURN_ORDER_ACTION = '[actions] return order action';
export const RETURN_ORDER_MUTATION = '[mutations] return order mutation';
export const GET_ORDER_INERTNAL_MUTATION = '[mutations] get order internal action';
export const GET_ORDER_GETTERS = '[getter] get order ';
export const GET_EDIT_ORDER_ACTION = '[actions] get order edit action';
export const GET_EDIT_ORDER_MUTATION = '[mutations] get order edit mutations ';
export const ORDER_STATUS_ACTION = '[actions]  order status  action';
export const ORDER_CHARGED_STATUS_ACTION = '[actions]  order status charged action';
export const ORDER_MOVED_STATUS_ACTION = '[actions]  order status moved action';
export const UPLOADE_RECEIPT_ACTION = '[actions] uploade receipt order action';
export const UPLOADE_RECEIPT_MUTATION = '[mutations] uploade receipt order action';
export const DELETE_IMAGE_RECEIPT_ACTION = '[actions] delete image receipt action';
export const DELETE_IMAGE_RECEIPT_MUTATION = '[mutations] delete image receipt action';
export const ORDER_MOVED_STATUS_MUTATION = '[mutations]  order status moved action';
export const ORDER_CHARGED_STATUS_MUTATION = '[mutations]  order status charged action';
export const ORDER_STATUS_MUTATION = '[mutations]  order status  mutation';
export const GET_ORDER_EXTERNAL_ACTION = '[actions] get order external action';
export const GET_ORDER_EXTERNAL_MUTATION = '[mutations] get order external mutations';
export const ADD_RECEIPT_ORDER_EXTERNAL_ACTION = '[actions] add receipt order external action';
export const ADD_RECEIPT_ORDER_EXTERNAL_MUTATION = '[mutations] add receipt order external action';
export const POST_ORDER_ACTION = '[actions]  post order action';
export const PRINT_ORDER_ACTION = '[actions]  print order action';
export const PRINT_ORDER_MUTATION = '[mutations]  print order action';
export const POST_ORDER_MUTATION = '[mutations] post order action';
export const GET_ORDER_BY_ID_ACTION = '[actions] get order by id action';
export const GET_ORDER_BY_ID_MUTATION = '[mutations] get order by id action';
export const SEARCH_ORDER_INTERNAL_ACTION = '[actions] search order internal action';
export const SEARCH_RETURN_ORDER_INTERNAL_ACTION = '[actions] search return order internal action';
export const SEARCH_RETURN_ORDER_EXTERNAL_ACTION = '[actions] search return order external action';
export const DELETE_RETURN_ORDER_ACTION = '[actions] delete return order';
export const DELETEING_RETURN_ORDER_MUTATION = '[mutations] deleteing return order ';
export const DELETE_ORDER_ACTION = '[actions] delete order';
export const DELETEING_ORDER_MUTATION = '[mutations] deleteing order ';
export const UPDATE_ORDER_ACTION = '[actions] update order ';
export const UPDATING_ORDER_MUTATION = '[mutation] updating order mutation';
export const FILTER_ORDER_INTERNAL_ACTION = '[actions] filter order internal action';
export const FILTER_ORDER_EXTERNAL_ACTION = '[actions] filter order external action';
export const FILTE_RETURN_ORDER_INTERNAL_ACTION = '[actions] filter return order internal action';
export const FILTE_RETURN_ORDER_EXTERNAL_ACTION = '[actions] filter return order external action';
export const EDIT_RECEIPT_ORDER_EXTERNAL_ACTION = '[actions] edit receipt order external action';

////////////orderWaiting//////////////////
export const GET_ORDER_WAITING_ACTION = '[actions] get order waiting action';
export const RETURN_ONE_ORDER_WAITING_ACTION = '[actions] return one order waiting  action';
export const GET_ORDER_WAITING_MUTATION = '[mutations] get order waiting action';
export const UPDATE_ORDER_WAITING_ACTION = '[actions] update order waiting action';
export const SEARCH_ORDER_WAITING_ACTION = '[actions] search order waiting action';
export const DELETE_WAITING_ORDER_ACTION = '[actions] delete waiting order';
export const DELETEING_WAITING_ORDER_MUTATION = '[mutations] deleteing waiting order';

///////////////////wholesale/////////////
export const GET_WHOLESALE_ACTION = '[actions] get wholesale  action';
export const GET_ALL_WHOLESALE_ACTION = '[actions] get all wholesale  action';
export const GET_ALL_WHOLESALE_MUTATION = '[mutations] get all wholesale mutations';
export const SHOW_WHOLESALE_ACTION = '[actions] show  wholesale actions';
export const SHOW_WHOLESALE_MUTATION = '[mutations] show  wholesale mutations';
export const GET_WHOLESALE_MUTATION = '[mutations] get wholesale  mutations';
export const POST_WHOLESALE_ACTION = '[actions]  post wholesale action';
export const SEARCH_WHOLESALE_ACTION = '[actions]  search wholesale action';
export const POST_WHOLESALE_MUTATION = '[mutations] post wholesale mutations';
export const DELETE_WHOLESALE_ACTION = '[actions] delete wholesale';
export const DELETEING_WHOLESALE_MUTATION = '[mutations] deleteing wholesale ';
export const UPDATE_WHOLESALE_ACTION = '[actions] update wholesale ';
export const UPDATING_WHOLESALE_MUTATION = '[mutation] updating wholesale mutation';
export const WHOLESALE_DETAIL_GETTERs = '[getters] wholesale detail getter';

///////////////////wholesaleOrder/////////////
export const GET_WHOLESALE_ORDER_ACTION = '[actions] get wholesale order action';
export const GET_ALL_WHOLESALE_ORDER_ACTION = '[actions] get all wholesale order  action';
export const GET_PRODUCT_BY_ID_WHOLESALE_ORDER_ACTION = '[actions] get product wholesale by id order  action';
export const GET_PRODUCT_WHOLESALE_ORDER_MUTATION = '[mutations] get product wholesale order  mutations';
export const GET_ALL_WHOLESALE_ORDER_MUTATION = '[mutations] get all wholesale order mutations';
export const GET_WHOLESALE_ORDER_MUTATION = '[mutations] get wholesale order  mutations';
export const POST_WHOLESALE_ORDER_ACTION = '[actions]  post wholesale order action';
export const POST_WHOLESALE_ORDER_MUTATION = '[mutations] post wholesale order mutations';
export const SEARCH_WHOLESALE_ORDER_MUTATION = '[actions] search wholesale order actions';
export const DELETE_WHOLESALE_ORDER_ACTION = '[actions] delete  wholesale order';
export const DELETEING_WHOLESALE_ORDER_MUTATION = '[mutations] deleteing order wholesale ';
export const UPDATE_WHOLESALE_ORDER_ACTION = '[actions] update wholesale order';
export const UPDATING_WHOLESALE_ORDER_MUTATION = '[mutation] updating wholesale order mutation';
export const RESET_STATE_WHOLESALE_ORDER_MUTATION = '[mutation] reset  state  wholesale mutation';
export const WHOLESALE_STATUS_ACTION = '[actions] wholesale  status action';
export const WHOLESALE_STATUS_MUTATION = '[mutation] wholesale  status mutation';
export const FILTER_WHOLESALE_ORDER_INTERNAL_ACTION = '[actions] filter wholesale order internal action';


///////////////////Ratio/////////////
export const GET_RATIO_ACTION = '[actions] get ratio  action';
export const ADD_RECEIPT_RATIO_ACTION = '[actions] add receipt ratio  action';
export const ADD_RECEIPT_RATIO_MUTATION = '[mutation] add receipt ratio  action';
export const EDIT_RECEIPT_RATIO_ACTION = '[actions] edit receipt ratio  action';
export const SEARCH_RECEIPT_RATIO_ACTION = '[actions] search receipt ratio  action';
export const SEARCH_RATIO_ACTION = '[actions] search  ratio  action';

export const EDIT_RECEIPT_RATIO_MUTATION = '[mutation] edit receipt ratio  action';
export const POST_RETURN_RATIO_ACTION = '[actions] post return ratio  action';
export const POST_RETURN_RATIO_MUTATION = '[mutation] post return ratio  action';
export const GET_RATIO_CLIENT_ACTION = '[actions] get ratio client action';
export const GET_RATIO_CLIENT_DETAIL_ACTION = '[actions] get ratio client detail action';
export const GET_RATIO_CLIENT_DETAIL_MUTATION = '[mutation] get ratio client detail action';
export const GET_RATIO_CLIENT_MUTATION = '[mutation] get ratio client action';
export const POST_RATIO_ACTION = '[actions] post ratio  action';
export const POST_RATIO_MUTATION = '[mutation] post ratio  action';
export const UPDATE_RATIO_ACTION = '[actions] update ratio  action';
export const UPDATE_RATIO_MUTATION = '[mutation] update ratio  action';
export const DELETE_RATIO_ACTION = '[actions] delete  ratio order';
export const DELETE_RATIO_MUTATION = '[mutation] delete  ratio order';
export const GET_RATIO_MUTATION = '[mutation] get ratio  MUTATION ';
export const RESET_RATIO_Action = '[actions] reset ratio  actions ';


////////////////////delivery//////////////
export const GET_DELIVERY_ACTION = '[actions] get delivery';
export const GET_ِALL_DELIVERY_ACTION = '[actions] get all delivery';
export const CHNANGE_ِSTATUS_DELIVERY_ACTION = '[actions] change status delivery';
export const CHNANGE_ِSTATUS_DELIVERY_MUTATION = '[mutations] change status delivery';
export const POST_DELIVERY_ORDER_ACTION = '[actions] post  delivery order';
export const POST_DELIVERY_ORDER_MUTATION = '[mutations] post  delivery order';
export const GET_ِALL_DELIVERY_ORDER_ACTION = '[actions] get all delivery order';
export const GET_ِALL_DELIVERY_ORDER_MUTATION = '[mutations] get all delivery order';
export const GET_ِALL_DELIVERY_MUTATION = '[mutations] get all delivery';
export const GET_DELIVERY_MUTATION = '[mutations] deliveries loaded';
export const POST_DELIVERY_ACTION = '[actions] post delivery';
export const POST_DELIVERY_MUTATION = '[mutations]post delivery ';
export const UPDATE_DELIVERY_ACTION = '[actions] update delivery';
export const DELETE_DELIVERY_ACTION = '[actions] delete delivery';
export const DELETING_DELIVERY_mutations = '[mutations] deleting delivery';
export const DELETING_DELIVERY_ORDER_ACTION = '[actions] deleting order delivery';
export const DELETING_DELIVERY_ORDER_mutations = '[mutations] deleting order delivery';
export const SEARCH_DELIVERY_ORDER_ACTION = '[actions] search delivery order ';
export const FILTER_DELIVERY_ORDER_ACTION = '[actions] filter delivery order ';
export const SEARCH_DELIVERY_ACTION = '[actions] search delivery ';

////////////////////SALEINVOCE//////////////
export const GET_SALEINVOCE_ACTION = '[actions] get saleinvoce';
export const RETURN_SALEINVOCE_ACTION = '[actions] return  saleinvoce';
export const RETURN_SALEINVOCE_MUTATION = '[mutations] return  saleinvoce';
export const GET_SALEINVOCE_return_ACTION = '[actions] get return saleinvoce';
export const GET_SALEINVOCE_return_MUTATION = '[mutations] get return saleinvoce';
export const SEARCH_SALEINVOCE_RETURN_ACTION = '[actions] search return  saleinvoce ';
export const SEARCH_SALEINVOCE_RETURN_MUTATION = '[mutations] search return saleinvoce ';
export const DELETE_SALEINVOCE_RETURN_ACTION = '[actions] delete return saleinvoce';
export const DELETE_SALEINVOCE_RETURN_MUTATION = '[mutations] delete return saleinvoce';
export const GET_SALEINVOCE_DETAIL_ACTION = '[actions] get saleinvoce detail';
export const GET_SALEINVOCE_DETAIL_MUTATION = '[mutations] get saleinvoce detail';
export const EDIT_SALEINVOCE_ACTION = '[actions] edit saleinvoce detail';
export const EDIT_SALEINVOCE_MUTATION = '[mutations] edit saleinvoce detail';
export const GET_SALEINVOCE_MUTATION = '[mutations] get saleinvoce ';
export const SEARCH_SALEINVOCE_ACTION = '[actions] search saleinvoce ';
export const SEARCH_SALEINVOCE_MUTATION = '[mutations] search saleinvoce ';
export const POST_SALEINVOCE_ACTION = '[actions] post saleinvoce';
export const POST_SALEINVOCE_MUTATION = '[mutations] post delivery ';
export const DELETE_SALEINVOCE_ACTION = '[actions] delete saleinvoce';
export const DELETE_SALEINVOCE_MUTATION = '[mutations] delete saleinvoce';
export const DELETE_SALEINVOCE_ALL_ACTION = '[actions] delete all saleinvoce';
export const DELETE_SALEINVOCE_ALL_MUTATION = '[mutations] delete all saleinvoce';

////////////purchaseInvoce////////////
export const GET_PURCHASEINVOCE_ACTION = '[actions] get purchaseInvoce';
export const GET_DETAIL_PURCHASEINVOCE_ACTION = '[actions] get detail purchaseInvoce';
export const GET_DETAIL_PURCHASEINVOCE_MUTATION = '[mutations] get detail purchaseInvoce';
export const RETURN_PURCHASEINVOCE_ACTION = '[actions] return_ purchaseInvoce';
export const RETURN_PURCHASEINVOCE_MUTATION = '[mutations] return_ purchaseInvoce';
export const GET_PURCHASEINVOCE_RETURN_ACTION = '[actions] get return purchaseInvoce';
export const FILTER_PURCHASEINVOCE_RETURN_ACTION = '[actions] filter return purchaseInvoce';
export const FILTER_PURCHASEINVOCE_RETURN_MUTATION = '[mutations] filter return purchaseInvoce';
export const GET_PURCHASEINVOCE_RETURN_MUTATION = '[mutations] get return purchaseInvoce';
export const GET_PURCHASEINVOCE_MUTATION = '[mutations] get purchaseInvoce';
export const SEARCH_PURCHASEINVOCE_ACTION = '[actions]  search purchaseInvoce ';
export const DELETE_PURCHASEINVOCE_ACTION = '[actions] delete purchaseInvoce';
export const DELETE_PURCHASEINVOCE_return_ACTION = '[actions] delete purchaseInvoce return';
export const DELETE_PURCHASEINVOCE_return_MUTATION = '[mutations] delete purchaseInvoce return';
export const DELETE_PURCHASEINVOCE_MUTATION = '[actions] delete purchaseInvoce';
export const POST_PURCHASEINVOCE_ACTION = '[actions] post purchaseInvoce';
export const POST_PURCHASEINVOCE_MUTATION = '[mutations] post purchaseInvoce';

////////////////////Fundn//////////////
export const GET_FUNDN_OPEN_ACTION = '[actions] get fundn open';
export const GET_FUNDN_OPEN_MUTATION = '[mutations] get fundn open';
export const POST_FUNDN_OPEN_ACTION = '[actions] post fundn open';
export const POST_FUNDN_OPEN_MUTATION = '[mutations] post fundn open';
export const DELETE_FUNDN_OPEN_ACTION = '[actions] delete fundn open';
export const GET_FUNDN_ACTION = '[actions] get fundn ';
export const SEARCH_FUNDN_ACTION = '[actions] search get fundn ';
export const SEARCH_FUNDN_MUTATION = '[mutations] search get fundn ';
export const GET_FUNDN_MUTATION = '[mutations] get fundn ';
export const POST_FUNDN_ACTION = '[actions] post fundn ';
export const POST_FUNDN_MUTATION = '[mutations] post fundn ';
export const DELETE_FUNDN_ACTION = '[actions] post fundn ';
export const DELETE_FUNDN_MUTATION = '[actions] post fundn ';

///////////////dashboard//////////////
export const GET_DASHBOARD_ACTION = '[actions] get dashboard';
export const GET_REPORT_YARE_ORDER_ACTION = '[actions] get dashboard yare order';
export const GET_REPORT_YARE_ORDER_MUTATION = '[mutations] get dashboard yare order';
export const GET_DASHBOARD_MUTATION = '[mutations] get dashboard';

////////////budget//////

export const GET_BUDGET_ACTION = '[actions] get budget dashboard';
export const GET_ORDER_INVENTORY_ACTION = '[actions] get inventory order';
export const GET_ORDER_INVENTORY_MUTATION = '[mutations] get inventory order';
export const GET_PRODUCT_INVENTORY_ACTION = '[actions] get inventory product';
export const GET_PRODUCT_INVENTORY_MUTATION = '[mutations] get inventory product';
export const GET_DEALER_INVENTORY_ACTION = '[actions] get inventory dealer';
export const GET_DEALER_INVENTORY_MUTATION = '[mutations] get inventory dealer';
export const GET_FUNDN_INVENTORY_ACTION = '[actions] get inventory fundn';
export const GET_FUNDN_INVENTORY_MUTATION = '[mutations] get inventory fundn';
export const GET_BUDGET_MUTATION = '[mutations] get budget dashboard';

///////////////catchReceipt//////////////
export const GET_CATCH_RECEIPT_ACTION = '[actions] get catch receipt';
export const POST_CATCH_RECEIPT_ACTION = '[actions] post catch receipt';
export const POST_CATCH_RECEIPT_MUTATION = '[mutations] post catch receipt';
export const UPDATE_CATCH_RECEIPT_ACTION = '[actions] update catch receipt';
export const UPDATE_CATCH_RECEIPT_MUTATION = '[actions] update catch receipt';
export const GET_CATCH_RECEIPT_MUTATION = '[mutations] get catch receipt';
export const FILTER_CATCH_RECEIPT_ACTION = '[actions] filter catch receipt';
export const DELETE_CATCH_RECEIPT_ACTION = '[actions] delete catch receipt';
export const DELETE_CATCH_RECEIPT_MUTATION = '[mutations] delete catch receipt';

///////////////paymentReceipt//////////////
export const GET_PAYMENT_RECEIPT_ACTION = '[actions] get payment receipt';
export const UPDATE_PAYMENT_RECEIPT_ACTION = '[actions] update payment receipt';
export const UPDATE_PAYMENT_RECEIPT_MUTATION = '[actions] update payment receipt';
export const POST_PAYMENT_RECEIPT_ACTION = '[actions] post payment receipt';
export const POST_PAYMENT_RECEIPT_MUTATION = '[mutations] post payment receipt';
export const GET_PAYMENT_RECEIPT_MUTATION = '[mutations] get payment receipt';
export const FILTER_PAYMENT_RECEIPT_MUTATION = '[mutations] delete payment receipt';
export const DELETE_PAYMENT_RECEIPT_ACTION = '[actions] delete payment receipt';
export const DELETE_PAYMENT_RECEIPT_MUTATION = '[mutations] delete payment receipt';


///////////////dealerOpen//////////////
export const RESET_REPORT_DEALER_ACTION = '[actions] reset report dealer ';
export const GET_REPORT_DEALER_ACTION = '[actions] get report dealer ';
export const GET_REPORT_DEALER_MUTATION = '[mutations] get report dealer ';
export const GET_DEALER_OPEN_ACTION = '[actions] get dealer open';
export const UPDATE_DEALER_OPEN_ACTION = '[actions] update dealer open';
export const UPDATE_DEALER_OPEN_MUTATION = '[mutations] update dealer open';
export const POST_DEALER_OPEN_ACTION = '[actions] post dealer open';
export const POST_DEALER_OPEN_MUTATION = '[mutations] post dealer open';
export const GET_DEALER_OPEN_MUTATION = '[mutations] get dealer open';
export const FILTER_DEALER_OPEN_MUTATION = '[mutations] delete dealer open';
export const DELETE_DEALER_OPEN_ACTION = '[actions] delete dealer open';
export const DELETE_DEALER_OPEN_MUTATION = '[mutations] delete dealer open';

////////////notifications///////////
export const Get_UNREAD_NOTIFICATIONS_ACTION = '[actions] get unread notifications';
export const Get_UNREAD_NOTIFICATIONS_MUTATION = '[mutations] get unread notifications';
export const MARK_AS_READ_NOTIFICATIONS_ACTION = '[actions]  read all notifications';
export const GET_ALL_NOTIFICATION_ACTION = '[actions]  gat all notifications';
export const DELETE_ALL_NOTIFICATION_ACTION = '[actions]  delete all notifications';
export const DELETE_ALL_NOTIFICATION_MUTATION = '[MUTATION]  delete all notifications';
export const GET_ALL_NOTIFICATION_MUTATION = '[MUTATION]  gat all notifications';
export const READ_ONE_NOTIFICATION_ACTION = '[actions]  read one notifications';
export const READ_ONE_NOTIFICATION_MUTATION = '[MUTATION]  read one notifications';
export const DELETE_ONE_NOTIFICATION_ACTION = '[actions]  delete one notifications';
export const DELETE_ONE_NOTIFICATION_MUTATION = '[MUTATION]  delete one notifications';


///////////////FabricNumber//////////////
export const GET_ALL_FABRIC_NUMBER_ACTION = '[actions] get all fabric number ';
export const GET_ALL_FABRIC_NUMBER_MUTATION = '[mutations] get all fabric number ';
export const GET_FABRIC_NUMBER_ACTION = '[actions] get fabric number ';
export const GET_FABRIC_NUMBER_MUTATION = '[mutations] get fabric number ';
export const UPDATE_FABRIC_NUMBER_ACTION = '[actions] update fabric number ';
export const POST_FABRIC_NUMBER_ACTION = '[actions] post fabric number ';
export const POST_FABRIC_NUMBER_MUTATION = '[mutations] post fabric number ';
export const FILTER_FABRIC_NUMBER_ACTION = '[actions] filter fabric number';
export const DELETE_FABRIC_NUMBER_ACTION = '[actions] delete fabric number';
export const DELETE_FABRIC_NUMBER_MUTATION = '[mutations] delete fabric number';

///////////////Fabric//////////////
export const GET_FABRIC_ACTION = '[actions] get fabric ';
export const GET_FABRIC_MUTATION = '[mutations] get fabric ';
export const GET_CODE_FABRIC_BY_ID_ACTION = '[actions] get code fabric ';
export const GET_CODE_FABRIC_BY_MUTATION = '[mutations] get code fabric ';
export const UPDATE_FABRIC_ACTION = '[actions] update fabric ';
export const POST_FABRIC_ACTION = '[actions] post fabric ';
export const POST_FABRIC_MUTATION = '[mutations] post fabric ';
export const FILTER_FABRIC_ACTION = '[actions] filter fabric';
export const DELETE_FABRIC_ACTION = '[actions] delete fabric';
export const DELETE_FABRIC_MUTATION = '[mutations] delete fabric';


///////////////FabricDrawls//////////////
export const GET_FABRIC_DRAWLS_ACTION = '[actions] get fabric drawls';
export const GET_FABRIC_DRAWLS_MUTATION = '[mutations] get fabric drawls';
export const GET_CODE_FABRIC_DRAWLS_BY_ID_ACTION = '[actions] get code fabric drawls ';
export const GET_CODE_FABRIC_DRAWLS_BY_ID_MUTATION = '[mutations] get code fabric drawls ';
export const UPDATE_FABRIC_DRAWLS_ACTION = '[actions] post fabric drawls';
export const POST_FABRIC_DRAWLS_ACTION = '[actions] post fabric drawls ';
export const POST_FABRIC_DRAWLS_MUTATION = '[mutations] post fabric drawls';
export const FILTER_FABRIC_DRAWLS_ACTION = '[actions] filter fabric drawls';
export const DELETE_FABRIC_DRAWLS_ACTION = '[actions] delete fabric drawls';
export const DELETE_FABRIC_DRAWLS_MUTATION = '[mutations] delete fabric drawls';

//////////operating//////

export const GET_OPERATING_ACTION = '[actions] get operating ';
export const GET_OPERATING_MUTATION = '[mutations] get operating ';
export const UPDATE_OPERATING_ACTION = '[actions] update operating ';
export const POST_OPERATING_ACTION = '[actions] post operating ';
export const POST_OPERATING_MUTATION = '[mutations] post operating ';
export const FILTER_OPERATING_ACTION = '[actions] filter operating';
export const DELETE_OPERATING_ACTION = '[actions] delete operating';
export const DELETE_OPERATING_MUTATION = '[mutations] delete operating';


//////////operating_payment//////
export const GET_OPERATING_FINANCE_ACTION = '[actions] get operating finance ';
export const GET_OPERATING_FINANCE_MUTATION= '[mutations] get operating finance ';
export const GET_OPERATING_PAYMENT_ACTION = '[actions] get operating payment ';
export const GET_OPERATING_PAYMENT_MUTATION = '[mutations] get operating payment ';
export const UPDATE_OPERATING_PAYMENT_ACTION = '[actions] update operating payment';
export const POST_OPERATING_PAYMENT_ACTION = '[actions] post operating payment';
export const POST_OPERATING_PAYMENT_MUTATION = '[mutations] post operating payment';
export const FILTER_OPERATING_PAYMENT_ACTION = '[actions] filter operating payment';
export const DELETE_OPERATING_PAYMENT_ACTION = '[actions] delete operating payment';
export const DELETE_OPERATING_PAYMENT_MUTATION = '[mutations] delete operating payment';