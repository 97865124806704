import {CHNANGE_ِSTATUS_DELIVERY_MUTATION,DELETING_DELIVERY_ORDER_mutations, GET_DELIVERY_MUTATION,GET_ِALL_DELIVERY_ORDER_MUTATION, POST_DELIVERY_ORDER_MUTATION, DELETING_DELIVERY_mutations, GET_ِALL_DELIVERY_MUTATION, POST_DELIVERY_MUTATION } from "@/store/storeConstants";
export default {
    [GET_DELIVERY_MUTATION](state, payload) {
        state.deliveries = payload.deliveries;
        state.deliveryLoaded = payload.loaded;
    },

    [POST_DELIVERY_MUTATION](state, payload) {
        state.postDelivery = payload;
    },

    [DELETING_DELIVERY_mutations](state, payload) {
        state.deleteing = payload;
    },

    [GET_ِALL_DELIVERY_MUTATION](state, payload) {
        state.allDeliveries = payload.Alldeliveries;
    },

    [POST_DELIVERY_ORDER_MUTATION](state, payload) {
        state.postDeliveryOrder = payload;
    },  
    
    [GET_ِALL_DELIVERY_ORDER_MUTATION](state, payload) {
        state.deliveryOrders = payload.deliveryOrders;
        state.deliveryOrderLoaded = payload.loaded;
    },
    [DELETING_DELIVERY_ORDER_mutations](state, payload) {
        state.deleteing = payload
    }, 
    [CHNANGE_ِSTATUS_DELIVERY_MUTATION](state, payload) {
      
    },

}