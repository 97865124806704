import {GET_ALL_DEALER_MUTATION,DELETING_DEALER_mutations,POST_DEALER_MUTATION,DEALERS_LOADED_MUTATION} from "@/store/storeConstants";
export default {
    [DEALERS_LOADED_MUTATION](state, payload) {
        state.dealers = payload.dealers;
        state.dealerLoaded = payload.loaded;
    },
    [POST_DEALER_MUTATION](state, payload) {
        state.postDealer = payload
    },
    [DELETING_DEALER_mutations](state, payload) {
        state.deleteing = payload
    },  
     [GET_ALL_DEALER_MUTATION](state, payload) {
        state.Alldealers = payload.Alldealers
    }, 
  
}