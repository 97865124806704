import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        
        return{
            deliveries : [],
            allDeliveries : [],
            deliveryOrders : [],
            deliveryLoaded:false,
            postDelivery:false,
            postDeliveryOrder:false,
            deliveryOrderLoaded:false,
            deleteing:false,
        }
    },
    mutations,
    getters,
    actions,
}
