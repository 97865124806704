import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        
        return{
            clients : [],
            Allclients : [],
            clientLoaded:false,
            postClient:false,
            deleteing:false,
        }
    },
    mutations,
    getters,
    actions,
}
