import axiosInstance from "@/service/AxiosInstance";
import external from "@/service/External";
import {GET_REPORT_YARE_ORDER_MUTATION,GET_REPORT_YARE_ORDER_ACTION,GET_DASHBOARD_ACTION, GET_DASHBOARD_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_DASHBOARD_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/dashboard/report`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            let data = {
                loaded: true,
                internal_orders: response.data.internal_orders,
                internal_orders_done: response.data.internal_orders_done,
                external_orders: response.data.external_orders,
                external_orders_done: response.data.external_orders_done,
                waiting_orders: response.data.waiting_orders,
                return_internal: response.data.return_internal,
                return_external: response.data.return_external,
                products: response.data.products,
                dealers: response.data.dealers,
                fundns: response.data.fundns,
                clients: response.data.clients,
                saleInvoice: response.data.saleInvoice,
                saleInvoice_return: response.data.saleInvoice_return,
                deliveries: response.data.deliveries,
                productNumber: response.data.productNumber,
            }
            context.commit(GET_DASHBOARD_MUTATION, data)
        }
    },

    async [GET_REPORT_YARE_ORDER_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/dashboard/report_yare`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            let data = {
                loaded: true,
                resultReportDone: response.data.resultReportDone,
                resultReportReturn: response.data.resultReportReturn,
                countProductPay: response.data.countProductPay,

            }
            context.commit(GET_REPORT_YARE_ORDER_MUTATION, data)
            external.emit('dashboardLoded')
        }
    },

    
    



}