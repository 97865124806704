import axiosInstance from "@/service/AxiosInstance";

import { DELETE_ONE_NOTIFICATION_ACTION,DELETE_ALL_NOTIFICATION_MUTATION, DELETE_ALL_NOTIFICATION_ACTION, GET_ALL_NOTIFICATION_ACTION, GET_ALL_NOTIFICATION_MUTATION, Get_UNREAD_NOTIFICATIONS_MUTATION, MARK_AS_READ_NOTIFICATIONS_ACTION, Get_UNREAD_NOTIFICATIONS_ACTION, BASE_URL } from "@/store/storeConstants";
export default {
    async [Get_UNREAD_NOTIFICATIONS_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/notification/unread`);
        } catch (error) {


            return error
        }
        if (response.status == 200) {
            console.log(response.data.total);

            const data = {
                notifications: response.data.data,
                notificationLoaded: true,
                totalNotifiction: response.data.total,
            }

            context.commit(Get_UNREAD_NOTIFICATIONS_MUTATION, data)
        }


    },

    async [MARK_AS_READ_NOTIFICATIONS_ACTION](context, payload) {
        const data = {
            notificationLoaded: false,
        }
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/notification/read`);

        } catch (error) {


            console.log(error);
            return error
        }
        if (response.status == 200) {

            console.log(response.data);

        }


    },

    async [GET_ALL_NOTIFICATION_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/notification/all?page=${payload.currentPage}`);

        } catch (error) {

            console.log(error);
            return error
        }
        if (response.status == 200) {

            const data = {
                notifications: response.data,
                loaded: true,

            }
            context.commit(GET_ALL_NOTIFICATION_MUTATION, data)
        }


    },

    async [DELETE_ALL_NOTIFICATION_ACTION](context, payload) {
        context.commit(DELETE_ALL_NOTIFICATION_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/notification/delete/all`);

        } catch (error) {
            context.commit(DELETE_ALL_NOTIFICATION_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
          
            context.commit(DELETE_ALL_NOTIFICATION_MUTATION, false)
        }

    },    
    
    async [DELETE_ONE_NOTIFICATION_ACTION](context, payload) {
        context.commit(DELETE_ALL_NOTIFICATION_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/notification/delete/${payload}`);

        } catch (error) {
            context.commit(DELETE_ALL_NOTIFICATION_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETE_ALL_NOTIFICATION_MUTATION, false)
        }

    },



}