export function setLocalStorage(name, items) {
    return localStorage.setItem(name, JSON.stringify(items));
}

export function getLocalStorage(name, items) {
    return JSON.parse(localStorage.getItem(name))
}

export function addOldStorage(name, key,value) {
    var data = localStorage.getItem(name);
    data = data ? JSON.parse(data) : {};
    data [key] = value
    return localStorage.setItem(name, JSON.stringify(data));
}
