import {DELETE_RATIO_MUTATION,EDIT_RECEIPT_RATIO_MUTATION,ADD_RECEIPT_RATIO_MUTATION,POST_RETURN_RATIO_MUTATION,GET_RATIO_CLIENT_DETAIL_MUTATION, GET_RATIO_MUTATION, POST_RATIO_MUTATION, GET_RATIO_CLIENT_MUTATION } from "@/store/storeConstants";
export default {
    [GET_RATIO_MUTATION](state, payload) {
        state.ratios = payload.ratios;
        state.ratioLoaded = payload.loaded;
        state.totalRatio = payload.totalRatio;
        state.totalBonus = payload.totalBonus;
        state.totalProtaction = payload.totalProtaction;
        state.finalRatio = payload.finalRatio;
        state.totalDiscount = payload.totalDiscount;
        state.countOrderReturn = payload.countOrderReturn;
        state.countOrderDone = payload.countOrderDone;
    },

    [POST_RATIO_MUTATION](state, payload) {
        state.postRtio = payload;
    },

    [GET_RATIO_CLIENT_MUTATION](state, payload) {
        state.allRatios = payload.allRatios;
        state.allRatioLoaded = payload.allRatioLoaded;
    },

    [GET_RATIO_CLIENT_DETAIL_MUTATION](state, payload) {
        state.ratioDetails = payload.ratioDetails;
        state.ratioDetailsLoaded = payload.ratioDetailsLoaded;
    }, 
     [POST_RETURN_RATIO_MUTATION](state, payload) {
        state.PostreturnRatio = payload;
    },    
    
    [ADD_RECEIPT_RATIO_MUTATION](state, payload) {
        state.postReceipt = payload;
    }, 
    
    [EDIT_RECEIPT_RATIO_MUTATION](state, payload) {
        state.postReceipt = payload;
    }, 
      [DELETE_RATIO_MUTATION](state, payload) {
        state.deleteing = payload;
    },


}