import {PRINT_ORDER_MUTATION,DELETE_IMAGE_RECEIPT_MUTATION,UPLOADE_RECEIPT_MUTATION,GET_ORDER_INERNAL_MUTATION,GET_ORDER_WAITING_MUTATION,ORDER_MOVED_STATUS_MUTATION,ORDER_CHARGED_STATUS_MUTATION,ADD_RECEIPT_ORDER_EXTERNAL_MUTATION,DELETEING_RETURN_ORDER_MUTATION,GET_RETURN_ORDER_EXTERNAL_MUTATION,GET_RETURN_ORDER_INERNAL_MUTATION,RETURN_ORDER_MUTATION,DELETEING_ORDER_MUTATION,GET_EDIT_ORDER_MUTATION,GET_ORDER_EXTERNAL_MUTATION,GET_ORDER_INERTNAL_MUTATION,POST_ORDER_MUTATION,ORDER_STATUS_MUTATION} from "@/store/storeConstants";
export default {
    [GET_ORDER_INERTNAL_MUTATION](state, payload) {
        state.ordersInternal = payload.orders;
        state.orderInternalLoaded = payload.loaded;
        state.count = payload.count ?? null;
    },
     [GET_ORDER_EXTERNAL_MUTATION](state, payload) {
        state.ordersExternal = payload.orders;
        state.orderExternalLoaded = payload.loaded;
        state.count = payload.count ?? null;
    },
    [POST_ORDER_MUTATION](state, payload) {
        state.postOrder = payload
    },  
    [ORDER_STATUS_MUTATION](state, payload) {
        state.changingStatus = payload
    }, 
     [GET_EDIT_ORDER_MUTATION](state, payload) {
        state.orderDetail = payload.orders
        state.orderDetailLoaded = payload.loaded
    },  
    [DELETEING_ORDER_MUTATION](state, payload) {
        state.deleteing = payload
    },
   [RETURN_ORDER_MUTATION](state, payload) {
        state.returning = payload
    },
     [GET_RETURN_ORDER_INERNAL_MUTATION](state, payload) {
        state.returnOrdersInternal = payload.returnOrdersInternal
        state.returnOrderInternalLoaded = payload.returnOrderInternalLoaded
    },   
     [GET_RETURN_ORDER_EXTERNAL_MUTATION](state, payload) {
        state.returnOrdersExternal = payload.returnOrdersExternal
        state.returnOrderExternalLoaded = payload.returnOrderExternalLoaded
    },
   [DELETEING_RETURN_ORDER_MUTATION](state, payload) {
        state.deleteing = payload
   
    }, 
    [ADD_RECEIPT_ORDER_EXTERNAL_MUTATION](state, payload) {
        state.postReceipt = payload
    },   
    [ORDER_CHARGED_STATUS_MUTATION](state, payload) {
        state.postCharged = payload
    },
    [ORDER_MOVED_STATUS_MUTATION](state, payload) {
        state.postMoved = payload
    }, 
    
    [GET_ORDER_WAITING_MUTATION](state, payload) {
        state.ordersWaiting = payload.ordersWaiting
        state.ordersWaitLoaded = payload.loaded
    }, 
      [GET_ORDER_INERNAL_MUTATION](state, payload) {
      
    },
     [UPLOADE_RECEIPT_MUTATION](state, payload) {
      state.uploadeReceipt = payload
    },  
    
    [DELETE_IMAGE_RECEIPT_MUTATION](state, payload) {
      state.deleteingReceipt = payload
    },  
    [PRINT_ORDER_MUTATION](state, payload) {
      
    },
 
}