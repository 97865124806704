import axiosInstance from "@/service/AxiosInstance";

import {DELETE_FABRIC_DRAWLS_MUTATION,DELETE_FABRIC_DRAWLS_ACTION,GET_CODE_FABRIC_DRAWLS_BY_ID_MUTATION,GET_CODE_FABRIC_DRAWLS_BY_ID_ACTION,UPDATE_FABRIC_DRAWLS_ACTION,POST_FABRIC_DRAWLS_MUTATION,POST_FABRIC_DRAWLS_ACTION,FILTER_FABRIC_DRAWLS_ACTION,GET_FABRIC_DRAWLS_MUTATION,GET_FABRIC_DRAWLS_ACTION,DELETE_FABRIC_MUTATION,DELETE_FABRIC_ACTION ,UPDATE_FABRIC_ACTION,POST_FABRIC_MUTATION,POST_FABRIC_ACTION, FILTER_FABRIC_ACTION,GET_FABRIC_MUTATION,GET_FABRIC_ACTION, GET_CODE_FABRIC_BY_MUTATION, GET_CODE_FABRIC_BY_ID_ACTION, FILTER_FABRIC_NUMBER_ACTION, UPDATE_FABRIC_NUMBER_ACTION, DELETE_FABRIC_NUMBER_MUTATION, DELETE_FABRIC_NUMBER_ACTION, POST_FABRIC_NUMBER_MUTATION, POST_FABRIC_NUMBER_ACTION, GET_FABRIC_NUMBER_ACTION, GET_FABRIC_NUMBER_MUTATION, GET_ALL_FABRIC_NUMBER_ACTION, GET_ALL_FABRIC_NUMBER_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_FABRIC_NUMBER_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/fabirc_number/index?page=${payload.currentPage}`);


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fabricCodes: response.data.data,

            }
            context.commit(GET_FABRIC_NUMBER_MUTATION, data)
        }
    }, 
    async [GET_FABRIC_NUMBER_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/fabirc_number/index?page=${payload.currentPage}`);


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fabricCodes: response.data.data,

            }
            context.commit(GET_FABRIC_NUMBER_MUTATION, data)
        }
    },
    async [FILTER_FABRIC_NUMBER_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/fabirc_number/index?code=${payload.text}`);


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fabricCodes: response.data.data,

            }
            context.commit(GET_FABRIC_NUMBER_MUTATION, data)
        }
    },
    async [GET_ALL_FABRIC_NUMBER_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/fabirc_number/all`);

        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                allFabricCodes: response.data.data,

            }
            context.commit(GET_ALL_FABRIC_NUMBER_MUTATION, data)
        }
    },
    async [POST_FABRIC_NUMBER_ACTION](context, payload) {
        context.commit(POST_FABRIC_NUMBER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/fabirc_number/create`, payload);
        } catch (e) {
            console.log(e);
            context.commit(POST_FABRIC_NUMBER_MUTATION, false)
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_FABRIC_NUMBER_MUTATION, false)
        }
    },
    async [UPDATE_FABRIC_NUMBER_ACTION](context, payload) {
        context.commit(POST_FABRIC_NUMBER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/fabirc_number/update`, payload);
        } catch (e) {
            context.commit(POST_FABRIC_NUMBER_MUTATION, false)
            console.log(e);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_FABRIC_NUMBER_MUTATION, false)
        }
    },

    async [DELETE_FABRIC_NUMBER_ACTION](context, payload) {
        context.commit(DELETE_FABRIC_NUMBER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/fabirc_number/delete/${payload.id}`);
        } catch (e) {
            console.log(e);
            context.commit(DELETE_FABRIC_NUMBER_MUTATION, false)

            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(DELETE_FABRIC_NUMBER_MUTATION, false)
        }
    },
    ////////////////////fabric//////////////
    async [GET_CODE_FABRIC_BY_ID_ACTION](context, payload) {
 
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/fabirc_number/show/${payload}`)
        }
         catch (error) {
            return console.log(error);
        }

        if (response.status = 200) {
       
            let data = {
                singleCode: response.data.data[0]
            }

            context.commit(GET_CODE_FABRIC_BY_MUTATION, data);
        }
    },

    async [GET_CODE_FABRIC_DRAWLS_BY_ID_ACTION](context, payload) {
 
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/fabirc_drawls/total/${payload}`)
        }
         catch (error) {
            return console.log(error);
        }

        if (response.status = 200) {
           console.log( response.data);
            let data = {
                total: response.data.total,
                type: response.data.type,
            }
            context.commit(GET_CODE_FABRIC_DRAWLS_BY_ID_MUTATION, data);
        }
    },

    async [GET_FABRIC_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/fabirc/index?page=${payload.currentPage}`);


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fabrices: response.data.data,
            }
            context.commit(GET_FABRIC_MUTATION, data)
        }
    },
    
    async [POST_FABRIC_ACTION](context, payload) {
  
        context.commit(POST_FABRIC_MUTATION, true)
        let response = '';
        try {

            response = await axiosInstance.post(`${BASE_URL}/app/fabirc/create`,payload);

        } catch (error) {

            context.commit(POST_FABRIC_MUTATION, false)
            if (error.response.status = 408)
            throw error.response.data.message;
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
        
            context.commit(POST_FABRIC_MUTATION, false)
        }
    }, 
    
    async [UPDATE_FABRIC_ACTION](context, payload) {
            
        context.commit(POST_FABRIC_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/fabirc/update`,payload);

        } catch (error) {
            context.commit(POST_FABRIC_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
        
            context.commit(POST_FABRIC_MUTATION, false)
        }
    },  

    async [DELETE_FABRIC_ACTION](context, payload) {
    
        context.commit(DELETE_FABRIC_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/fabirc/delete/${payload.id}`);

        } catch (error) {
            context.commit(DELETE_FABRIC_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
        
            context.commit(DELETE_FABRIC_MUTATION, false)
        }
    },

    async [FILTER_FABRIC_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/fabirc/index?search=${payload.text}`);


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fabrices: response.data.data,
            }
            context.commit(GET_FABRIC_MUTATION, data)
        }
    },

//////////////////fabricDrawls//////
async [GET_FABRIC_DRAWLS_ACTION](context, payload) {
 
    let response = ''
    try {
        response = await axiosInstance.get(`${BASE_URL}/app/fabirc_drawls/index?page=${payload.currentPage}`)
    }
     catch (error) {
        return console.log(error);
    }

    if (response.status = 200) {
   
        let data = {
            loaded:true,
            fabricDrawls: response.data.data,
        }

        context.commit(GET_FABRIC_DRAWLS_MUTATION, data);
    }
},

async [POST_FABRIC_DRAWLS_ACTION](context, payload) {
    context.commit(POST_FABRIC_DRAWLS_MUTATION, true);
    let response = ''
    try {
        response = await axiosInstance.post(`${BASE_URL}/app/fabirc_drawls/create`,payload)
    }
     catch (error) {
        context.commit(POST_FABRIC_DRAWLS_MUTATION, false);
        return console.log(error);
    }

    if (response.status = 200) {
  
     

        context.commit(POST_FABRIC_DRAWLS_MUTATION, false);
    }
},

async [UPDATE_FABRIC_DRAWLS_ACTION](context, payload) {
    context.commit(POST_FABRIC_DRAWLS_MUTATION, true);
    let response = ''
    try {
        response = await axiosInstance.post(`${BASE_URL}/app/fabirc_drawls/create`,payload)
    }
     catch (error) {
        context.commit(POST_FABRIC_DRAWLS_MUTATION, false);
        return console.log(error);
    }

    if (response.status = 200) {
  
     

        context.commit(POST_FABRIC_DRAWLS_MUTATION, false);
    }
},

async [FILTER_FABRIC_DRAWLS_ACTION](context, payload) {
 
    let response = ''
    try {
        response = await axiosInstance.get(`${BASE_URL}/app/fabirc_drawls/index?search=${payload.text}`)
    }
     catch (error) {
        return console.log(error);
    }

    if (response.status = 200) {
   
        let data = {
            loaded:true,
            fabricDrawls: response.data.data,
        }

        context.commit(GET_FABRIC_DRAWLS_MUTATION, data);
    }
},

async [DELETE_FABRIC_DRAWLS_ACTION](context, payload) {

    context.commit(DELETE_FABRIC_DRAWLS_MUTATION, true);
    let response = ''
    try {
        response = await axiosInstance.delete(`${BASE_URL}/app/fabirc_drawls/delete/${payload.id}`)
    }
     catch (error) {
        context.commit(DELETE_FABRIC_DRAWLS_MUTATION, false);
        return console.log(error);
    }

    if (response.status = 200) {
   
  
        context.commit(DELETE_FABRIC_DRAWLS_MUTATION, false);
    }
},




}