import { EDIT_IMAGE_MUTATION,LOG_OUT_USER_MUTATION, DELETING_USER_mutations, POST_USER_MUTATION, USERS_LOADED_MUTATION, SHOW_LOADING, LOGGED_IN_USER } from '../../storeConstants'
export default {
    [SHOW_LOADING](state, payload) {
        state.autoLogout = payload;

    },
    [LOGGED_IN_USER](state, payload) {
        state.token = payload.token;
        state.isAdmin = payload.isAdmin;
        state.expiresIn = payload.expires_in;
        state.fullname = payload.fullname;
        state.email = payload.fullname;
        state.image = payload.image;
        state.userId = payload.id;
        state.company_id = payload.company_id;
    },
    [USERS_LOADED_MUTATION](state, payload) {
        state.users = payload.users;
        state.userLoaded = payload.loaded;
    },
    [POST_USER_MUTATION](state, payload) {
        state.postUser = payload
    },
    [DELETING_USER_mutations](state, payload) {
        state.deleteing = payload
    }, 
    [LOG_OUT_USER_MUTATION](state, payload) {
        state.logingOut = payload
    },  
     [EDIT_IMAGE_MUTATION](state, payload) {
        state.updateImage = payload
    },

}