import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            protacionClients: [],
            protacionDealers: [],
            protacionClientDetails: [],
            protacionDealerDetails: [],
            protacionDetailCleints: [],
            protacionDetailDealers: [],
            protacionClientId: 0,
            protacionDealerId: 0,
            protacionClientLoaded:false,
            protacionDealerIdLoaded:false,
            protacionClientIdLoaded:false,
            protacionDealerLoaded:false,
            protacionClientDetailLoaded:false,
            protacionDealerDetailLoaded:false,
            protacionDetailClientsLoaded:false,
            protacionDetailDealersLoaded:false,
            postProtaction:false,
            deleteing:false,
        }
    },
    mutations,
    getters,
    actions,
}
