import { Validation } from "@/service/Validations";
import {EDIT_IMAGE_MUTATION,EDIT_IMAGE_ACTION,SEARCH_USER_ACTION,UPDATE_USER_ACTION,LOG_OUT_USER_MUTATION,DELETING_USER_mutations,DELETE_USER_ACTION,POST_USER_MUTATION,POST_USER_ACTION,USERS_LOADED_MUTATION, GET_USERS_ACTION,AUTO_LOGIN_USER, BASE_URL, LOGGED_IN_USER, LOGIN_ACTION, SHOW_LOADING, USER_LOG_OUT, showError } from "@/store/storeConstants";
import axios from "axios";
import router from '@/router'
import ec from "@/service/External";
import axiosInstance from "@/service/AxiosInstance";
import { SetScaleNew, applyScale, changeMenu, onChangeTheme } from "@/service/ChangeTheme";
import { getLocalStorage } from "@/service/LocalStorageService";
let timer;
export default {

    async [LOGIN_ACTION](context, payload) {
        context.commit(SHOW_LOADING, true)
        let data = {
            email: payload.email,
            password: payload.password,
        }
        let response = '';
        try {
            response = await axios.post(`${BASE_URL}/auth/admin/login`, data);

        } catch (e) {
            console.log(e);
            context.commit(SHOW_LOADING, false)
            const error = Validation.getErrorMessage(e.response.data.error)
            throw error;
        }
        context.commit(SHOW_LOADING, false)
        if (response.status == 200) {
       
            if(!response.data.user.email_request)
            {
              
                 context.dispatch(USER_LOG_OUT)
            }
            const userConfig = getLocalStorage('userConfig');
            const menus = getLocalStorage('menus');
            const scale = getLocalStorage('scale');
            if (scale)
                SetScaleNew (scale.scale)
            if (userConfig)
                onChangeTheme(userConfig.theme, userConfig.mode)
            if (menus)
                changeMenu(menus.menu)
            let expirationTime = (+response.data.expires_in / 60 -1)* 3600000 + new Date().getTime();
            let tokenData = {
                token: response.data.access_token,
                email: response.data.user.email,
                isAdmin: response.data.user.is_admin,
                userId: response.data.user.id,
                fullname: response.data.user.fullname,
                image: response.data.user.image,
                companyId: response.data.user.company_id,
                expiresIn: expirationTime,
            }
            ec.emit('logined')
            localStorage.setItem('userData', JSON.stringify(tokenData))
            context.commit(LOGGED_IN_USER, tokenData)
            router.push('/')
        }


    },
    
    async [AUTO_LOGIN_USER](context, payload) {
        const dataUser = JSON.parse(localStorage.getItem('userData'));
        const scale = getLocalStorage('scale');
        const menus = getLocalStorage('menus');
        const userConfig = getLocalStorage('userConfig');
        if (scale)
            SetScaleNew(scale.scale)
        if (userConfig)
            onChangeTheme(userConfig.theme, userConfig.mode)
        if (menus)
            changeMenu(menus.menu)

        if (dataUser) {

            let expirationTime = dataUser.expiresIn - new Date().getTime();
           
            if (expirationTime < 5000) {
                context.dispatch(USER_LOG_OUT)
            } else {
                timer = setTimeout(() => {
                    context.dispatch(USER_LOG_OUT)
                }, expirationTime);
            }
            context.commit(LOGGED_IN_USER, dataUser)
        }
    },

    

    async [USER_LOG_OUT](context, payload) {
        context.commit(LOG_OUT_USER_MUTATION,true);
        let response = '';
        try {
            
            response = await axiosInstance.post(`http://127.0.0.1:8000/api/auth/admin/logout`);
        } catch (error) {
            context.commit(LOG_OUT_USER_MUTATION,false);
            context.commit(LOGGED_IN_USER, {
                access_token: null,
                email: null,
                userId: null,
                companyId: null,
                expiresIn: null,
            });
            localStorage.removeItem('userData')

            router.replace({ name: 'login' })
        }
        if (response.status == 200) {
            context.commit(LOG_OUT_USER_MUTATION,false);
            context.commit(LOGGED_IN_USER, {
                access_token: null,
                email: null,
                userId: null,
                companyId: null,
                expiresIn: null,
            });
            localStorage.removeItem('userData')
            context.commit(LOG_OUT_USER_MUTATION,false);
            router.replace({ name: 'login' })
        }

    },

    async [GET_USERS_ACTION](context, payload) {
     
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/auth/admin/index`);

        } catch (e) {
         
          return e;
        }
      
        if (response.status == 200) {
             let data = {
                loaded:true,
                users:response.data.data
             }
            context.commit(USERS_LOADED_MUTATION, data)
         
        }


    },

     async [POST_USER_ACTION](context, payload) {
         context.commit(POST_USER_MUTATION,true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/auth/admin/register`,payload);
        } catch (e) {
                console.log(e);
            context.commit(POST_USER_MUTATION,false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0] ;
            }
        }
   
        if (response.status == 200) {
       
            context.commit(POST_USER_MUTATION,false)
        }


    },

     async [SEARCH_USER_ACTION](context, payload) {
     
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/auth/admin/index?search=${payload.text}`);
        } catch (e) {

            return e;
        }
        if (response.status == 200) {
          
            let data = {
                loaded:true,
                users:response.data.data
             }
            context.commit(USERS_LOADED_MUTATION, data)
        }


    },

      async [UPDATE_USER_ACTION](context, payload) {
         context.commit(POST_USER_MUTATION,true)

        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/auth/admin/update`,payload);
        } catch (e) {
       
            context.commit(POST_USER_MUTATION,false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0] ;
            }
        }
       
        if (response.status == 200) {
          
            context.commit(POST_USER_MUTATION,false);
            return console.log(response.data);
        }


    },     
    
    async [EDIT_IMAGE_ACTION](context, payload) {
         context.commit(EDIT_IMAGE_MUTATION,true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/auth/admin/updateImage`,payload);
        } catch (e) {
       
            context.commit(EDIT_IMAGE_MUTATION,false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0] ;
            }
        }
       
        if (response.status == 200) {
             console.log(response.data);
            context.commit(EDIT_IMAGE_MUTATION,false);
       
        }


    }, 

     async [DELETE_USER_ACTION](context, payload) {
      
         context.commit(DELETING_USER_mutations,true)
        const id = payload.userId;
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/auth/admin/delete/${id}`);

        } catch (e) {
            context.commit(DELETING_USER_mutations,false)
            console.log(e);
            return e;
        }
       
        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETING_USER_mutations,false)


        }


    },

}

