import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            fundns : [],
            fundnMovments : [],
            totalOutput : 0,
            totalInput : 0,
            totalExpense : 0,
            finalFundn : 0,
            fundnOpen : 0,
            fundnLoaded:false,
            postMovment:false,
            fundnMovmentLoaded:false,
            postFundn:false,
 
        }
    },
    mutations,
    getters,
    actions,
}
