import axiosInstance from "@/service/AxiosInstance";
import external from "@/service/External";
import { GET_FUNDN_INVENTORY_MUTATION, GET_FUNDN_INVENTORY_ACTION, GET_DEALER_INVENTORY_MUTATION, GET_DEALER_INVENTORY_ACTION, GET_ORDER_INVENTORY_MUTATION, GET_ORDER_INVENTORY_ACTION, GET_PRODUCT_INVENTORY_MUTATION, GET_BUDGET_ACTION, GET_BUDGET_MUTATION, GET_PRODUCT_INVENTORY_ACTION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_BUDGET_ACTION](context, payload) {
        let response = '';
        try {
            if (payload.startDate && payload.endDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/report/budget?start_date=${payload.startDate}&end_date=${payload.endDate}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/report/budget`);

            }

        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                delaerprofit: response.data.delaerprofit,
                wholesaleProfit: response.data.wholesaleProfit,
                orderProfit: response.data.orderProfit,
                fundn: response.data.fundn,
            }
            context.commit(GET_BUDGET_MUTATION, data)
        }
    },

    async [GET_PRODUCT_INVENTORY_ACTION](context, payload) {

        let response = '';
        try {

            if (payload.startDate && payload.endDate && payload.text) {
                response = await axiosInstance.get(`${BASE_URL}/app/report/product?page=${payload.currentPage}&start_date=${payload.startDate}&end_date=${payload.endDate}&search=${payload.text}`);

            } else if (payload.startDate && payload.endDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/report/product?page=${payload.currentPage}&start_date=${payload.startDate}&end_date=${payload.endDate}`);

            }
            else if (payload.text) {
 
                response = await axiosInstance.get(`${BASE_URL}/app/report/product?search=${payload.text}`);

            }
            else {

                response = await axiosInstance.get(`${BASE_URL}/app/report/product?page=${payload.currentPage}`);

            }

        } catch (error) {

            console.log(error);
            return error
        }
        if (response.status == 200) {
    
            let data = {
                loaded: true,
                products: response.data.data,
                sumProducts: response.data.sumProducts
            }
            context.commit(GET_PRODUCT_INVENTORY_MUTATION, data)
        }
    },

    async [GET_ORDER_INVENTORY_ACTION](context, payload) {

        let response = '';
        try {

            if (payload.startDate && payload.endDate && payload.text) {

                response = await axiosInstance.get(`${BASE_URL}/app/report/order?page=${payload.currentPage}&start_date=${payload.startDate}&end_date=${payload.endDate}&search=${payload.text}`);
            } else if (payload.startDate && payload.endDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/report/order?page=${payload.currentPage}&start_date=${payload.startDate}&end_date=${payload.endDate}`);

            } else if (payload.text) {
                response = await axiosInstance.get(`${BASE_URL}/app/report/order?search=${payload.text}`);

            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/report/order?page=${payload.currentPage}`);
            }

        } catch (error) {

            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                orders: response.data.data,
                sumOrder: response.data.sumOrder,
            }
            context.commit(GET_ORDER_INVENTORY_MUTATION, data)
        }
    },

    async [GET_DEALER_INVENTORY_ACTION](context, payload) {
        let response = '';
        try {
            if (payload.startDate && payload.endDate) {

                response = await axiosInstance.get(`${BASE_URL}/app/report/dealer?start_date=${payload.startDate}&end_date=${payload.endDate}`);

            } else {

                response = await axiosInstance.get(`${BASE_URL}/app/report/dealer`);

            }


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                dealers: response.data,
                dealerLength: response.data.data.length,
                sumCreditF: response.data.sumCreditF,
                sumDebitF: response.data.sumDebitF,
            }
            context.commit(GET_DEALER_INVENTORY_MUTATION, data)
        }
    },

    async [GET_FUNDN_INVENTORY_ACTION](context, payload) {
        let data = {
            loaded: false,
        }
        context.commit(GET_FUNDN_INVENTORY_MUTATION, data)
        let response = '';
        try {

            if (payload.startDate && payload.endDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/report/fundn?start_date=${payload.startDate}&end_date=${payload.endDate}`);

            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/report/fundn`);

            }



        } catch (error) {
            let data = {
                loaded: true,
            }
            context.commit(GET_FUNDN_INVENTORY_MUTATION, data)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fundns: response.data.data
            }
            context.commit(GET_FUNDN_INVENTORY_MUTATION, data)
        }
    },




}