import {GET_REPORT_YARE_ORDER_MUTATION,GET_DASHBOARD_MUTATION} from "@/store/storeConstants";
export default {
  
  [GET_DASHBOARD_MUTATION](state, payload) {
        state.loadedDashboard = payload.loaded;
        state.internal_orders = payload.internal_orders;
        state.internal_orders_done = payload.internal_orders_done;
        state.external_orders = payload.external_orders;
        state.external_orders_done = payload.external_orders_done;
        state.waiting_orders = payload.waiting_orders;
        state.waiting_orders_done = payload.waiting_orders_done;
        state.return_external = payload.return_external;
        state.return_internal = payload.return_internal;
        state.products = payload.products;
        state.dealers = payload.dealers;
        state.fundns = payload.fundns;
        state.clients = payload.clients;
        state.saleInvoice = payload.saleInvoice;
        state.saleInvoice_return = payload.saleInvoice_return;
        state.deliveries = payload.deliveries;
        state.productNumber = payload.productNumber;
    }, 
    
    [GET_REPORT_YARE_ORDER_MUTATION](state, payload) {
      state.loadedReportDashboard = payload.loaded;
      state.resultReportReturn = payload.resultReportReturn;
      state.resultReportDone = payload.resultReportDone;
      state.countProductPay = payload.countProductPay;
    },  



}