import axiosInstance from "@/service/AxiosInstance";
import {GET_ALL_CLIENT_ACTION,DELETE_CLIENT_ACTION,GET_ALL_CLIENT_MUTATION,DELETING_CLIENT_mutations,UPDATE_CLIENT_ACTION, SEARCH_CLIENT_ACTION, POST_CLIENT_MUTATION, POST_CLIENT_ACTION, GET_CLIENT_ACTION, CLIENTS_LOADED_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_CLIENT_ACTION](context, payload) {
       
        let response = ''
    
            try {
                response = await axiosInstance.get(`${BASE_URL}/app/client/index?page=${payload.currentPage}`);
            } catch (error) {
                
                return error
            }
      

        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                clients: response.data.data
            }
            context.commit(CLIENTS_LOADED_MUTATION, data)
        }


    },
      async [GET_ALL_CLIENT_ACTION](context, payload) {
       
        let response = ''
            try {
                response = await axiosInstance.get(`${BASE_URL}/app/client/all`)
            } catch (error) {
                return   console.log(error);
            }

        if (response.status == 200) {
            console.log(response.data);
            console.log('asdasdsd');
            let data = {
                loaded: true,
                Allclients: response.data.data,
            }
            context.commit(GET_ALL_CLIENT_MUTATION, data)
        }


    },
    async [POST_CLIENT_ACTION](context, payload) {
        context.commit(POST_CLIENT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/client/create`, payload);
        } catch (e) {
            console.log(e);
            context.commit(POST_CLIENT_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
 
        if (response.status == 200) {

            context.commit(POST_CLIENT_MUTATION, false)
        }


    },
    async [SEARCH_CLIENT_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/client/index?filter[item]=${payload.text}`);
        } catch (e) {
        
            return e;
        }
        if (response.status == 200) {
           
            let data = {
                loaded: true,
                clients: response.data.data
            }
            context.commit(CLIENTS_LOADED_MUTATION, data)
        }


    },
    async [UPDATE_CLIENT_ACTION](context, payload) {
       
        context.commit(POST_CLIENT_MUTATION,true)
       let response = '';
       try {
           response = await axiosInstance.post(`${BASE_URL}/app/client/update`,payload);
       } catch (e) {
       
           context.commit(POST_CLIENT_MUTATION,false)
           for (var key in e.response.data.errors) {
               throw e.response.data.errors[key][0] ;
           }
       }
      
       if (response.status == 200) {
           context.commit(POST_CLIENT_MUTATION,false);
       }


   }, 
   async [DELETE_CLIENT_ACTION](context, payload) {
    context.commit(DELETING_CLIENT_mutations,true)
   const id = payload.clientId;
   let response = '';
   try {
       response = await axiosInstance.delete(`${BASE_URL}/app/client/delete/${id}`);

   } catch (e) {
       context.commit(DELETING_CLIENT_mutations,false);
       return e;
   }
   if (response.status == 200) {
       context.commit(DELETING_CLIENT_mutations,false);
   }
},
}