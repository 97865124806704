import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            wholeSales : [],
            AllwholeSales : [],
            wholeSaleDetail : [],
           wholeSaleLoaded:false,
           postWholesale:false,
           deleteing:false,

        }
    },
    mutations,
    getters,
    actions,
}
