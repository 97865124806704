import axiosInstance from "@/service/AxiosInstance";
import ex from "@/service/External";
import { GET_ALL_WHOLESALE_MUTATION, UPDATE_WHOLESALE_ACTION, GET_PRODUCT_WHOLESALE_ORDER_MUTATION, GET_WHOLESALE_MUTATION, DELETE_DEALER_ACTION, DELETING_DEALER_mutations, DELETEING_WHOLESALE_MUTATION, DELETE_WHOLESALE_ACTION, SEARCH_WHOLESALE_ACTION, POST_DEALER_MUTATION, POST_WHOLESALE_ACTION, GET_WHOLESALE_ACTION, GET_ALL_WHOLESALE_ACTION, POST_WHOLESALE_MUTATION, GET_PRODUCT_BY_ID_WHOLESALE_ORDER_ACTION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_WHOLESALE_ACTION](context, payload) {
        let response = '';

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }


        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                wholeSales: response.data.data
            }
            context.commit(GET_WHOLESALE_MUTATION, data)
        }


    },
    async [GET_ALL_WHOLESALE_ACTION](context, payload) {
        let response = '';

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale/all`);

        } catch (error) {

            return error
        }


        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                AllwholeSales: response.data.data
            }
            context.commit(GET_ALL_WHOLESALE_MUTATION, data)
        }


    },
    async [GET_PRODUCT_BY_ID_WHOLESALE_ORDER_ACTION](context, payload) {
        console.log(555);
        let response = '';

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale/show/${payload}`);

        } catch (error) {
            console.log(error);
            return error
        }


        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                wholeSaleDetail: response.data.data
            }
            context.commit(GET_PRODUCT_WHOLESALE_ORDER_MUTATION, data)
            ex.emit('wholeSaleOrder')
        }


    },

    async [POST_WHOLESALE_ACTION](context, payload) {

        context.commit(POST_WHOLESALE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/wholesale/create`, payload);
        } catch (e) {

            context.commit(POST_WHOLESALE_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        console.log(response.data);
        if (response.status == 200) {

            context.commit(POST_WHOLESALE_MUTATION, false)
        }


    },

    async [UPDATE_WHOLESALE_ACTION](context, payload) {

        context.commit(POST_WHOLESALE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/wholesale/update`, payload);
        } catch (e) {

            context.commit(POST_WHOLESALE_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        console.log(response.data);
        if (response.status == 200) {

            context.commit(POST_WHOLESALE_MUTATION, false)
        }


    },

    async [SEARCH_WHOLESALE_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale/index?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                wholeSales: response.data.data
            }
            context.commit(GET_WHOLESALE_MUTATION, data)
        }


    },

    async [DELETE_WHOLESALE_ACTION](context, payload) {

        context.commit(DELETEING_WHOLESALE_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/wholesale/delete/${payload}`);
        } catch (e) {
            context.commit(DELETEING_WHOLESALE_MUTATION, false)
            if (e.response.status == 500) {
                throw 'الموديل مستخدم لايمكن حذفه '
            }
            return console.log(e);
        }

        if (response.status == 200) {

            context.commit(DELETEING_WHOLESALE_MUTATION, false);
        }


    },

   
}