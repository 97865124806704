import store from '@/store';
import { IS_USER_AUTH_GETTERs } from '@/store/storeConstants';
import axios from 'axios'
const axiosInstance =axios.create([]);

axiosInstance.interceptors.request.use((config)=>{
    const token = store.state['auth'].token ? store.state['auth'].token: null ;
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
})
export default axiosInstance ;