import {GET_SALEINVOCE_return_MUTATION,RETURN_SALEINVOCE_MUTATION,DELETE_SALEINVOCE_MUTATION,EDIT_SALEINVOCE_MUTATION, GET_SALEINVOCE_DETAIL_MUTATION, GET_SALEINVOCE_MUTATION, POST_SALEINVOCE_MUTATION } from "@/store/storeConstants";
export default {

    [GET_SALEINVOCE_MUTATION](state, payload) {
        state.saleInvoices = payload.invoices;
        state.saleInvoicesLoaded = payload.loaded;
    },

    [POST_SALEINVOCE_MUTATION](state, payload) {
        state.postSaleInvoce = payload;
    },

    [GET_SALEINVOCE_DETAIL_MUTATION](state, payload) {
        state.invoiceDetails = payload.invoiceDetails;
        state.invoiceDetailLoaded = payload.loaded;
    }, 
    
    [EDIT_SALEINVOCE_MUTATION](state, payload) {
        state.invoiceEdit = payload.invoiceEdit;
        state.invoiceEditLoaded = payload.loaded;
    }, 
    
    [DELETE_SALEINVOCE_MUTATION](state, payload) {
        state.deleting = payload;
    },  
    
    [RETURN_SALEINVOCE_MUTATION](state, payload) {
        state.returning = payload;
    },

   [GET_SALEINVOCE_return_MUTATION](state, payload) {
        state.invoicesReturn = payload.invoicesReturn;
        state.invoicesReturnLoaded = payload.loaded;
    },



}