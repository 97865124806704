import axiosInstance from "@/service/AxiosInstance";
import { GET_ALL_DEALER_ACTION, DELETE_DEALER_ACTION, GET_ALL_DEALER_MUTATION, DELETING_DEALER_mutations, UPDATE_DEALER_ACTION, SEARCH_DEALER_ACTION, POST_DEALER_MUTATION, POST_DEALER_ACTION, GET_DEALER_ACTION, DEALERS_LOADED_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_DEALER_ACTION](context, payload) {

        let response = '';

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/dealer/index?page=${payload.currentPage}`);
        } catch (error) {
            return error
        }

        if (response.status == 200) {

            let data = {
                loaded: true,
                dealers: response.data.data
            }
            context.commit(DEALERS_LOADED_MUTATION, data)
        }


    },
    async [GET_ALL_DEALER_ACTION](context, payload) {

        let response = '';

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/dealer/all`)
        } catch (error) {
            context.commit(GET_ALL_DEALER_MUTATION, { loaded: true, })
            return console.log(error);
        }


        if (response.status == 200) {
               console.log(response.data);
            let data = { 
                loaded: true,
                Alldealers: response.data.data
            }
            context.commit(GET_ALL_DEALER_MUTATION, data)
        }


    },
    async [POST_DEALER_ACTION](context, payload) {

        context.commit(POST_DEALER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/dealer/create`, payload);
        } catch (e) {

            context.commit(POST_DEALER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        console.log(response);
        if (response.status == 200) {

            context.commit(POST_DEALER_MUTATION, false)
        }


    },
    async [SEARCH_DEALER_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/dealer/index?filter[item]=${payload.text}`);
        } catch (e) {

            return e;
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                dealers: response.data.data
            }
            context.commit(DEALERS_LOADED_MUTATION, data)
        }


    },
    async [UPDATE_DEALER_ACTION](context, payload) {

        context.commit(POST_DEALER_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/dealer/update`, payload);
        } catch (e) {

            context.commit(POST_DEALER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }

        if (response.status == 200) {
            context.commit(POST_DEALER_MUTATION, false);

        }


    },
    async [DELETE_DEALER_ACTION](context, payload) {

        context.commit(DELETING_DEALER_mutations, true)
        const id = payload.dealerId;
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/dealer/delete/${id}`);

        } catch (e) {
            context.commit(DELETING_DEALER_mutations, false)
            console.log(e);
            return e;
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETING_DEALER_mutations, false)


        }


    },
}