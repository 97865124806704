import {GET_BOUNS_CLIENT_BY_ID_MUTATION,DELETEING_BOUNS_CLIENT_ONE_MUTATION,DELETEING_BOUNS_CLIENT_MUTATION,POST_BOUNS_CLIENT_MUTATION,GET_CLIENTS_DETAIL_BOUNS_MUTATION,BOUNS_CLIENT_LOADED_MUTATION,GET_BOUNS_CLIENT_DETAIL_MUTATION} from "@/store/storeConstants";
export default {
    [BOUNS_CLIENT_LOADED_MUTATION](state, payload) {
        state.bonusClients = payload.bonusClients;
        state.bonusClientLoaded = payload.bonusClientLoaded;
    }, 
     [GET_BOUNS_CLIENT_DETAIL_MUTATION](state, payload) {
        state.bonusClientDetails = payload.bonusClientDetails;
        state.bonusClientDetailLoaded = payload.bonusClientDetailLoaded;
    },  
      [POST_BOUNS_CLIENT_MUTATION](state, payload) {
        state.postBonus = payload;
    },
      [DELETEING_BOUNS_CLIENT_MUTATION](state, payload) {
        state.deleteing = payload;
      }, 
      [DELETEING_BOUNS_CLIENT_ONE_MUTATION](state, payload) {
        state.deleteing = payload;
      },

     [GET_CLIENTS_DETAIL_BOUNS_MUTATION](state, payload) {
        state.bonusDetailCleints = payload.bonusClients;
        state.bonusDetailClientsLoaded = payload.bonusClientsLoaded;
      },  
       [GET_BOUNS_CLIENT_BY_ID_MUTATION](state, payload) {
        state.protacionBonusId = payload.protacionBonusId;
      },
 

}