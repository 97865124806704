import {WHOLESALE_STATUS_MUTATION,POST_WHOLESALE_ORDER_MUTATION,DELETEING_WHOLESALE_MUTATION,POST_WHOLESALE_MUTATION,GET_WHOLESALE_ORDER_MUTATION,GET_WHOLESALE_MUTATION,DELETEING_WHOLESALE_ORDER_MUTATION} from "@/store/storeConstants";
export default {
    [GET_WHOLESALE_MUTATION](state, payload) {
        state.wholeSales = payload.wholeSales;
        state.wholeSaleLoaded = payload.loaded;
    },
    [POST_WHOLESALE_MUTATION](state, payload) {
        state.postWholesale = payload
    },
    [DELETEING_WHOLESALE_MUTATION](state, payload) {
        state.deleteing = payload
    },
    [DELETEING_WHOLESALE_ORDER_MUTATION](state, payload) {
        state.deleteing = payload
    },
     [GET_WHOLESALE_ORDER_MUTATION](state, payload) {
        state.wholeSaleOrders = payload.wholeSaleOrders
        state.wholeSaleorderLoaded = payload.loaded
    },
     [POST_WHOLESALE_ORDER_MUTATION](state, payload) {
        state.postOrderWholesale = payload
    }, 
    [WHOLESALE_STATUS_MUTATION](state, payload) {
        state.changingStatus = payload
    }, 

}