import {GET_OPERATING_FINANCE_MUTATION,DELETE_OPERATING_PAYMENT_MUTATION,GET_OPERATING_PAYMENT_MUTATION,POST_OPERATING_PAYMENT_MUTATION,DELETE_OPERATING_MUTATION,GET_OPERATING_MUTATION,POST_OPERATING_MUTATION} from "@/store/storeConstants";
export default {
  
     [GET_OPERATING_MUTATION](state, payload) {
        state.operatingLoaded = payload.loaded;
        state.operatings = payload.operatings;
    },  
     [POST_OPERATING_MUTATION](state, payload) {
        state.postOpreating = payload;
 
    },  
       
    [POST_OPERATING_PAYMENT_MUTATION](state, payload) {
        state.postPayment = payload;
 
    },  
      [DELETE_OPERATING_PAYMENT_MUTATION](state, payload) {
        state.deleteing = payload;
 
    },  
     [GET_OPERATING_PAYMENT_MUTATION](state, payload) {
        state.operatingPaymentLoaded = payload.loaded;
        state.operatingPayments = payload.operatingPayments;
 
    },  
    
    [DELETE_OPERATING_MUTATION](state, payload) {
        state.deleteing = payload;

    },    
    
    [GET_OPERATING_FINANCE_MUTATION](state, payload) {
        state.financeLoaded = payload.loaded;
        state.finance = payload.finance;
    },  





}