import axiosInstance from "@/service/AxiosInstance";

import { SEARCH_FUNDN_ACTION, POST_FUNDN_MUTATION, POST_FUNDN_ACTION, GET_FUNDN_MUTATION, GET_FUNDN_ACTION, GET_FUNDN_OPEN_ACTION, GET_FUNDN_OPEN_MUTATION, POST_FUNDN_OPEN_MUTATION, POST_FUNDN_OPEN_ACTION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_FUNDN_OPEN_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/fundn/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                fundns: response.data.data
            }
            context.commit(GET_FUNDN_OPEN_MUTATION, data)
        }
    },

    async [GET_FUNDN_ACTION](context, payload) {
        let response = '';
        try {
            if (payload.text) {
                response = await axiosInstance.get(`${BASE_URL}/app/fundn/get_fundn?page=${payload.currentPage}&search=${payload.text}`);

            } else if (payload.endDate && payload.startDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/fundn/get_fundn?page=${payload.currentPage}&start_date=${payload.startDate}&end_date=${payload.endDate}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/fundn/get_fundn?page=${payload.currentPage}`);

            }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                fundnMovments: response.data.data,
                totalInput: response.data.totalinput,
                totalOutput: response.data.totaloutput,
                totalExpense: response.data.totalExpense,
                finalFundn: response.data.finalFundn,
                fundnOpen: response.data.fundnOpen,
            }

            context.commit(GET_FUNDN_MUTATION, data)
        }
    },

    async [SEARCH_FUNDN_ACTION](context, payload) {

        let response = '';
        try {
            if (payload.startDate && payload.endDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/fundn/get_fundn?start_date=${payload.startDate}&end_date=${payload.endDate}`);
            } else {

                response = await axiosInstance.get(`${BASE_URL}/app/fundn/get_fundn?search=${payload.text}`);
            }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                fundnMovments: response.data.data,
                totalInput: response.data.totalinput,
                totalOutput: response.data.totaloutput,
                totalExpense: response.data.totalExpense,
                finalFundn: response.data.finalFundn,
                fundnOpen: response.data.fundnOpen,
            }
            context.commit(GET_FUNDN_MUTATION, data)
        }
    },

    async [POST_FUNDN_OPEN_ACTION](context, payload) {
        context.commit(POST_FUNDN_OPEN_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/fundn/create`, payload);
        } catch (error) {
            context.commit(POST_FUNDN_OPEN_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_FUNDN_OPEN_MUTATION, false)
        }


    },

    async [POST_FUNDN_ACTION](context, payload) {
        context.commit(POST_FUNDN_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/fundn/movment/create`, payload);
        } catch (error) {
            context.commit(POST_FUNDN_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_FUNDN_MUTATION, false)
        }


    },




}