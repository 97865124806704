import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced: true,
    state() {
        return {
            fabrices:[],
            fabricCodes:[],
            allFabricCodes:[],
            fabricDrawls:[],
            singleCode:{},
            fabricDrawlLoaded:false,
            fabricLoaded:false,
            fabricCodeLoaded:false,
            AllfabricCodeLoaded:false,
            postFabric:false,
            postDrawls:false,
            deleteing:false,
            total:0,
            type:0,
   
            


        }
    },
    mutations,
    getters,
    actions,
}
