import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import store from '@/store';
import External from "@/service/External";
import { IS_USER_AUTH_GETTERS } from '@/store/storeConstants';
import { getLocalStorage } from '@/service/LocalStorageService';
import { computed, ref } from 'vue';


const router = createRouter({
    history: createWebHistory(),

    routes: [
        {
            path: '/',
            component: AppLayout,
            meta: { auth: true },
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@/views/Dashboard.vue'),

                },
                {
                    path: '/users/admin',
                    name: 'admin',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/auth/Users.vue'),

                },
                {
                    path: '/users/dealer',
                    name: 'dealers',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/dealer/Index.vue'),

                },
                {
                    path: '/users/client',
                    name: 'clients',
                    component: () => import('@/views/pages/client/Index.vue'),

                },
                {
                    path: '/fabric/number',
                    name: 'fabricNumber',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/fabric/fabricNumber/CodeFabric.vue')
                },
                {
                    path: '/fabric/index',
                    name: 'fabric',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/fabric/fabric/Fabric.vue')
                },
                {
                    path: '/fabric/drawls',
                    name: 'fabricDrawls',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/fabric/fabric/FabricDrawls.vue')
                },
                {
                    path: '/operating/index',
                    name: 'operating',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/fabric/operating/Operating.vue')
                },
                {
                    path: '/operating/payment',
                    name: 'operatingPayment',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/fabric/operating/OperatingPayment.vue')
                },
                {
                    path: '/operating/finance',
                    name: 'operatingFinance',
                    meta: { auth: true },
                    component: () => import('@/views/pages/fabric/operating/OperatingFinance.vue')
                },
                {
                    path: '/product/index',
                    name: 'products',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/product/Index.vue')
                },
                {
                    path: '/product/movment/:id',
                    name: 'productMovment',
                    meta: { auth: true },

                    component: () => import('@/views/pages/product/ProductMovment.vue')
                },
                {
                    path: '/product/create',
                    name: 'productCreate',
                    meta: { auth: true },
                    component: () => import('@/views/pages/product/Create.vue')
                },
                {
                    path: '/product/code',
                    name: 'productCode',
                    meta: { auth: true },

                    component: () => import('@/views/pages/product/CodeProduct.vue')
                },
                {
                    path: '/product/detail/:id',
                    name: 'productDetail',
                    meta: { auth: true },

                    component: () => import('@/views/pages/product/ProductDetail.vue')
                },
                {
                    path: '/product/color',
                    name: 'color',
                    meta: { auth: true },

                    component: () => import('@/views/pages/product/Color.vue')
                },
                {
                    path: '/product/size',
                    name: 'size',
                    meta: { auth: true },

                    component: () => import('@/views/pages/product/Size.vue')
                },
                {
                    path: '/region/internal',
                    name: 'regionsInternal',
                    meta: { auth: true },
                    component: () => import('@/views/pages/region/InternalRegion.vue')
                },
                {
                    path: '/region/external',
                    name: 'regionsExternal',
                    meta: { auth: true },

                    component: () => import('@/views/pages/region/ExternalRegion.vue')
                },
                {
                    path: '/region/province',
                    name: 'provinces',
                    meta: { auth: true },

                    component: () => import('@/views/pages/region/Province.vue')
                },
                {
                    path: '/order/select',
                    name: 'orderSelect',
                    meta: { auth: true },

                    component: () => import('@/views/pages/order/SelectProduct.vue')
                },
                {
                    path: '/order/select/create/:id',
                    name: 'orderCreate',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/Create.vue')
                },
                {
                    path: '/order/internal/index',
                    name: 'orderInternal',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/InternalOrder.vue')
                },
                {
                    path: '/order/external/index',
                    name: 'orderExternal',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/ExternalOrder.vue')
                },
                {
                    path: '/order/waiting',
                    name: 'orderWaiting',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/WaitingOrder.vue')
                },
                {
                    path: '/order/OrderDetail',
                    name: 'orderDetail',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/OrderDetail.vue')
                },
                {
                    path: '/order/internal/return',
                    name: 'orderInternalReturn',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/ReturnInternalOrder.vue')
                },
                {
                    path: '/order/external/return',
                    name: 'orderExternalReturn',
                    meta: { auth: true },
                    component: () => import('@/views/pages/order/ReturnExternalOrder.vue')
                },
                {
                    path: '/wholesale/index',
                    name: 'wholeSale',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/wholeSale/Index.vue')
                },
                {
                    path: '/wholesale/order',
                    name: 'wholeSaleOrder',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/wholeSale/Order.vue')
                },
                {
                    path: '/ratio/index',
                    name: 'ratio',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/ratio/Index.vue')
                },
                {
                    path: '/ratio/ratio',
                    name: 'ratioDone',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/ratio/Ratio.vue')
                },
                {
                    path: '/ratio/ratio/detail/:id',
                    name: 'ratioDetail',

                    meta: { auth: true },
                    component: () => import('@/views/pages/ratio/RatioDetail.vue')
                },
                {
                    path: '/delivery/index',
                    name: 'delivery',
                    meta: { auth: true },
                    component: () => import('@/views/pages/delivery/Index.vue')
                },
                {
                    path: '/delivery/order',
                    name: 'deliveryOrder',
                    meta: { auth: true },
                    component: () => import('@/views/pages/delivery/Delivery.vue')
                },
                {
                    path: '/sale_invoice/index',
                    name: 'saleInvoce',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/saleinvoce/SaleInvoice.vue')
                },
                {
                    path: '/sale_invoice/create',
                    name: 'saleInvoceCreate',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/saleinvoce/Create.vue')
                },
                {
                    path: '/sale_invoice/return',
                    name: 'saleInvoceReturn',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/saleinvoce/SaleInvoiceReturn.vue')
                },
                {
                    path: '/sale_invoice/detail/:id',
                    name: 'saleInvoceDetail',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/saleinvoce/saleinvoceDetail.vue')
                },
                {
                    path: '/purchase_invoice/index',
                    name: 'purchaseInvoce',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/purchaseInvoce/PurchaseInvoce.vue')
                },
                {
                    path: '/purchase_invoice/detail/:id',
                    name: 'PurcheaceInvoceDetail',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/purchaseInvoce/PurchaseDetail.vue')
                },
                {
                    path: '/purchase_invoice/create',
                    name: 'purchaseInvoceCreate',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/purchaseInvoce/Create.vue')
                },
                {
                    path: '/purchase_invoice/return',
                    name: 'purchaseInvoceReturn',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/purchaseInvoce/PurchaseInvoceReturn.vue')
                },
                {
                    path: '/catch/index',
                    name: 'catchReceipt',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/receipts/Catch.vue')
                },
                {
                    path: '/payment/index',
                    name: 'paymentReceipt',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/finance/receipts/Payment.vue')
                },
                {
                    path: '/dealer_open/index',
                    name: 'dealerOpen',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/finance/report/DealerOpen.vue')
                },
                {
                    path: '/dealer_report/index',
                    name: 'dealerReport',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/finance/report/DealerReport.vue')
                },
                {
                    path: '/fundn_open/index',
                    name: 'fundnOpen',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/finance/fundn/FundnOpen.vue')
                },
                {
                    path: '/fundn/index',
                    name: 'fundn',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/finance/fundn/Fundn.vue')
                },
                {
                    path: '/budget/index',
                    name: 'budget',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/finance/budget/Budget.vue')
                },
                {
                    path: '/inventory/index',
                    name: 'inventory',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/finance/budget/Inventory.vue')
                },
                {
                    path: '/notification/index',
                    name: 'notifications',
                    meta: { auth: true },
                    component: () => import('@/views/pages/notification/Index.vue')
                },
                {
                    path: '/protaction/client',
                    name: 'protactionClient',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/protaction/Client.vue')
                },
                {
                    path: '/protaction/client/:id',
                    name: 'protactionDetail',
                    meta: { auth: true },

                    component: () => import('@/views/pages/protaction/ProtactionDetail.vue')
                },
                {
                    path: '/protaction/dealer',
                    name: 'protactionDealer',
                    meta: { auth: true },
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    component: () => import('@/views/pages/protaction/Dealer.vue')
                },
                {
                    path: '/bonus/index',
                    name: 'bonus',
                    beforeEnter: (to, from, next) => {
                        if (store.state['auth'].isAdmin) {
                            next()
                        } else {
                            next({ name: 'dashboard' })
                        }
                    },
                    meta: { auth: true },
                    component: () => import('@/views/pages/protaction/Bonus.vue')
                },
                {
                    path: '/bonus/:id',
                    name: 'bonusDetail',
                    meta: { auth: true },
                    component: () => import('@/views/pages/protaction/BonusDetail.vue')
                },



            ],

        },
        {
            path: '/auth/login',
            name: 'login',
            component: () => import('@/views/pages/auth/Login.vue'),

        },



        {
            name: 'not-found',
            path: '/:pathMatch(.*)*',
            component: () => import('@/views/pages/auth/Access.vue'),
        }
    ]
});
router.beforeEach((to, from, next) => {
    const isAuthentication = store.getters[`auth/${IS_USER_AUTH_GETTERS}`]
    if (to.meta.auth && !isAuthentication) {
        next({ name: 'login' })
    } else if (!to.meta.auth && isAuthentication) {
        next({ name: 'dashboard' })
    } else {
        next()
    }
})



export default router;
