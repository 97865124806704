import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            saleInvoices : [],
            invoiceDetails : [],
            invoiceEdit : [],
            invoicesReturn : [],
            postSaleInvoce: false,
            deleting: false,
            invoiceEditLoaded: false,
            returning: false,
            invoicesReturnLoaded: false,
            invoiceDetailLoaded: false,
            saleInvoicesLoaded: false,

        }
    },
    mutations,
    getters,
    actions,
}
