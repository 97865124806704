import { POST_FUNDN_MUTATION,GET_FUNDN_MUTATION,GET_FUNDN_OPEN_MUTATION ,POST_FUNDN_OPEN_MUTATION} from "@/store/storeConstants";
export default {
  
  [GET_FUNDN_OPEN_MUTATION](state, payload) {
        state.fundns = payload.fundns;
        state.fundnLoaded = payload.loaded;
    },  
  [POST_FUNDN_OPEN_MUTATION](state, payload) {
        state.postFundn = payload;
    },   
    
    [GET_FUNDN_MUTATION](state, payload) {
        state.fundnMovments = payload.fundnMovments;
        state.fundnMovmentLoaded = payload.loaded;
        state.totalOutput = payload.totalOutput;
        state.totalInput = payload.totalInput;
        state.totalExpense = payload.totalExpense;
        state.finalFundn = payload.finalFundn;
        state.fundnOpen = payload.fundnOpen;

    },  
    
    [POST_FUNDN_MUTATION](state, payload) {
        state.postMovment = payload;
        
    },  



}