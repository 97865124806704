import {GET_FUNDN_INVENTORY_MUTATION,GET_DEALER_INVENTORY_MUTATION,GET_ORDER_INVENTORY_MUTATION,GET_PRODUCT_INVENTORY_MUTATION,GET_BUDGET_MUTATION} from "@/store/storeConstants";
export default {
  
     [GET_BUDGET_MUTATION](state, payload) {
        state.loadedBudget = payload.loaded;
        state.delaerprofit = payload.delaerprofit;
        state.wholesaleProfit = payload.wholesaleProfit;
        state.orderProfit = payload.orderProfit;
        state.fundn = payload.fundn;
    },  
     [GET_PRODUCT_INVENTORY_MUTATION](state, payload) {
        state.productInvLoaded = payload.loaded;
        state.productInvs = payload.products;
        state.sumProducts = payload.sumProducts;
    }, 
    
    [GET_ORDER_INVENTORY_MUTATION](state, payload) {
        state.orderInvLoaded = payload.loaded;
        state.orderInvs = payload.orders;
        state.sumOrder = payload.sumOrder;

    },   
    
    [GET_DEALER_INVENTORY_MUTATION](state, payload) {
        state.dealerInvLoaded = payload.loaded;
        state.dealerInvs = payload.dealers;
        state.dealerLength = payload.dealerLength;
        state.sumCreditF = payload.sumCreditF;
        state.sumDebitF = payload.sumDebitF;
    },  
    
    [GET_FUNDN_INVENTORY_MUTATION](state, payload) {
        state.fundnInvLoaded = payload.loaded;
        state.fundnInvs = payload.fundns;
    }, 
    




}