import axiosInstance from "@/service/AxiosInstance";
import ec from "@/service/External";
import { Validation } from "@/service/Validations";
import {GET_PRODUCTS_BY_ID_INVOICE_RETURN_ACTION,GET_PRODUCT_MOVMENT_ACTION,GET_PRODUCT_MOVMENT_MUTATION, GET_ALL_PRODUCTS_WITHOUT_PROTACTION_DEALER_ACTION, GET_PRODUCTS_DEALER_MUTATION, GET_PRODUCTS_BY_ID_INVOICE_MUTATION, GET_PRODUCTS_BY_ID_INVOICE_ACTION, GET_COUNT_PRODUCT_MUTATION, GET_COUNT_PRODUCT_ACTION, GET_ALL_CODE_PRODUCT_MUTATION, GET_ALL_CODE_PRODUCT_ACTION, GET_CODE_BY_ID_ACTION, GET_CODE_BY_ID_MUTATION, ALL_PRODUCTS_MUTATION, ALL_PRODUCTS_ACTION, GET_SIZE_BY_ID_MUTATION, GET_SIZE_BY_ID_ACTION, GET_PRODUCTS_BY_ID_MUTATION, GET_PRODUCTS_BY_ID_ACTION, GET_ALL_PRODUCTS_MUTATION, GET_ALL_PRODUCTS_WITHOUT_PROTACTION_CLIENT_ACTION, POST_SIZE_MUTATION, DELETE_SIZE_ACTION, SEARCH_SIZE_ACTION, EDIT_SIZE_ACTION, POST_SIZE_ACTION, GET_SIZE_PAGE_ACTION, SEARCH_COLOR_ACTION, DELETE_COLOR_ACTION, EDIT_COLOR_ACTION, POST_COLOR_ACTION, DELETE_PRODUCT_COLOR_ACTION, GET_COLOR_PAGE_ACTION, FILTER_PRODUCT_ACTION, DELETE_PRODUCT_SIZE_ACTION, EDIT_PRODUCT_ACTION, SEARCH_PRODUCT_ACTION, PODUCT_DETAIL_MUTATION, PODUCT_DETAIL_ACTION, DELETE_PRODUCT_ACTION, GET_PRODUCTS_ACTION, POSTING_PORDUCT_MUTATION, POST_PRODUCT_ACTION, CATEGORY_LOADED_MUTATION, GET_CATEGORIES_ACTION, SEARCH_PRODUCT_NUMBER_ACTION, DELETING_PRODUCT_CODE_MUTATION, DELETE_PRODUCT_CODE_ACTION, EDIT_PRODUCT_CODE_ACTION, GET_CODE_PRODUCT_ACTION, BASE_URL, GET_SIZE_ACTION, SIZE_LOADED_MUTATION, GET_COLOR_ACTION, COLOR_LOADED_MUTATION, CODE_LOADED_MUTATION, POST_PRODUCT_CODE_ACTION, POST_PRODUCT_CODE_MUTATION, GET_PRODUCTS_MUTATION } from "@/store/storeConstants";

export default {

    ///////////////product Actions//////////////////////
    async [GET_PRODUCTS_ACTION](context, payload) {

        let response = ''
        try {
            if (payload.FlterValue != undefined) {
                response = await axiosInstance.get(`${BASE_URL}/app/product/index?page=${payload.currentPage}&sort=${payload.FlterValue.sort}&type=${payload.FlterValue.type}`)
            } else {

                response = await axiosInstance.get(`${BASE_URL}/app/product/index?page=${payload.currentPage}&search=${payload.filter}`)
            }

        } catch (e) {

            return console.log(e);
        }
        if (response.status = 200) {
            console.log(response.data);
            context.commit(GET_PRODUCTS_MUTATION, {
                products: response.data.data,
                productLoaded: true
            });
        }

    },

    async [GET_COUNT_PRODUCT_ACTION](context, payload) {


        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/get_count/${payload}`)

        } catch (e) {

            return console.log(e);
        }
        if (response.status = 200) {


            context.commit(GET_COUNT_PRODUCT_MUTATION, {
                productCount: response.data.data[0].count,

            });
        }

    },
    async [GET_PRODUCT_MOVMENT_ACTION](context, payload) {

        let response = ''
        try {
            if(payload.currentPage)
            {      response = await axiosInstance.get(`${BASE_URL}/app/product_movment/index/${payload.id}?page=${payload.currentPage}`)

            }else{
                response = await axiosInstance.get(`${BASE_URL}/app/product_movment/index/${payload}`)
            }
      

        } catch (e) {

            return console.log(e);
        }
        if (response.status = 200) {

            const data = {
                loaded :true ,
                movments :response.data.data ,
                movmentSum :response.data.movmentSum ,
            }
            context.commit(GET_PRODUCT_MOVMENT_MUTATION,data);
        }

    },

    async [GET_PRODUCTS_BY_ID_ACTION](context, payload) {
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/get_product_id/${payload}`)
        } catch (e) {
            return console.log(e);
        }
        if (response.status = 200) {
            console.log('inmnnnn');
            console.log(response.data.data);
            context.commit(GET_PRODUCTS_BY_ID_MUTATION, {
                productById: response.data.data,
                loadProductId: true,
            });
            ec.emit('productIdLoaded')
        }

    },

    async [GET_PRODUCTS_BY_ID_INVOICE_ACTION](context, payload) {
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/invoce-sale/get_product_id/${payload}`)
        } catch (e) {
            return console.log(e);
        }
        if (response.status = 200) {

            context.commit(GET_PRODUCTS_BY_ID_INVOICE_MUTATION, {
                productById: response.data.data,
                loadProductId: true,
            });
            ec.emit('productIdInoviceLoaded')
        }

    },  
    
    async [GET_PRODUCTS_BY_ID_INVOICE_RETURN_ACTION](context, payload) {
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/get_product_id/${payload}`)
        } catch (e) {
            return console.log(e);
        }
        if (response.status = 200) {

            context.commit(GET_PRODUCTS_BY_ID_INVOICE_MUTATION, {
                productById: response.data.data,
                loadProductId: true,
            });
            ec.emit('productIdInoviceReturnLoaded')
        }

    },
    async [ALL_PRODUCTS_ACTION](context, payload) {
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/all`)
        } catch (e) {
            return console.log(e);
        }
        if (response.status = 200) {
            console.log(response.data);
            context.commit(ALL_PRODUCTS_MUTATION, {
                ProductsPure: response.data.data,
                loadProducts: true,
            });
            ec.emit('productIdLoaded')
        }

    },

    async [GET_ALL_PRODUCTS_WITHOUT_PROTACTION_CLIENT_ACTION](context, payload) {

        let response = ''
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/product/to_protaction_client/all`)


        } catch (e) {
            return console.log(e);
        }
        if (response.status = 200) {


            context.commit(GET_ALL_PRODUCTS_MUTATION, {
                AllProducts: response.data.data,
                AllProductLoaded: true
            });
        }

    },

    async [GET_ALL_PRODUCTS_WITHOUT_PROTACTION_DEALER_ACTION](context, payload) {
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/to_protaction_dealer/all`)
        } catch (e) {
            return console.log(e);
        }
        if (response.status = 200) {
 
            context.commit(GET_PRODUCTS_DEALER_MUTATION, {
                AllProducts: response.data.data,
                AllProductLoaded: true
            });
        }

    },

    async [POST_PRODUCT_ACTION](context, payload) {
        context.commit(POSTING_PORDUCT_MUTATION, true);
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/product/create`, payload)
        } catch (e) {

            context.commit(POSTING_PORDUCT_MUTATION, false);
            console.log(e);
            if (e.response.status == 500) {
                throw 'اللون او القياس مكرر'
            }
            for (var key in e.response.data.errors) {

                throw e.response.data.errors[key][0];
            }
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(POSTING_PORDUCT_MUTATION, false);
        }

    },

    async [EDIT_PRODUCT_ACTION](context, payload) {

        context.commit(POSTING_PORDUCT_MUTATION, true);
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/product/edit`, payload)
        } catch (e) {
            console.log(e);
            context.commit(POSTING_PORDUCT_MUTATION, false);
            if (e.response.status == 500) {
                throw 'اللون او القياس مكرر'
            }
            for (var key in e.response.data.errors) {

                throw e.response.data.errors[key][0];
            }

        }

        if (response.status = 200) {
            console.log(response);
            context.commit(POSTING_PORDUCT_MUTATION, false);

        }

    },

    async [PODUCT_DETAIL_ACTION](context, payload) {
        context.commit(PODUCT_DETAIL_MUTATION, { loaded: false });
        const id = payload;

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/detail/${id}`)
        } catch (e) {
            context.commit(PODUCT_DETAIL_MUTATION, { loaded: true });
            context.commit(PODUCT_DETAIL_MUTATION, { loaded: false });
            return console.log(e);
        }
        if (response.status = 200) {

            context.commit(PODUCT_DETAIL_MUTATION, {
                productDetail: response.data.data,
                loaded: true
            });
            ec.emit('detaiLoaded');
        }

    },

    async [SEARCH_PRODUCT_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/index?search=${payload.text}`)
        } catch (e) {
            return console.log(e);

        }


        if (response.status = 200) {

            let data = {
                products: response.data.data,
                productLoaded: true
            }
            context.commit(GET_PRODUCTS_MUTATION, data);
        }

    },

    async [DELETE_PRODUCT_ACTION](context, payload) {
        context.commit(DELETING_PRODUCT_CODE_MUTATION, true)
        const id = payload.productId;
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/product/delete/${id}`)
        } catch (e) {
            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
            if (e.response.status == 500) {
                throw 'الموديل مستخدم لايمكن حذفه'
            }
        }
        if (response.status = 200) {

            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
        }

    },

    async [DELETE_PRODUCT_COLOR_ACTION](context, payload) {


        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/product/delete/color/${payload}`)
        } catch (e) {

            return console.log(e);
        }
        if (response.status = 200) {

            return true;
        }

    },

    async [DELETE_PRODUCT_SIZE_ACTION](context, payload) {


        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/product/delete/size/${payload}`)
        } catch (e) {

            return console.log(e);
        }
        if (response.status = 200) {

            return;
        }

    },

    async [GET_SIZE_ACTION](context, payload) {
        context.commit(SIZE_LOADED_MUTATION, { size: false });
        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/size/all`)
        } catch (error) {
            context.commit(SIZE_LOADED_MUTATION, { size: true });
            return console.log(error);
        }
        if (response.status = 200) {
            let data = {
                sizes: response.data.data,
                sizeLoaded: true
            }
            context.commit(SIZE_LOADED_MUTATION, data);
        }
    },

    ////////////////////////End//////////////////////////////



    ///////////////prodcutNumber Actions//////////////////////

    async [GET_CODE_PRODUCT_ACTION](context, payload) {

        let response = ''
        if (payload) {
            try {
                response = await axiosInstance.get(`${BASE_URL}/app/code/index?page=${payload.currentPage}`)
            } catch (error) {

                return console.log(error);
            }
        } else {

            try {
                response = await axiosInstance.get(`${BASE_URL}/app/code/all`)
            } catch (error) {
                context.commit(CODE_LOADED_MUTATION, { codeLoaded: true });
                return console.log(error);
            }
        }

        if (response.status = 200) {

            console.log(response.data);
            let data = {
                codes: response.data.data,
                codeLoaded: true
            }

            context.commit(CODE_LOADED_MUTATION, data);
        }
    },

    async [GET_ALL_CODE_PRODUCT_ACTION](context, payload) {

        let response = ''

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/code/all`)
        } catch (error) {
            context.commit(GET_ALL_CODE_PRODUCT_MUTATION, { codeLoaded: true });
            return console.log(error);
        }


        if (response.status = 200) {

            let data = {
                ALLcodes: response.data.data,
                codeLoaded: true
            }

            context.commit(GET_ALL_CODE_PRODUCT_MUTATION, data);
        }
    },

    async [GET_CODE_BY_ID_ACTION](context, payload) {
        
        let response = ''

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/code/show/${payload}`)
        } catch (error) {

            return console.log(error);
        }

        if (response.status = 200) {

            let data = {
                singleCode: response.data.data[0]
            }

            context.commit(GET_CODE_BY_ID_MUTATION, data);
        }
    },

    async [POST_PRODUCT_CODE_ACTION](context, payload) {

        context.commit(POST_PRODUCT_CODE_MUTATION, true);
        let productNameData = {
            name: payload.nameProduct,
            code: payload.codeProduct,
        }
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/code/create`, productNameData)
        } catch (e) {
            console.log(e);
            context.commit(POST_PRODUCT_CODE_MUTATION, false);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }


        }
        if (response.status = 200) {

            context.commit(POST_PRODUCT_CODE_MUTATION, false);
        }

    },

    async [EDIT_PRODUCT_CODE_ACTION](context, payload) {
        context.commit(POST_PRODUCT_CODE_MUTATION, true);
        const id = payload.productId;
        let productNameData = {
            name: payload.nameProduct,
            code: payload.codeProduct,
            id: id,
        }
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/code/update`, productNameData)

        } catch (e) {

            context.commit(POST_PRODUCT_CODE_MUTATION, false);
            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(POST_PRODUCT_CODE_MUTATION, false);
        }

    },

    async [SEARCH_PRODUCT_NUMBER_ACTION](context, payload) {

        let response = ''
        try {
        
            response = await axiosInstance.get(`${BASE_URL}/app/code/index?code=${payload.text}`)
        } catch (e) {
            return console.log(e);
            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {
            let data = {
                codes: response.data.data,
                codeLoaded: true
            }
            context.commit(CODE_LOADED_MUTATION, data);
        }

    },

    async [FILTER_PRODUCT_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/index?sort=${payload.sort}&type=${payload.type}`)
        } catch (e) {
            return console.log(e);
            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {
            console.log(response.data);
            let data = {
                products: response.data.data,
                productLoaded: true
            }
            context.commit(GET_PRODUCTS_MUTATION, data);
        }

    },

    async [GET_COLOR_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/color/all`)
        } catch (error) {

            return console.log(error);
        }
        if (response.status = 200) {

            let data = {
                colors: response.data.data,
                colorLoaded: true
            }

            context.commit(COLOR_LOADED_MUTATION, data);
        }
    },

    async [DELETE_PRODUCT_CODE_ACTION](context, payload) {
        context.commit(DELETING_PRODUCT_CODE_MUTATION, true);
        const id = payload.productId;

        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/code/delete/${id}`)
        } catch (e) {
            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
            if (e.response.status == 500)
                throw 'رقم الموديل مستخدم لايمكن حذفه'
        }

        if (response.status = 200) {
            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
        }

    },

    ////////////////////End////////////////////////


    ///////////////Color Actions//////////////////////

    async [GET_COLOR_PAGE_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/color/index?page=${payload.currentPage}`)
        } catch (error) {

            return console.log(error);
        }
        if (response.status = 200) {

            let data = {
                colors: response.data.data,
                colorLoaded: true
            }

            context.commit(COLOR_LOADED_MUTATION, data);
        }
    },

    async [POST_COLOR_ACTION](context, payload) {
        context.commit(POST_COLOR_ACTION, true);

        let productNameData = {
            name: payload.name,
        }
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/color/create`, productNameData)
        } catch (e) {
            console.log(e);
            context.commit(POST_COLOR_ACTION, false);


            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        if (response.status = 200) {

            context.commit(POST_COLOR_ACTION, false);
        }
    },

    async [EDIT_COLOR_ACTION](context, payload) {
        context.commit(POST_COLOR_ACTION, true);
        let productNameData = {
            name: payload.name,
            id: payload.colorId,
        }
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/color/update`, productNameData)

        } catch (e) {
            context.commit(POST_COLOR_ACTION, false);
            return console.log(e);

            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(POST_COLOR_ACTION, false);
        }

    },

    async [SEARCH_COLOR_ACTION](context, payload) {
        let response = ''
        try {
            // response = await axiosInstance.get(`${BASE_URL}/app/code/index?filter[item]=${{ code:payload.text }}`)
            response = await axiosInstance.get(`${BASE_URL}/app/color/index?filter[item]=${payload.text}`)
        } catch (e) {
            return console.log(e);
            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {
            let data = {
                colors: response.data.data,
                colorLoaded: true
            }
            context.commit(COLOR_LOADED_MUTATION, data);
        }

    },

    async [DELETE_COLOR_ACTION](context, payload) {
        context.commit(DELETING_PRODUCT_CODE_MUTATION, true);
        const id = payload.colorId;
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/color/delete/${id}`)
        } catch (e) {

            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
            if (e.response.status == 500)
                throw 'اللون مستخدم لايمكن حذفه'

        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
        }

    },

    ///////////////End//////////////////////



    ///////////////size Actions//////////////////////
    async [GET_SIZE_PAGE_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/size/index?page=${payload.currentPage}`)
        } catch (error) {
            return console.log(error);
        }
        if (response.status = 200) {

            let data = {
                sizes: response.data.data,
                sizeLoaded: true
            }

            context.commit(SIZE_LOADED_MUTATION, data);
        }
    },
    async [GET_SIZE_BY_ID_ACTION](context, payload) {
        let response = ''
        console.log('qweqwe');
        console.log(payload);
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/product/size/${payload}`)
        } catch (error) {
            return console.log(error);
        }
        if (response.status = 200) {
            console.log(response);
            let data = {
                sizesById: response.data.data,
            }

            context.commit(GET_SIZE_BY_ID_MUTATION, data);
        }
    },
    async [POST_SIZE_ACTION](context, payload) {
        context.commit(POST_SIZE_MUTATION, true);
        let productNameData = {
            name: payload.name,
        }
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/size/create`, productNameData)
        } catch (e) {
            context.commit(POST_SIZE_MUTATION, false);

            for (var key in e.response.data.errors) {

                throw e.response.data.errors[key][0];
            }
        }
        if (response.status = 200) {

            context.commit(POST_SIZE_MUTATION, false);
        }
    },
    async [EDIT_SIZE_ACTION](context, payload) {
        context.commit(POST_SIZE_MUTATION, true);
        let productNameData = {
            name: payload.name,
            id: payload.sizeId,
        }
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/size/update`, productNameData)

        } catch (e) {
            context.commit(POST_SIZE_MUTATION, false);

            for (var key in e.response.data.errors) {

                throw e.response.data.errors[key][0];
            }
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(POST_SIZE_MUTATION, false);
        }

    },
    async [SEARCH_SIZE_ACTION](context, payload) {
        let response = ''
        try {
            // response = await axiosInstance.get(`${BASE_URL}/app/code/index?filter[item]=${{ code:payload.text }}`)
            response = await axiosInstance.get(`${BASE_URL}/app/size/index?filter[item]=${payload.text}`)
        } catch (e) {
            return console.log(e);
            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {

            let data = {
                sizes: response.data.data,
                sizeLoaded: true
            }
            context.commit(SIZE_LOADED_MUTATION, data);
        }

    },
    async [DELETE_SIZE_ACTION](context, payload) {
        context.commit(DELETING_PRODUCT_CODE_MUTATION, true);
        const id = payload.sizeId;
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/size/delete/${id}`)
        } catch (e) {

            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
            if (e.response.status == 500)
                throw 'القياس مستخدم لايمكن حذفه'

        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(DELETING_PRODUCT_CODE_MUTATION, false)
        }

    },

    ///////////////End//////////////////////


    ///////////////categories Actions//////////////////////

    async [GET_CATEGORIES_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/category/index`)
        } catch (e) {
            return console.log(e);
            for (var key in e.response.data.errors) {
                throw Validation.getErrorMessage(e.response.data.errors[key][0])
            }
        }

        if (response.status = 200) {
            let data = {
                categories: response.data.data,
                categoryLoaded: true
            }
            context.commit(CATEGORY_LOADED_MUTATION, data);
        }

    },

    ///////////////End//////////////////////














}