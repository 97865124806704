import {GET_ID_PRODUCT_INVOCE_GETTERS, GET_COLOR_PRODUCT_GETTERS,GET_ID_PRODUCT_GETTERS } from "@/store/storeConstants"

export default {
    [GET_ID_PRODUCT_GETTERS]:(state)=>{
        return state.productById['product'][0]
    }, 

    [GET_COLOR_PRODUCT_GETTERS]:(state)=>{
        return state.productById['colors']
    }
  
}
