import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced: true,
    state() {
        return {


            resultReportReturn: [],
            resultReportDone: [],
            loadedDashboard: false,
            loadedReportDashboard: false,
            internal_orders: 0,
            internal_orders_done: 0,
            external_orders: 0,
            countProductPay: 0,
            external_orders_done: 0,
            waiting_orders: 0,
            return_external: 0,
            return_internal: 0,
            products: 0,
            dealers: 0,
            fundns: 0,
            clients: 0,
            saleInvoice: 0,
            saleInvoice_return: 0,
            deliveries: 0,
            productNumber: 0,


        }
    },
    mutations,
    getters,
    actions,
}
