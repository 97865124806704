import {GET_ALL_CLIENT_MUTATION,DELETING_CLIENT_mutations,POST_CLIENT_MUTATION,CLIENTS_LOADED_MUTATION} from "@/store/storeConstants";
export default {
    [CLIENTS_LOADED_MUTATION](state, payload) {
        state.clients = payload.clients;
        state.clientLoaded = payload.loaded;
    },
    [POST_CLIENT_MUTATION](state, payload) {
        state.postClient= payload
    },
    [DELETING_CLIENT_mutations](state, payload) {
        state.deleteing = payload
    }, 
    [GET_ALL_CLIENT_MUTATION](state, payload) {
        state.Allclients = payload.Allclients
    },
}