import axiosInstance from "@/service/AxiosInstance";

import {UPDATE_PAYMENT_RECEIPT_ACTION,DELETE_PAYMENT_RECEIPT_ACTION,DELETE_PAYMENT_RECEIPT_MUTATION,FILTER_PAYMENT_RECEIPT_MUTATION,POST_PAYMENT_RECEIPT_MUTATION,POST_PAYMENT_RECEIPT_ACTION,GET_PAYMENT_RECEIPT_MUTATION,DELETE_CATCH_RECEIPT_ACTION, GET_PAYMENT_RECEIPT_ACTION,GET_CATCH_RECEIPT_MUTATION, FILTER_CATCH_RECEIPT_ACTION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_PAYMENT_RECEIPT_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/payment/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            let data = {
                loaded: true,
                receipts: response.data.data
            }
            context.commit(GET_PAYMENT_RECEIPT_MUTATION, data)
        }
    }, 
    
    async [POST_PAYMENT_RECEIPT_ACTION](context, payload) {
        context.commit(POST_PAYMENT_RECEIPT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/payment/create`,payload);
        } catch (error) {
            context.commit(POST_PAYMENT_RECEIPT_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_PAYMENT_RECEIPT_MUTATION, false)
        }
    },  
    
    async [DELETE_PAYMENT_RECEIPT_ACTION](context, payload) {
        context.commit(DELETE_PAYMENT_RECEIPT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/payment/delete/${payload.id}`);
        } catch (error) {
            context.commit(DELETE_PAYMENT_RECEIPT_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(DELETE_PAYMENT_RECEIPT_MUTATION, false)
        }
    },  
    
    async [UPDATE_PAYMENT_RECEIPT_ACTION](context, payload) {
        context.commit(POST_PAYMENT_RECEIPT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/payment/update`,payload);
        } catch (error) {
            context.commit(POST_PAYMENT_RECEIPT_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_PAYMENT_RECEIPT_MUTATION, false)
        }
    },

    async [FILTER_PAYMENT_RECEIPT_MUTATION](context, payload) {
   
        let response = '';
        try {
            if(payload.sortDealer)
            {
                response = await axiosInstance.get(`${BASE_URL}/app/payment/index?sort_dealer=${payload.sortDealer}`);
            }else{
                response = await axiosInstance.get(`${BASE_URL}/app/payment/index?search=${payload.text}`);
              }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                receipts: response.data.data
            }
            context.commit(GET_PAYMENT_RECEIPT_MUTATION, data)
        }
    }, 




}