import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            regionsInternal: [],
            AllRegionsInternal: [],
            regionsExtrenal: [],
            provinces: [],
            provinceRegions: [],
            regionInternalLoaded:false,
            AllRegionsLoaded:false,
            regionExtrenalLoaded:false,
            provinceLoaded:false,
            postProvince:false,
            postInternal:false,
            postExternal:false,
            deleteing:false,
        }
    },
    mutations,
    getters,
    actions,
}
