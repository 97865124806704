import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
           products:[],
           AllProducts:[],
           AllProductsInvoice:[],
           colors:[],
           sizes:[], 
           codes:[],
           categories:[],
           productDetail:[],
           ProductsPure:[],
           sizesById:[],
           productById:[],
           productByIdInvoice:[],
           ALLcodes:[],
           movments:[],
           singleCode:{},
           productDetailLoading:false,
           movmentLoaded:false,
           sizeLoaded:false,
           colorLoaded:false,
           AllProductInvoceLoaded:false,
           productLoaded:false,
           productInvouceLoaded:false,
           loadProducts:false,
           codeLoaded:false,
           postCode:false,
           postColor:false,
           postSize:false,
           deleteing:false,
           porductColorDeleting:false,
           categoryLoaded:false,
           postingProduct:false,
           updatingProduct:false,
           loadProductId:false,
           loadProductIdInvoce:false,
           productCount:0,
           movmentSum:0,
        }
    },
    mutations,
    getters,
    actions,
}
