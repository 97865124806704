import {GET_PROVINCE_REGION_MUTATION, GET_ALL_REGION_INERNAL_MUTATION,POST_PROVINCE_MUTATION, DELETING_PROVINCE_MUTATION, POST_EXTERNAL_REGION_MUTATION, POST_INTERNAL_REGION_MUTATION, PROVINCE_LOADED_MUTATION, REGION_INERNAL_LOADED_MUTATION, REGION_EXTERNAL_LOADED_MUTATION, POST_REGION_MUTATION, DELETING_REGION_MUTATION } from "@/store/storeConstants";
export default {
    [REGION_INERNAL_LOADED_MUTATION](state, payload) {
        state.regionsInternal = payload.regionsInternal;
        state.regionInternalLoaded = payload.loaded;
    },
    [REGION_EXTERNAL_LOADED_MUTATION](state, payload) {
        state.regionsExtrenal = payload.regionsExtrenal;
        state.regionExtrenalLoaded = payload.loaded;
    },
    [POST_INTERNAL_REGION_MUTATION](state, payload) {
        state.postInternal = payload;
    },
    [POST_EXTERNAL_REGION_MUTATION](state, payload) {
        state.postExternal = payload;
    },
    [POST_EXTERNAL_REGION_MUTATION](state, payload) {
        state.postExternal = payload;
    },
    [POST_PROVINCE_MUTATION](state, payload) {
        state.postProvince = payload;
    },
    [PROVINCE_LOADED_MUTATION](state, payload) {
        state.provinces = payload.provinces;
        state.provinceLoaded = payload.loaded;
    },
    [DELETING_REGION_MUTATION](state, payload) {
        state.deleteing = payload;
    },

    [DELETING_PROVINCE_MUTATION](state, payload) {
        state.deleteing = payload;
    },

    [GET_ALL_REGION_INERNAL_MUTATION](state, payload) {
        state.AllRegionsInternal = payload.AllRegionsInternal;
        state.AllRegionsLoaded = payload.AllRegionsLoaded;
    },
    [GET_PROVINCE_REGION_MUTATION](state, payload) {
        state.provinceRegions = payload.provinceRegions;
    },

}