import {DELETE_ALL_NOTIFICATION_MUTATION,GET_RATIO_MUTATION,Get_UNREAD_NOTIFICATIONS_MUTATION,GET_ALL_NOTIFICATION_MUTATION} from "@/store/storeConstants";
export default {
    [GET_RATIO_MUTATION](state, payload) {
        state.ratios = payload.ratios;
        state.ratioLoaded = payload.loaded;
        state.totalRatio = payload.totalRatio;
        state.totalBonus = payload.totalBonus;
        state.totalProtaction = payload.totalProtaction;
        state.finalRatio = payload.finalRatio;
        state.totalDiscount = payload.totalDiscount;
        state.countOrderReturn = payload.countOrderReturn;
        state.countOrderDone = payload.countOrderDone;
    },  
      [Get_UNREAD_NOTIFICATIONS_MUTATION](state, payload) {
        state.notifications = payload.notifications;
        state.notificationLoaded = payload.notificationLoaded;
        state.totalNotifiction = payload.totalNotifiction;

    }, 
       [GET_ALL_NOTIFICATION_MUTATION](state, payload) {
        state.AllNotifications = payload.notifications;
        state.AllNotificationLoaded = payload.loaded;

    }, 
    [DELETE_ALL_NOTIFICATION_MUTATION](state, payload) {
        state.deleting = payload;
    },


}