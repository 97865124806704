import { useLayout } from '@/layout/composables/layout';
const { changeThemeSettings, setScale, layoutConfig } = useLayout();
export function onChangeTheme (theme , mode ) {
    const elementId = 'theme-css';
    const linkElement = document.getElementById(elementId);
    const cloneLinkElement = linkElement.cloneNode(true);
    const newThemeUrl = linkElement.getAttribute('href').replace(layoutConfig.theme.value, theme);
    cloneLinkElement.setAttribute('id', elementId + '-clone');
    cloneLinkElement.setAttribute('href', newThemeUrl);
    cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', elementId);
        changeThemeSettings(theme, mode === 'dark');
    });
    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
};

export function  SetScaleNew (value) {
    setScale(value);
    document.documentElement.style.fontSize = layoutConfig.scale.value + 'px';
};
export function  changeMenu (value) {
    layoutConfig.menuMode.value = value
};