import axiosInstance from "@/service/AxiosInstance";

import {DELETE_RATIO_MUTATION,DELETE_RATIO_ACTION,SEARCH_RATIO_ACTION,SEARCH_RECEIPT_RATIO_ACTION,EDIT_RECEIPT_RATIO_MUTATION,EDIT_RECEIPT_RATIO_ACTION,ADD_RECEIPT_RATIO_MUTATION,ADD_RECEIPT_RATIO_ACTION,POST_RETURN_RATIO_MUTATION,POST_RETURN_RATIO_ACTION,GET_RATIO_CLIENT_DETAIL_MUTATION,GET_RATIO_CLIENT_DETAIL_ACTION,GET_RATIO_CLIENT_MUTATION,GET_RATIO_CLIENT_ACTION, POST_RATIO_ACTION,POST_RATIO_MUTATION,GET_RATIO_MUTATION, GET_RATIO_ACTION,RESET_RATIO_Action, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_RATIO_ACTION](context, payload) {

     
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/ratio/index?start_date=${payload.startDate}&end_date=${payload.endDate}&client_id=${payload.clientId}`, );
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let finalRatio= parseInt(response.data.totalRatio )+ parseInt(response.data.totalProtaction)  + parseInt(response.data.totalBonus) -  parseInt(response.data.totalDiscount) ;
            let data = {
                loaded: true,
                ratios: response.data.data,
                totalRatio:response.data.totalRatio,
                totalProtaction:response.data.totalProtaction,
                totalDiscount:response.data.totalDiscount,
                totalBonus:response.data.totalBonus,
                finalRatio:finalRatio,
                countOrderReturn:response.data.countOrderReturn,
                countOrderDone:response.data.countOrderDone
            }
           
            context.commit(GET_RATIO_MUTATION, data)
        }


    },  
    
    async [POST_RATIO_ACTION](context, payload) {
        context.commit(POST_RATIO_MUTATION, true)
     
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/ratio/create`,payload );
        } catch (error) {
            context.commit(POST_RATIO_MUTATION, false)
            if(error.response.status = 408)
            throw error.response.data.message;
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_RATIO_MUTATION, false)
        }

    },  
    
    async [POST_RETURN_RATIO_ACTION](context, payload) {
        context.commit(POST_RETURN_RATIO_MUTATION, true)
     
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/ratio/return`,payload );
        } catch (error) {
            context.commit(POST_RETURN_RATIO_MUTATION, false)
            if(error.response.status = 408)
            throw error.response.data.message;
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_RETURN_RATIO_MUTATION, false)
        }

    },  
    
    async [ADD_RECEIPT_RATIO_ACTION](context, payload) {
        context.commit(ADD_RECEIPT_RATIO_MUTATION, true)
    
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/ratio/receipt/add`,payload );
        } catch (error) {
            context.commit(ADD_RECEIPT_RATIO_MUTATION, false)
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(ADD_RECEIPT_RATIO_MUTATION, false)
        }

    },
    
    async [EDIT_RECEIPT_RATIO_ACTION](context, payload) {
        context.commit(EDIT_RECEIPT_RATIO_MUTATION, true)
    
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/ratio/receipt/edit`,payload );
        } catch (error) {
            context.commit(EDIT_RECEIPT_RATIO_MUTATION, false)
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(EDIT_RECEIPT_RATIO_MUTATION, false)
        }
    },  
    
    async [GET_RATIO_CLIENT_ACTION](context, payload) {
       
     
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/ratio/all?page=${payload.currentPage}` );
        } catch (error) {
      
            console.log(error);
            return error
        }

        if (response.status == 200) {
         
           const data={
             allRatios:response.data.data,
             allRatioLoaded:true,
           }
            context.commit(GET_RATIO_CLIENT_MUTATION, data)
        }

    },   
    
    async [SEARCH_RECEIPT_RATIO_ACTION](context, payload) {
       
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/ratio/all?receipt=${payload.text}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
           const data={
             allRatios:response.data.data,
             allRatioLoaded:true,
           }
            context.commit(GET_RATIO_CLIENT_MUTATION, data)
        }

    },   
    
    
    async [GET_RATIO_CLIENT_DETAIL_ACTION](context, payload) {
     

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/ratio/detail/${payload.id}?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
          
              context.commit(GET_RATIO_CLIENT_DETAIL_MUTATION)
            return error
        }

        if (response.status == 200) {

           const data={
             ratioDetails:response.data.data,
             ratioDetailsLoaded:true,
           }
            context.commit(GET_RATIO_CLIENT_DETAIL_MUTATION, data)
        }

    },  
      
    async [SEARCH_RATIO_ACTION](context, payload) {
    
       
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/ratio/detail/${payload.id}?ratio=${payload.text}`);
        } catch (error) {
            console.log(error);
          
      
            return error
        }

        if (response.status == 200) {
             console.log(response.data);
           const data={
             ratioDetails:response.data.data,
             ratioDetailsLoaded:true,
           }
            context.commit(GET_RATIO_CLIENT_DETAIL_MUTATION, data)
        }

    },  
    
    async [RESET_RATIO_Action](context, payload) {

     
       
            let data = {
                loaded: false,
                ratios: [],
                totalRatio:0,
                totalProtaction:0,
                totalDiscount:0,
                totalBonus:0,
                finalRatio:0,
                countOrderReturn:0,
                countOrderDone:0
            }
           
            context.commit(GET_RATIO_MUTATION, data)
    


    },

    async [DELETE_RATIO_ACTION](context, payload) {
        context.commit(DELETE_RATIO_MUTATION, true);
        const id = payload.ratioId;
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/ratio/delete/${id}`)
        } catch (e) {
            context.commit(DELETE_RATIO_MUTATION, false)
          
        }

        if (response.status = 200) {
            context.commit(DELETE_RATIO_MUTATION, false)
        }

    },


 
}