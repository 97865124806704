<script setup>
import { getLocalStorage } from "@/service/LocalStorageService";
import { ref } from "vue";

import AppMenuItem from "./AppMenuItem.vue";
const isAdmin = getLocalStorage("userData").isAdmin;

const model = ref([
  {
    label: "الرئيسية",
    items: [{ label: "الصفحة الرئيسية", icon: "pi pi-fw pi-home", to: "/" }],
  },
  isAdmin
    ? {
        label: "المستخدمين",
        items: [
          { label: "الادمن", icon: "pi pi-user", to: "/users/admin" },
          { label: "التجار", icon: "pi pi-user-plus", to: "/users/dealer" },
          { label: "المندوبين", icon: "pi  pi-users", to: "/users/client" },
        ],
      }
    : {
        label: "المستخدمين",
        items: [
          { label: "المندوبين", icon: "pi  pi-users", to: "/users/client" },
        ],
      },
  isAdmin
    ? {
        label: "الموديلات",
        items: [
          {
            label: "الموديلات",
            icon: "pi pi-check-circle",
            to: "/product/index",
          },
          {
            label: "رقم الموديل",
            icon: "pi  pi-paperclip",
            to: "/product/code",
          },
          { label: "الالوان", icon: "pi  pi-palette", to: "/product/color" },
          { label: "القياسات", icon: "pi  pi-tag", to: "/product/size" },
        ],
      }
    : "",

  isAdmin
    ? {
        label: "الحماية والمكافئة",
        items: [
          {
            label: "حماية المندوبين ",
            icon: "pi pi-shield",
            to: "/protaction/client",
          },
          {
            label: "حماية التجار",
            icon: "pi pi-shield",
            to: "/protaction/dealer",
          },
          {
            label: "مكافئة المندوبين",
            icon: "pi  pi-gift",
            to: "/bonus/index",
          },
        ],
      }
    : "",

  {
    label: "الطلبات",
    items: [
      {
        label: "انشاء طلب",
        icon: "pi pi-plus",
        to: "/order/select",
      },
       {
        label: "الطلبات الداخلية",
        icon: "pi pi-cart-plus",
        to: "/order/internal/index",
      },
      {
        label: "الطلبات الخارجية",
        icon: "pi pi-cart-plus",
        to: "/order/external/index",
      },
      {
        label: "طلبات الانتظار",
        icon: "pi pi-clock",
        to: "/order/waiting",
      },
      {
        label: " طلبات المرتجع الداخلية",
        icon: "pi pi-refresh",
        to: "/order/internal/return",
      },
      {
        label: " طلبات المرتجع الخارجية",
        icon: "pi pi-refresh",
        to: "/order/external/return",
      },
    ],
  },

  isAdmin
    ? {
        label: "طلبات الجملة",
        items: [
          {
            label: "موديلات جملة الجملة",
            icon: "pi pi-check-circle",
            to: "/wholesale/index",
          },
          {
            label: "طلبات جملة الجملة",
            icon: "pi pi-cart-plus",
            to: "/wholesale/order",
          },
        ],
      }
    : "",

  isAdmin
    ? {
        label: "الصندوق",
        items: [
          {
            label: "الحركات",
            icon: "pi pi-sort-alt",
            to: "/fundn/index",
          },
          {
            label: "رصيد افتتاحي",
            icon: "pi pi-money-bill",
            to: "/fundn_open/index",
          },
        ],
      }
    : "",
  isAdmin
    ? {
        label: "التجار",
        items: [
          {
            label: "فاتورة مبيع",
            icon: "pi pi-window-maximize",
            to: "/sale_invoice/index",
          },
          {
            label: "مرتجع فاتورة مبيع",
            icon: "pi pi-refresh",
            to: "/sale_invoice/return",
          },
          {
            label: "فاتورة شراء",
            icon: "pi pi-window-minimize",
            to: "/purchase_invoice/index",
          },
          {
            label: "مرتجع فاتورة شراء",
            icon: "pi pi-refresh",
            to: "/purchase_invoice/return",
          },
          {
            label: "سند قبض",
            icon: "pi pi-file-import",
            to: "/catch/index",
          },
          {
            label: "سند دفع",
            icon: "pi  pi-file-export",
            to: "/payment/index",
          },
          {
            label: "رصيد افتتاحي",
            icon: "pi pi-copy",
            to: "/dealer_open/index",
          },
          {
            label: "تقرير التجار",
            icon: "pi  pi-sync",
            to: "/dealer_report/index",
          },
        ],
      }
    : "",
  isAdmin
    ? {
        label: "الجرد والميزانية",
        items: [
          {
            label: "الميزانية",
            icon: "pi pi-sitemap",
            to: "/budget/index",
          },
          {
            label: "الجرد",
            icon: "pi pi-slack ",
            to: "/inventory/index",
          },
        ],
      }
    : "",

  isAdmin
    ? {
        label: "النسب",
        items: [
          {
            label: "نسب المندوبين",
            icon: "pi pi-chart-bar",
            to: "/ratio/index",
          },
          {
            label: "النسب المستلمة",
            icon: "pi pi-check-circle",
            to: "/ratio/ratio",
          },
        ],
      }
    : "",
  {
    label: "ديليفري ",
    items: [
      {
        label: "موظفين التوصيل",
        icon: "pi pi-users",
        to: "/delivery/index",
      },
      {
        label: "طلبات التوصيل",
        icon: "pi pi-truck",
        to: "/delivery/order",
      },
    ],
  },
  isAdmin
    ? {
        label: "القماش والتشغيل",
        items: [
          {
            label: "كود القماش",
            icon: "pi pi-check-circle",
            to: "/fabric/number",
          },
          {
            label: "القماش",
            icon: "pi  pi-paperclip",
            to: "/fabric/index",
          },
          {
            label: "سحوبات القماش",
            icon: "pi  pi-file-import",
            to: "/fabric/drawls",
          },
          {
            label: "تشغيل",
            icon: "pi  pi-cog",
            to: "/operating/index",
          },
          {
            label: "دفعات التشغيل",
            icon: "pi  pi-wallet",
            to: "/operating/payment",
          },
          {
            label: "اجمالي التشغيل",
            icon: "pi  pi-hashtag",
            to: "/operating/finance",
          },
        ],
      }
    : "",
  {
    label: "المناطق",
    items: [
      {
        label: "مناطق داخلية",
        icon: "pi pi-map-marker",
        to: "/region/internal",
      },
      {
        label: "مناطق خارجية",
        icon: "pi  pi-map-marker",
        to: "/region/external",
      },
      { label: " المحافظات", icon: "pi  pi-map", to: "/region/province" },
    ],
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
