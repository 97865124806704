import {DELETE_FABRIC_DRAWLS_MUTATION,GET_CODE_FABRIC_DRAWLS_BY_ID_MUTATION,POST_FABRIC_DRAWLS_MUTATION,GET_FABRIC_DRAWLS_MUTATION,DELETE_FABRIC_MUTATION,POST_FABRIC_MUTATION,GET_FABRIC_MUTATION,GET_CODE_FABRIC_BY_MUTATION,DELETE_FABRIC_NUMBER_MUTATION,POST_FABRIC_NUMBER_MUTATION,GET_FABRIC_NUMBER_MUTATION,GET_ALL_FABRIC_NUMBER_MUTATION} from "@/store/storeConstants";
export default {
  
     [GET_FABRIC_NUMBER_MUTATION](state, payload) {
        state.fabricCodeLoaded = payload.loaded;
        state.fabricCodes = payload.fabricCodes;

    },  

    [GET_ALL_FABRIC_NUMBER_MUTATION](state, payload) {
        state.AllfabricCodeLoaded = payload.loaded;
        state.allFabricCodes = payload.allFabricCodes;
    }, 

    [POST_FABRIC_NUMBER_MUTATION](state, payload) {
        state.postFabricNumber = payload;
    },   
    
    [DELETE_FABRIC_NUMBER_MUTATION](state, payload) {
        state.deleteing = payload;
    },  

    [GET_CODE_FABRIC_BY_MUTATION](state, payload) {
        state.singleCode = payload.singleCode;
    },  
    
    [GET_FABRIC_MUTATION](state, payload) {
        state.fabricLoaded = payload.loaded;
        state.fabrices = payload.fabrices;
    },  

    [POST_FABRIC_MUTATION](state, payload) {

        state.postFabric = payload;
    },  
    [DELETE_FABRIC_MUTATION](state, payload) {

        state.deleteing = payload;
    },  
    
    [POST_FABRIC_DRAWLS_MUTATION](state, payload) {

        state.postDrawls = payload;
    },  
    
    [GET_FABRIC_DRAWLS_MUTATION](state, payload) {

        state.fabricDrawlLoaded= payload.loaded;
        state.fabricDrawls = payload.fabricDrawls;
    },  

   [GET_CODE_FABRIC_DRAWLS_BY_ID_MUTATION](state, payload) {

        state.total= payload.total;
        state.type= payload.type;
    },  
    
    [DELETE_FABRIC_DRAWLS_MUTATION](state, payload) {

        state.deleteing= payload;
    },  





}