import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
           token:'',
           fullname:'',
           email:'',
           userId:'',
           Image:'',
           company_id:'',
           refreshToken:'',
           expiresIn:'',
           users:[],
           userLoaded:false,
           autoLogout:false,
           postUser:false,
           deleteing:false,
           logingOut:false,
           updateImage:false,
           isAdmin:false,
        }
    },
    mutations,
    getters,
    actions,
}
