import {GET_PROTACTION_DEALER_BY_ID_MUTATION,GET_PROTACTION_CLIENT_BY_ID_MUTATION,DELETEING_PROTACTION_DEALER_ONE_MUTATION,DELETEING_PROTACTION_DEALER_MUTATION,POST_PROTACTION_DEALER_MUTATION,GET_DEALERS_DETAIL_MUTATION,GET_PROTACTION_DEALER_DETAIL_MUTATION,PROTACTION_DEALER_LOADED_MUTATION,DELETEING_PROTACTION_CLIENT_ONE_MUTATION,GET_CLIENTS_DETAIL_MUTATION,DELETEING_PROTACTION_CLIENT_MUTATION,POST_PROTACTION_CLIENT_MUTATION, GET_PROTACTION_CLIENT_DETAIL_MUTATION,PROTACTION_CLIENT_LOADED_MUTATION} from "@/store/storeConstants";
export default {
    [PROTACTION_CLIENT_LOADED_MUTATION](state, payload) {
        state.protacionClients = payload.protacionClients;
        state.protacionClientLoaded = payload.protacionClientLoaded;
    }, 
    
     [GET_PROTACTION_CLIENT_DETAIL_MUTATION](state, payload) {
        state.protacionClientDetails = payload.protacionClientDetails;
        state.protacionClientDetailLoaded = payload.protacionClientDetailLoaded;
    },  

      [POST_PROTACTION_CLIENT_MUTATION](state, payload) {
        state.postProtaction = payload;
    },
      [DELETEING_PROTACTION_CLIENT_MUTATION](state, payload) {
        state.deleteing = payload;
      }, 
      [DELETEING_PROTACTION_CLIENT_ONE_MUTATION](state, payload) {
        state.deleteing = payload;
      },
      [DELETEING_PROTACTION_DEALER_MUTATION](state, payload) {
        state.deleteing = payload;
      },

     [GET_CLIENTS_DETAIL_MUTATION](state, payload) {
        state.protacionDetailCleints = payload.protacionClients;
        state.protacionDetailClientsLoaded = payload.protacionClientsLoaded;
      },

      [GET_PROTACTION_CLIENT_BY_ID_MUTATION](state, payload) {
        state.protacionClientId = payload.protacionClientId;
        state.protacionClientIdLoaded = payload.protacionClientIdLoaded;
      }, 
      
      [GET_PROTACTION_DEALER_BY_ID_MUTATION](state, payload) {
        state.protacionDealerId = payload.protacionDealerId;
        state.protacionDealerIdLoaded = payload.protacionDealerIdLoaded;
      },
 
 ///////////////////////dealers/////////

 [PROTACTION_DEALER_LOADED_MUTATION](state, payload) {
  state.protacionDealers = payload.protacionDealers;
  state.protacionDealerLoaded = payload.protacionDealerLoaded;
}, 
[GET_PROTACTION_DEALER_DETAIL_MUTATION](state, payload) {
  state.protacionDealerDetails = payload.protacionDealerDetails;
  state.protacionDealerDetailLoaded = payload.protacionDealerDetailLoaded;
},  

[POST_PROTACTION_DEALER_MUTATION](state, payload) {
  state.postProtaction = payload;
},

[DELETEING_PROTACTION_DEALER_ONE_MUTATION](state, payload) {
  state.deleteing = payload;
},
[GET_DEALERS_DETAIL_MUTATION](state, payload) {
  state.protacionDetailDealers = payload.protacionDealers;
  state.protacionDetailDealersLoaded = payload.protacionDealersLoaded;
},
}