<script setup>
import { onMounted } from "vue";
import External from "./service/External";
import store from "./store";
import { AUTO_LOGIN_USER } from "./store/storeConstants";
 onMounted:{
    store.dispatch(`auth/${AUTO_LOGIN_USER}`)
  
 }

</script>

<template>
    <router-view />
</template>

<style scoped></style>
