import axiosInstance from "@/service/AxiosInstance";

import { GET_OPERATING_FINANCE_MUTATION, GET_OPERATING_FINANCE_ACTION, FILTER_OPERATING_PAYMENT_ACTION, DELETE_OPERATING_PAYMENT_ACTION, DELETE_OPERATING_PAYMENT_MUTATION, UPDATE_OPERATING_PAYMENT_ACTION, POST_OPERATING_PAYMENT_MUTATION, POST_OPERATING_PAYMENT_ACTION, GET_OPERATING_PAYMENT_MUTATION, GET_OPERATING_PAYMENT_ACTION, FILTER_OPERATING_ACTION, DELETE_OPERATING_MUTATION, DELETE_OPERATING_ACTION, POST_OPERATING_MUTATION, POST_OPERATING_ACTION, GET_OPERATING_MUTATION, GET_OPERATING_ACTION, FILTER_FABRIC_NUMBER_ACTION, UPDATE_FABRIC_NUMBER_ACTION, DELETE_FABRIC_NUMBER_MUTATION, DELETE_FABRIC_NUMBER_ACTION, POST_FABRIC_NUMBER_MUTATION, POST_FABRIC_NUMBER_ACTION, GET_FABRIC_NUMBER_ACTION, GET_FABRIC_NUMBER_MUTATION, GET_ALL_FABRIC_NUMBER_ACTION, GET_ALL_FABRIC_NUMBER_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_OPERATING_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/operating/index?page=${payload.currentPage}`);

        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                operatings: response.data.data,

            }
            context.commit(GET_OPERATING_MUTATION, data)
        }
    },

    async [FILTER_OPERATING_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/operating/index?search=${payload.text}`);


        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                operatings: response.data.data,

            }
            context.commit(GET_OPERATING_MUTATION, data)
        }
    },

    async [POST_OPERATING_ACTION](context, payload) {
        context.commit(POST_OPERATING_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/operating/create`, payload);
        } catch (e) {
            console.log(e);
            context.commit(POST_OPERATING_MUTATION, false)
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
            return error
        }
        if (response.status == 200) {
            context.commit(POST_OPERATING_MUTATION, false)
        }
    },

    async [UPDATE_FABRIC_NUMBER_ACTION](context, payload) {
        context.commit(POST_FABRIC_NUMBER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/fabirc_number/update`, payload);
        } catch (e) {
            context.commit(POST_FABRIC_NUMBER_MUTATION, false)
            console.log(e);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_FABRIC_NUMBER_MUTATION, false)
        }
    },

    async [DELETE_OPERATING_ACTION](context, payload) {
        context.commit(DELETE_OPERATING_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/operating/delete/${payload.id}`);
        } catch (e) {
            console.log(e);
            context.commit(DELETE_OPERATING_MUTATION, false)

            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(DELETE_OPERATING_MUTATION, false)
        }
    },
    ////////////////////opreatingPayment//////////////
    async [GET_OPERATING_FINANCE_ACTION](context, payload) {
        let response = '';
        try {
             if(payload.startDate || payload.endDate)
             {
                response = await axiosInstance.get(`${BASE_URL}/app/operating_finance/index?start_date=${payload.startDate}&end_date=${payload.endDate}`);
             }else{
                response = await axiosInstance.get(`${BASE_URL}/app/operating_finance/index`);
             }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                finance: response.data,
            }
            context.commit(GET_OPERATING_FINANCE_MUTATION, data)
        }
    },
    async [GET_OPERATING_PAYMENT_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/operating_payment/index?page=${payload.currentPage}`);

        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {

            let data = {
                loaded: true,

                operatingPayments: response.data.data,
            }
            context.commit(GET_OPERATING_PAYMENT_MUTATION, data)
        }
    },
    async [FILTER_OPERATING_PAYMENT_ACTION](context, payload) {
        let response = '';
        try {

            response = await axiosInstance.get(`${BASE_URL}/app/operating_payment/index?search=${payload.text}`);

        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                operatingPayments: response.data.data,

            }
            context.commit(GET_OPERATING_PAYMENT_MUTATION, data)
        }
    },

    async [POST_OPERATING_PAYMENT_ACTION](context, payload) {
        context.commit(POST_OPERATING_PAYMENT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/operating_payment/create`, payload);
        } catch (error) {
            context.commit(POST_OPERATING_PAYMENT_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {

            context.commit(POST_OPERATING_PAYMENT_MUTATION, false)
        }
    },

    async [UPDATE_OPERATING_PAYMENT_ACTION](context, payload) {
        context.commit(POST_OPERATING_PAYMENT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/operating_payment/update`, payload);
        } catch (error) {
            context.commit(POST_OPERATING_PAYMENT_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {

            context.commit(POST_OPERATING_PAYMENT_MUTATION, false)
        }
    },

    async [DELETE_OPERATING_PAYMENT_ACTION](context, payload) {
        console.log(payload.id);
        context.commit(DELETE_OPERATING_PAYMENT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/operating_payment/delete/${payload.id}`);
        } catch (error) {
            context.commit(DELETE_OPERATING_PAYMENT_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETE_OPERATING_PAYMENT_MUTATION, false)
        }
    },
}