import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
           dealers : [],
           Alldealers : [],
           dealerLoaded:false,
           postDealer:false,
           deleteing:false,
        }
    },
    mutations,
    getters,
    actions,
}
