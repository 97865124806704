import axiosInstance from "@/service/AxiosInstance";

import {SEARCH_WHOLESALE_ORDER_MUTATION,DELETEING_WHOLESALE_ORDER_MUTATION, UPDATE_WHOLESALE_ORDER_ACTION, WHOLESALE_STATUS_ACTION, WHOLESALE_STATUS_MUTATION, POST_WHOLESALE_ORDER_MUTATION, GET_WHOLESALE_ORDER_ACTION, GET_WHOLESALE_ORDER_MUTATION, DELETE_WHOLESALE_ORDER_ACTION, UPDATE_WHOLESALE_ACTION, GET_PRODUCT_WHOLESALE_ORDER_MUTATION, GET_WHOLESALE_MUTATION, DELETE_DEALER_ACTION, DELETING_DEALER_mutations, DELETEING_WHOLESALE_MUTATION, DELETE_WHOLESALE_ACTION, SEARCH_WHOLESALE_ACTION, POST_DEALER_MUTATION, POST_WHOLESALE_ORDER_ACTION, GET_WHOLESALE_ACTION, FILTER_WHOLESALE_ORDER_INTERNAL_ACTION, POST_WHOLESALE_MUTATION, GET_PRODUCT_BY_ID_WHOLESALE_ORDER_ACTION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_WHOLESALE_ORDER_ACTION](context, payload) {
        let response = '';

        try {
            if(payload.sortDealer)
            {
            
                response = await axiosInstance.get(`${BASE_URL}/app/wholesale_order/index?page=${payload.currentPage}&sort_dealer=${payload.sortDealer}`);

            } else {
               
                response = await axiosInstance.get(`${BASE_URL}/app/wholesale_order/index?page=${payload.currentPage}`);
            }
       
        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                wholeSaleOrders: response.data.data
            }
            context.commit(GET_WHOLESALE_ORDER_MUTATION, data)
        }


    },

    async [WHOLESALE_STATUS_ACTION](context, payload) {
        context.commit(WHOLESALE_STATUS_MUTATION, true)

        let response = '';
        try {

            response = await axiosInstance.post(`${BASE_URL}/app/wholesale_order/received`, payload);
        } catch (e) {
            context.commit(WHOLESALE_STATUS_MUTATION, false)
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(WHOLESALE_STATUS_MUTATION, false)
            console.log(response);

            return true;
        }


    },
    async [GET_PRODUCT_BY_ID_WHOLESALE_ORDER_ACTION](context, payload) {


        let response = '';

        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale/show/${payload}`);

        } catch (error) {
            console.log(error);
            return error
        }


        if (response.status == 200) {


            let data = {
                loaded: true,
                wholeSaleDetail: response.data.data
            }
            context.commit(GET_PRODUCT_WHOLESALE_ORDER_MUTATION, data)


        }


    },

    async [POST_WHOLESALE_ORDER_ACTION](context, payload) {
        context.commit(POST_WHOLESALE_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/wholesale_order/create`, payload);
        } catch (e) {
            console.log(e);
            context.commit(POST_WHOLESALE_ORDER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        console.log(response.data);
        if (response.status == 200) {

            context.commit(POST_WHOLESALE_ORDER_MUTATION, false)
        }


    },

    async [UPDATE_WHOLESALE_ORDER_ACTION](context, payload) {

        context.commit(POST_WHOLESALE_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/wholesale_order/update`, payload);
        } catch (e) {
            console.log(e);
            context.commit(POST_WHOLESALE_ORDER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        console.log(response.data);
        if (response.status == 200) {

            context.commit(POST_WHOLESALE_ORDER_MUTATION, false)
        }


    },

    async [FILTER_WHOLESALE_ORDER_INTERNAL_ACTION](context, payload) {


        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale_order/index/?sort_dealer=${payload.sortDealer}`);
        } catch (e) {
            return e;
        }

        if (response.status == 200) {
            let data = {
                loaded: true,
                wholeSaleOrders: response.data.data
            }
            context.commit(GET_WHOLESALE_ORDER_MUTATION, data)
        }


    },

    async [SEARCH_WHOLESALE_ORDER_MUTATION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale_order/index/?search=${payload.text}`);
        } catch (e) {
            return e;
        }

        if (response.status == 200) {
            let data = {
                loaded: true,
                wholeSaleOrders: response.data.data
            }
            context.commit(GET_WHOLESALE_ORDER_MUTATION, data)
        }


    },

    async [UPDATE_WHOLESALE_ACTION](context, payload) {

        context.commit(POST_WHOLESALE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/wholesale/update`, payload);
        } catch (e) {

            context.commit(POST_WHOLESALE_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }
        console.log(response.data);
        if (response.status == 200) {

            context.commit(POST_WHOLESALE_MUTATION, false)
        }


    },

    async [SEARCH_WHOLESALE_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/wholesale/index?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                wholeSales: response.data.data
            }
            context.commit(GET_WHOLESALE_MUTATION, data)
        }


    },

    async [DELETE_WHOLESALE_ORDER_ACTION](context, payload) { 

        context.commit(DELETEING_WHOLESALE_ORDER_MUTATION, true)
     
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/wholesale_order/delete/${payload}`);
        } catch (e) {
            context.commit(DELETEING_WHOLESALE_ORDER_MUTATION, false)
            return e
        }

        if (response.status == 200) {
          
            context.commit(DELETEING_WHOLESALE_ORDER_MUTATION, false);
        }


    },

    async [DELETE_DEALER_ACTION](context, payload) {

        context.commit(DELETING_DEALER_mutations, true)
        const id = payload.dealerId;
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/dealer/delete/${id}`);

        } catch (e) {
            context.commit(DELETING_DEALER_mutations, false)
            console.log(e);
            return e;
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETING_DEALER_mutations, false)


        }


    },
}