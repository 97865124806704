import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            purchaseInvoiceDetails : [],
            purchaseInvoices : [],
            purchaseInvoicesReturn : [],
            purchaseInvoices: false,
            purchaseInvoicesLoaded: false,
            purchaseInvoiceDetailLoaded: false,
            purchaseInvoicesReturnLoaded: false,
            deleting: false,
            postReturn: false,
            postInvoce: false,

        }
    },
    mutations,
    getters,
    actions,
}
