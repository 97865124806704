import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            ratios : [],
            allRatios : [],
            ratioDetails : [],
            totalRatio:0,
            totalBonus:0,
            totalProtaction:0,
            finalRatio:0,
            totalDiscount:0,
            countOrderReturn:0,
            countOrderDone:0,
            ratioLoaded:false,
            postReceipt:false,
            deleteing:false,
            PostreturnRatio:false,
            ratioDetailsLoaded:false,
            postRtio:false,

        }
    },
    mutations,
    getters,
    actions,
}
