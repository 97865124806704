import {GET_PRODUCT_MOVMENT_MUTATION,GET_PRODUCTS_DEALER_MUTATION,GET_PRODUCTS_BY_ID_INVOICE_MUTATION,GET_COUNT_PRODUCT_MUTATION,GET_ALL_CODE_PRODUCT_MUTATION,GET_CODE_BY_ID_MUTATION, ALL_PRODUCTS_MUTATION,GET_SIZE_BY_ID_MUTATION,GET_PRODUCTS_BY_ID_MUTATION, GET_ALL_PRODUCTS_MUTATION, POST_USER_MUTATION, POST_SIZE_MUTATION, POST_COLOR_ACTION, POST_COLOR_MUTATION, UPDATING_PORDUCT_MUTATION, PODUCT_DETAIL_MUTATION, GET_PRODUCTS_MUTATION, POSTING_PORDUCT_MUTATION, CATEGORY_LOADED_MUTATION, DELETING_PRODUCT_CODE_MUTATION, SIZE_LOADED_MUTATION, COLOR_LOADED_MUTATION, CODE_LOADED_MUTATION, POST_PRODUCT_CODE_MUTATION } from "@/store/storeConstants";

export default {
    [DELETING_PRODUCT_CODE_MUTATION](state, payload) {
        state.deleteing = payload;
    },
    [GET_PRODUCTS_MUTATION](state, payload) {
        state.products = payload.products;
        state.productLoaded = payload.productLoaded;

    },
    [GET_ALL_PRODUCTS_MUTATION](state, payload) {
        state.AllProducts = payload.AllProducts;
        state.AllProductLoaded = payload.AllProductLoaded;
    },
    [SIZE_LOADED_MUTATION](state, payload) {
        state.sizes = payload.sizes;
        state.sizeLoaded = payload.sizeLoaded;
    },
    [COLOR_LOADED_MUTATION](state, payload) {
        state.colors = payload.colors;
        state.colorLoaded = payload.colorLoaded;

    },
    [POST_PRODUCT_CODE_MUTATION](state, payload) {
        state.postCode = payload;
    },
    [POST_COLOR_ACTION](state, payload) {
        state.postColor = payload;
    },
    [POST_SIZE_MUTATION](state, payload) {
        state.postSize = payload;
    },
    [POST_COLOR_MUTATION](state, payload) {
        state.postColor = payload;
    },
    [CODE_LOADED_MUTATION](state, payload) {
        state.codes = payload.codes;
        state.codeLoaded = payload.codeLoaded;

    },
    [CATEGORY_LOADED_MUTATION](state, payload) {
        state.categoryLoaded = payload.categoryLoaded;
        state.categories = payload.categories;
    },
    [POSTING_PORDUCT_MUTATION](state, payload) {
        state.postingProduct = payload;

    },
    [UPDATING_PORDUCT_MUTATION](state, payload) {
        state.updatingProduct = updatingProduct;

    },
    [PODUCT_DETAIL_MUTATION](state, payload) {
        state.productDetail = payload.productDetail;
        state.productDetailLoading = payload.loaded;
    },
    [GET_PRODUCTS_BY_ID_MUTATION](state, payload) {
        state.productById = payload.productById;
        state.loadProductId = payload.loadProductId;
    }, 
    [GET_PRODUCTS_DEALER_MUTATION](state, payload) {
        state.AllProductsInvoice = payload.AllProducts;
        state.AllProductInvoceLoaded = payload.AllProductLoaded;
    },  
    [GET_PRODUCTS_BY_ID_INVOICE_MUTATION](state, payload) {
        state.productByIdInvoice = payload.productById;
        state.loadProductIdInvoce = payload.loadProductId;
    }, 
    [ALL_PRODUCTS_MUTATION](state, payload) {
        state.ProductsPure = payload.ProductsPure;
        state.loadProducts = payload.loadProducts;
    },
    [GET_SIZE_BY_ID_MUTATION](state, payload) {
        state.sizesById = payload.sizesById;
    },
     [GET_CODE_BY_ID_MUTATION](state, payload) {
        state.singleCode = payload.singleCode;
    }, 
     [GET_ALL_CODE_PRODUCT_MUTATION](state, payload) {
        state.ALLcodes = payload.ALLcodes;
    },
    [GET_COUNT_PRODUCT_MUTATION](state, payload) {
        state.productCount = payload.productCount;
   
    },  
    
    [GET_PRODUCT_MOVMENT_MUTATION](state, payload) {
        state.movmentLoaded = payload.loaded;
        state.movments = payload.movments;
        state.movmentSum = payload.movmentSum;
   
    },

}