import axiosInstance from "@/service/AxiosInstance";
import external from "@/service/External";
import { PRINT_ORDER_MUTATION, PRINT_ORDER_ACTION, DELETE_IMAGE_RECEIPT_ACTION, DELETE_IMAGE_RECEIPT_MUTATION, UPLOADE_RECEIPT_MUTATION, UPLOADE_RECEIPT_ACTION, SEARCH_ORDER_WAITING_ACTION, RETURN_ONE_ORDER_WAITING_ACTION, UPDATE_ORDER_WAITING_ACTION, GET_ORDER_INERNAL_MUTATION, RETURN_ONE_ORDER_ACTION, GET_ORDER_WAITING_ACTION, GET_ORDER_WAITING_MUTATION, ORDER_MOVED_STATUS_MUTATION, ORDER_MOVED_STATUS_ACTION, ORDER_CHARGED_STATUS_ACTION, ORDER_CHARGED_STATUS_MUTATION, EDIT_RECEIPT_ORDER_EXTERNAL_ACTION, ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, ADD_RECEIPT_ORDER_EXTERNAL_ACTION, DELETEING_RETURN_ORDER_MUTATION, DELETE_RETURN_ORDER_ACTION, SEARCH_RETURN_ORDER_EXTERNAL_ACTION, GET_RETURN_ORDER_EXTERNAL_MUTATION, GET_RETURN_ORDER_EXTERNAL_ACTION, FILTE_RETURN_ORDER_EXTERNAL_ACTION, SEARCH_RETURN_ORDER_INTERNAL_ACTION, FILTE_RETURN_ORDER_INTERNAL_ACTION, FILTER_ORDER_EXTERNAL_ACTION, FILTER_ORDER_INTERNAL_ACTION, GET_RETURN_ORDER_INERNAL_MUTATION, GET_RETURN_ORDER_INERNAL_ACTION, RETURN_ORDER_MUTATION, RETURN_ORDER_ACTION, DELETE_ORDER_ACTION, SEARCH_ORDER_EXTERNAL_ACTION, UPDATE_ORDER_ACTION, GET_EDIT_ORDER_ACTION, GET_ORDER_EXTERNAL_MUTATION, GET_ORDER_EXTERNAL_ACTION, ORDER_STATUS_MUTATION, ORDER_STATUS_ACTION, DELETEING_ORDER_MUTATION, POST_ORDER_MUTATION, GET_ORDER_INERNAL_ACTION, GET_ORDER_INERTNAL_MUTATION, POST_ORDER_ACTION, DELETING_DEALER_mutations, UPDATE_DEALER_ACTION, SEARCH_ORDER_INTERNAL_ACTION, POST_DEALER_MUTATION, GET_EDIT_ORDER_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {

    async [GET_ORDER_INERNAL_ACTION](context, payload) {

        let response = '';
        try {
            if (payload.sortProduct && payload.sortClient) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);

            } else if (payload.sortProduct) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&sort_product=${payload.sortProduct}`);

            } else if (payload.sortClient) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&sort_client=${payload.sortClient}`);

            } else if (payload.text) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&search=${payload.text}`);
            } else if (payload.sortDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&sort_date=${payload.sortDate}`);
            }
            else if (payload.sortWaite) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&sort_waite=${payload.sortWaite}`);
            }
            else if (payload.sortReceived) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}&sort_received=${payload.sortReceived}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?page=${payload.currentPage}`);
            }

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                orders: response.data.data
            }
            context.commit(GET_ORDER_INERTNAL_MUTATION, data)
        }

    },

    async [GET_ORDER_WAITING_ACTION](context, payload) {

        let response = '';
        try {
            if (payload.sortProduct && payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/wait?page=${payload.currentPage}&sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);

            } else if (payload.sortProduct) {


                response = await axiosInstance.get(`${BASE_URL}/app/order/wait?page=${payload.currentPage}&sort_product=${payload.sortProduct}`);

            } else if (payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/wait?page=${payload.currentPage}&sort_client=${payload.sortClient}`);

            } else if (payload.text) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/wait?page=${payload.currentPage}&search=${payload.text}`);
            } else if (payload.sortDate) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/wait?page=${payload.currentPage}&sort_date=${payload.sortDate}`);
            }

            else {

                response = await axiosInstance.get(`${BASE_URL}/app/order/wait?page=${payload.currentPage}`);
            }

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                ordersWaiting: response.data.data
            }
            context.commit(GET_ORDER_WAITING_MUTATION, data)
        }

    },

    async [GET_RETURN_ORDER_INERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/return-order/internal/index?page=${payload.currentPage}`);
        } catch (error) {

            return error
        }

        if (response.status == 200) {

            let data = {
                returnOrderInternalLoaded: true,
                returnOrdersInternal: response.data.data
            }
            context.commit(GET_RETURN_ORDER_INERNAL_MUTATION, data)
        }


    },

    async [UPLOADE_RECEIPT_ACTION](context, payload) {
        context.commit(UPLOADE_RECEIPT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/uploade_receipt`, payload);
        } catch (error) {
            console.log(error);
            context.commit(UPLOADE_RECEIPT_MUTATION, false)
            return error
        }

        if (response.status == 200) {

            console.log(response.data);
            context.commit(UPLOADE_RECEIPT_MUTATION, false)
        }
    },

    async [DELETE_IMAGE_RECEIPT_ACTION](context, payload) {

        context.commit(DELETE_IMAGE_RECEIPT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/order/receipt/image/${payload.imageId}`);
        } catch (error) {
            console.log(error);
            context.commit(DELETE_IMAGE_RECEIPT_MUTATION, false)
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETE_IMAGE_RECEIPT_MUTATION, false)
        }


    },

    async [GET_RETURN_ORDER_EXTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/return-order/external/index?page=${payload.currentPage}`);
        } catch (error) {

            return error

        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                returnOrderExternalLoaded: true,
                returnOrdersExternal: response.data.data
            }
            context.commit(GET_RETURN_ORDER_EXTERNAL_MUTATION, data)
        }


    },

    async [GET_EDIT_ORDER_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/order/edit/${payload}`);
        } catch (error) {

            return error
        }

        if (response.status == 200) {

            let data = {
                loaded: true,
                orders: response.data.data
            }
            context.commit(GET_EDIT_ORDER_MUTATION, data)
            external.emit('orderDetail')
        }


    },

    async [RETURN_ONE_ORDER_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/return_one`, payload);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(GET_ORDER_INERNAL_MUTATION, true)

        }


    },

    async [RETURN_ONE_ORDER_WAITING_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/wait/return_one`, payload);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(GET_ORDER_INERNAL_MUTATION, true)

        }


    },

    async [FILTER_ORDER_INTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_date=${payload.sortDate}`)


            if (payload.sortProduct && payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);

            } else if (payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_client=${payload.sortClient}`);

            } else if (payload.sortDate) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_date=${payload.sortDate}`);
            } else if (payload.sortWaite) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_waite=${payload.sortWaite}`);
            }
            else if (payload.sortReceived) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_received=${payload.sortReceived}`);
            }
            else {
                console.log(3);
                response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?sort_product=${payload.sortProduct}`);
            }

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                orders: response.data.data,
                count: response.data.count
            }
            context.commit(GET_ORDER_INERTNAL_MUTATION, data)
        }

    },

    async [FILTER_ORDER_EXTERNAL_ACTION](context, payload) {

        let response = '';
        try {

            if (payload.sortProduct && payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);

            } else if (payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?sort_client=${payload.sortClient}`);

            } else if (payload.sortDate) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?sort_date=${payload.sortDate}`);
            } else if (payload.sortCharge) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?sort_charge=${payload.sortCharge}`);
            }
            else if (payload.sortReceived) {

                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?sort_received=${payload.sortReceived}`);
            } else {
                console.log(3);
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?sort_product=${payload.sortProduct}`);
            }

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                orders: response.data.data,
                count: response.data.count
            }
            context.commit(GET_ORDER_EXTERNAL_MUTATION, data)
        }


    },

    async [FILTE_RETURN_ORDER_INTERNAL_ACTION](context, payload) {

        let response = '';
        try {

            if (payload.sortProduct && payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/return-order/internal/index?sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);

            } else if (payload.sortClient) {

                response = await axiosInstance.get(`${BASE_URL}/app/return-order/internal/index?sort_client=${payload.sortClient}`);

            } else {
                console.log(3);
                response = await axiosInstance.get(`${BASE_URL}/app/return-order/internal/index?sort_product=${payload.sortProduct}`);
            }

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                returnOrderInternalLoaded: true,
                returnOrdersInternal: response.data.data
            }
            context.commit(GET_RETURN_ORDER_INERNAL_MUTATION, data)
        }


    },

    async [FILTE_RETURN_ORDER_EXTERNAL_ACTION](context, payload) {

        let response = '';
        try {

            if (payload.sortProduct && payload.sortClient) {
                console.log(1);
                response = await axiosInstance.get(`${BASE_URL}/app/return-order/external/index?sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);

            } else if (payload.sortClient) {
                console.log(2);
                response = await axiosInstance.get(`${BASE_URL}/app/return-order/external/index?sort_client=${payload.sortClient}`);

            } else {
                console.log(3);
                response = await axiosInstance.get(`${BASE_URL}/app/return-order/external/index?sort_product=${payload.sortProduct}`);
            }

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            let data = {
                returnOrderExternalLoaded: true,
                returnOrdersExternal: response.data.data
            }
            context.commit(GET_RETURN_ORDER_EXTERNAL_MUTATION, data)
        }


    },

    async [UPDATE_ORDER_ACTION](context, payload) {
        context.commit(POST_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/update`, payload);
        } catch (e) {
            context.commit(POST_ORDER_MUTATION, false)
            console.log(e);
            if (e.response.status = 408)
                throw e.response.data.message;
            context.commit(POST_ORDER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }

        if (response.status == 200) {
            console.log(response);
            context.commit(POST_ORDER_MUTATION, false)
        }


    },

    async [UPDATE_ORDER_WAITING_ACTION](context, payload) {
        context.commit(POST_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/wait/update`, payload);
        } catch (e) {
            context.commit(POST_ORDER_MUTATION, false)
            console.log(e);
            if (e.response.status = 408)
                throw e.response.data.message;
            context.commit(POST_ORDER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }

        if (response.status == 200) {
            console.log(response);
            context.commit(POST_ORDER_MUTATION, false)
        }


    },

    async [GET_ORDER_EXTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            if (payload.sortProduct && payload.sortClient) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&sort_product=${payload.sortProduct}&sort_client=${payload.sortClient}&double=${true}`);
            } else if (payload.sortProduct) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&sort_product=${payload.sortProduct}`);
            } else if (payload.sortClient) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&sort_client=${payload.sortClient}`);

            } else if (payload.text) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&search=${payload.text}`);
            } else if (payload.sortDate) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&sort_date=${payload.sortDate}`);
            }
            else if (payload.sortCharge) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&sort_charge=${payload.sortCharge}`);
            }
            else if (payload.sortReceived) {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}&sort_received=${payload.sortReceived}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?page=${payload.currentPage}`);
            }
        } catch (error) {

            return error
        }

        if (response.status == 200) {

            let data = {
                loaded: true,
                orders: response.data.data
            }
            context.commit(GET_ORDER_EXTERNAL_MUTATION, data)
        }


    },

    async [ADD_RECEIPT_ORDER_EXTERNAL_ACTION](context, payload) {
        context.commit(ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/external/receipt`, { receipt: payload.recept, receipt_date: payload.date, order_id: payload.orderId });
        } catch (e) {
            context.commit(ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
            return e
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, false)
        }
    },

    async [EDIT_RECEIPT_ORDER_EXTERNAL_ACTION](context, payload) {
        context.commit(ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/external/receipt/update`, { order_id: payload.orderId, receipt: payload.recept, receipt_date: payload.date, id: payload.receiptId });
        } catch (e) {
            console.log(e);
            context.commit(ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
            return e
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(ADD_RECEIPT_ORDER_EXTERNAL_MUTATION, false)
        }

    },

    async [RETURN_ORDER_ACTION](context, payload) {
        context.commit(RETURN_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/return`, payload);
        } catch (error) {
            return console.log(error);
            context.commit(RETURN_ORDER_MUTATION, false)
            return error
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(RETURN_ORDER_MUTATION, false)
            return response.data;
        }


    },

    async [POST_ORDER_ACTION](context, payload) {
        context.commit(POST_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/create`, payload);
        } catch (e) {
            context.commit(POST_ORDER_MUTATION, false)
            if (e.response.status = 408)
            throw e.response.data.message;

          
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }

        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_ORDER_MUTATION, false)
        }


    },

    async [SEARCH_ORDER_INTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/order/internal/index?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                orders: response.data.data
            }
            context.commit(GET_ORDER_INERTNAL_MUTATION, data)
        }


    },

    async [SEARCH_ORDER_WAITING_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/order/wait?search=${payload.text}`);
        } catch (e) {
            return e;
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                ordersWaiting: response.data.data
            }
            context.commit(GET_ORDER_WAITING_MUTATION, data)
        }


    },

    async [SEARCH_RETURN_ORDER_INTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/return-order/internal/index?search=${payload.text}`);
        } catch (e) {

            return e;
        }
        if (response.status == 200) {

            let data = {
                returnOrderInternalLoaded: true,
                returnOrdersInternal: response.data.data
            }
            context.commit(GET_RETURN_ORDER_INERNAL_MUTATION, data)
        }


    },

    async [SEARCH_RETURN_ORDER_EXTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/return-order/external/index?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                returnOrderExternalLoaded: true,
                returnOrdersExternal: response.data.data
            }
            context.commit(GET_RETURN_ORDER_EXTERNAL_MUTATION, data)
        }


    },

    async [SEARCH_ORDER_EXTERNAL_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/order/external/index?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                orders: response.data.data
            }
            context.commit(GET_ORDER_EXTERNAL_MUTATION, data)
        }


    },

    async [ORDER_STATUS_ACTION](context, payload) {
        context.commit(ORDER_STATUS_MUTATION, true)

        let response = '';
        try {

            response = await axiosInstance.post(`${BASE_URL}/app/order/received`, payload);
        } catch (e) {
            console.log(e);
            context.commit(ORDER_STATUS_MUTATION, false)
            return e;
        }
        if (response.status == 200) {
            console.log(response);
            context.commit(ORDER_STATUS_MUTATION, false)
            return true;
        }


    },

    async [ORDER_CHARGED_STATUS_ACTION](context, payload) {
        context.commit(ORDER_CHARGED_STATUS_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/charged`, payload);

        } catch (e) {
            console.log(e);
            context.commit(ORDER_CHARGED_STATUS_MUTATION, false)
            return e;
        }
        if (response.status == 200) {
            console.log(response);
            context.commit(ORDER_CHARGED_STATUS_MUTATION, false)
            return true;
        }


    },

    async [ORDER_MOVED_STATUS_ACTION](context, payload) {
        context.commit(ORDER_MOVED_STATUS_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/order/moved`, payload);

        } catch (e) {
            console.log(e);
            context.commit(ORDER_MOVED_STATUS_MUTATION, false)
            return e;
        }
        if (response.status == 200) {
            console.log(response);
            context.commit(ORDER_MOVED_STATUS_MUTATION, false)
            return true;
        }


    },

    async [PRINT_ORDER_ACTION](context, payload) {
        context.commit(PRINT_ORDER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/order/print/${payload}`);
        } catch (e) {
            console.log(e);
            context.commit(PRINT_ORDER_MUTATION, false)
            return e;
        }
        if (response.status == 200) {
            console.log(response);
            context.commit(PRINT_ORDER_MUTATION, false)
            return true;
        }


    },

    async [DELETE_ORDER_ACTION](context, payload) {

        context.commit(DELETEING_ORDER_MUTATION, true)
        let response = '';
        try {

            response = await axiosInstance.delete(`${BASE_URL}/app/order/delete/${payload.id}/${payload.status}`);

        } catch (e) {

            context.commit(DELETEING_ORDER_MUTATION, false)
            if (e.response.status == 500)
                throw 'لايمكن حذف طلب مربوط مع الديليفري'
            return e;
        }
        if (response.status == 200) {
            console.log(response);
            context.commit(DELETEING_ORDER_MUTATION, false)
            return true;
        }

    },

    async [DELETE_RETURN_ORDER_ACTION](context, payload) {

        context.commit(DELETEING_RETURN_ORDER_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/return-order/delete/${payload}`);
        } catch (e) {
            console.log(e);
            context.commit(DELETEING_RETURN_ORDER_MUTATION, false)
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETEING_RETURN_ORDER_MUTATION, false)
            return true;
        }

    },

    async [UPDATE_DEALER_ACTION](context, payload) {

        context.commit(POST_DEALER_MUTATION, true)

        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/dealer/update`, payload);
        } catch (e) {

            context.commit(POST_DEALER_MUTATION, false)
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
        }

        if (response.status == 200) {
            context.commit(POST_DEALER_MUTATION, false);

        }


    },

}