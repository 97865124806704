import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            paymentReceipts : [],
            paymentLoaded:false,
            deleting:false,
            postPayment:false
 
        }
    },
    mutations,
    getters,
    actions,
}
