import axiosInstance from "@/service/AxiosInstance";
import External from "@/service/External";
import {GET_PROTACTION_DEALER_BY_ID_MUTATION,GET_PROTACTION_DEALER_BY_ID_ACTION, GET_PROTACTION_CLIENT_BY_ID_MUTATION, GET_PROTACTION_CLIENT_BY_ID_ACTION, SEARCH_PROTACTION_DEALER_ACTION, DELETEING_PROTACTION_DEALER_ONE_MUTATION, DELETE_PROTACTION_DEALER_ONE_ACTION, DELETE_PROTACTION_DEALER_ACTION, DELETEING_PROTACTION_DEALER_MUTATION, UPDATE_PROTACTION_DEALER_ACTION, POST_PROTACTION_DEALER_MUTATION, POST_PROTACTION_DEALER_ACTION, GET_PROTACTION_DEALER_DETAIL_MUTATION, SEARCH_PROTACTION_DEALER_DETAIL_ACTION, GET_DEALERS_DETAIL_MUTATION, GET_DEALERS_DETAIL_ACTION, GET_PROTACTION_DEALER_DETAIL_ACTION, PROTACTION_DEALER_LOADED_MUTATION, GET_PROTACTION_DEALER_ACTION, UPDATE_PROTACTION_CLIENT_ACTION, DELETEING_PROTACTION_CLIENT_ONE_MUTATION, DELETE_PROTACTION_CLIENT_ONE_ACTION, GET_CLIENTS_DETAIL_MUTATION, GET_CLIENTS_DETAIL_ACTION, SEARCH_PROTACTION_CLIENT_DETAIL_ACTION, SEARCH_PROTACTION_CLIENT_ACTION, DELETEING_PROTACTION_CLIENT_MUTATION, DELETE_PROTACTION_CLIENT_ACTION, POST_PROTACTION_CLIENT_MUTATION, GET_PROTACTION_CLIENT_DETAIL_MUTATION, GET_PROTACTION_CLIENT_DETAIL_ACTION, PROTACTION_CLIENT_LOADED_MUTATION, GET_PROTACTION_CLIENT_ACTION, POST_PROTACTION_CLIENT_ACTION, BASE_URL } from "@/store/storeConstants";
export default {

    async [GET_PROTACTION_CLIENT_ACTION](context, payload) {
      
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_client/index?page=${payload.currentPage}`);
        } catch (e) {
           
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(PROTACTION_CLIENT_LOADED_MUTATION, {
                protacionClients: response.data.data,
                protacionClientLoaded: true
            });
        }

    },

    async [GET_PROTACTION_CLIENT_BY_ID_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_client/get_protaction/${payload.productId}/${payload.clientId}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(GET_PROTACTION_CLIENT_BY_ID_MUTATION, {
                protacionClientId: response.data.data[0] ? response.data.data[0] : 0,
                protacionClientIdLoaded: true
            });
            External.emit('protactionLoaded')
        }

    }, 
    
    async [GET_PROTACTION_DEALER_BY_ID_ACTION](context, payload) {
     
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_dealer/get_protaction/${payload.productId}/${payload.clientId}/${payload.type}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(GET_PROTACTION_DEALER_BY_ID_MUTATION, {
                protacionDealerId: response.data.data[0] ? response.data.data[0] : 0,
                protacionDealerIdLoaded: true
            });
            External.emit('protactionInvoiceLoaded')
        }

    },

    async [GET_PROTACTION_CLIENT_DETAIL_ACTION](context, payload) {
        context.commit(GET_PROTACTION_CLIENT_DETAIL_MUTATION, {
            protacionClientDetailLoaded: false
        });
        const id = payload.id;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_client/show/${id}?page=${payload.currentPage}`);
        } catch (e) {
            context.commit(GET_PROTACTION_CLIENT_DETAIL_MUTATION, {
                protacionClientDetailLoaded: false
            });
            console.log(e);
            return e;
        }
        if (response.status == 200) {
             console.log(response.data);
            context.commit(GET_PROTACTION_CLIENT_DETAIL_MUTATION, {
                protacionClientDetails: response.data.data,
                protacionClientDetailLoaded: true
            });


        }

    },

    async [GET_CLIENTS_DETAIL_ACTION](context, payload) {

        const id = payload.productId;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_client/get_client/${id}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(GET_CLIENTS_DETAIL_MUTATION, {
                protacionClients: response.data.data,
                protacionClientsLoaded: true
            });
            console.log(response.data);
        }
    },

    async [SEARCH_PROTACTION_CLIENT_DETAIL_ACTION](context, payload) {
        const id = payload.id;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_client/show/${id}?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(GET_PROTACTION_CLIENT_DETAIL_MUTATION, {
                protacionClientDetails: response.data.data,
                protacionClientDetailLoaded: true
            });
        }

    },

    async [POST_PROTACTION_CLIENT_ACTION](context, payload) {
        context.commit(POST_PROTACTION_CLIENT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/protaction_client/create`, payload);
        } catch (e) {
            context.commit(POST_PROTACTION_CLIENT_MUTATION, false)
            console.log(e);
            if (e.response.status == 500) {
                throw 'اسم المندوب مضاف مسبقا للموديل';
            }
            return
        }
        if (response.status == 200) {
            context.commit(POST_PROTACTION_CLIENT_MUTATION, false)
            console.log(response.data);

        }

    },

    async [UPDATE_PROTACTION_CLIENT_ACTION](context, payload) {
        context.commit(POST_PROTACTION_CLIENT_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/protaction_client/update`, payload);
        } catch (e) {
            context.commit(POST_PROTACTION_CLIENT_MUTATION, false)
            console.log(e);
            if (e.response.status == 500) {
                throw 'اسم المندوب مضاف مسبقا للموديل';
            }
            return
        }
        if (response.status == 200) {
            context.commit(POST_PROTACTION_CLIENT_MUTATION, false)
            console.log(response.data);

        }

    },

    async [DELETE_PROTACTION_CLIENT_ACTION](context, payload) {
        context.commit(DELETEING_PROTACTION_CLIENT_MUTATION, true);
        const id = payload.protactionId;

        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/protaction_client/delete/${id}`)
        } catch (e) {
            context.commit(DELETEING_PROTACTION_CLIENT_MUTATION, false)
            if (e.response.status == 500)
                throw ' هناك خطا ما'
        }

        if (response.status = 200) {

            context.commit(DELETEING_PROTACTION_CLIENT_MUTATION, false)
        }

    },

    async [DELETE_PROTACTION_CLIENT_ONE_ACTION](context, payload) {

        context.commit(DELETEING_PROTACTION_CLIENT_ONE_MUTATION, true);

        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/protaction_client/delete_one/${payload.clientId}`)
        } catch (e) {

            context.commit(DELETEING_PROTACTION_CLIENT_ONE_MUTATION, false)
            if (e.response.status == 500)
                throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            context.commit(DELETEING_PROTACTION_CLIENT_ONE_MUTATION, false)
        }

    },

    async [SEARCH_PROTACTION_CLIENT_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_client/index?search=${payload.text}`)
        } catch (e) {
            return e;
            if (e.response.status == 500)
                throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(PROTACTION_CLIENT_LOADED_MUTATION, {
                protacionClients: response.data.data,
                protacionClientLoaded: true
            });

        }

    },
    /////////////////////////dealers//////////////

    async [GET_PROTACTION_DEALER_ACTION](context, payload) {
     
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_dealer/index?page=${payload.currentPage}`);
        } catch (e) {
         
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(PROTACTION_DEALER_LOADED_MUTATION, {
                protacionDealers: response.data.data,
                protacionDealerLoaded: true
            });
        }

    },

    async [GET_PROTACTION_DEALER_DETAIL_ACTION](context, payload) {
        context.commit(GET_PROTACTION_DEALER_DETAIL_MUTATION, {
         
            protacionDealerDetailLoaded: false
        });
        const id = payload.id;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_dealer/show/${id}?page=${payload.currentPage}`);
        } catch (e) {
            context.commit(GET_PROTACTION_DEALER_DETAIL_MUTATION, {
         
                protacionDealerDetailLoaded: true
            });
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(GET_PROTACTION_DEALER_DETAIL_MUTATION, {
                protacionDealerDetails: response.data.data,
                protacionDealerDetailLoaded: true
            });
        }

    },

    async [GET_DEALERS_DETAIL_ACTION](context, payload) {
        const id = payload.productId;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_dealer/get_dealer/${id}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(GET_DEALERS_DETAIL_MUTATION, {
                protacionDealers: response.data.data,
                protacionDealersLoaded: true
            });
           
        }
    },

    async [SEARCH_PROTACTION_DEALER_DETAIL_ACTION](context, payload) {
        const id = payload.id;
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_dealer/show/${id}?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(GET_PROTACTION_DEALER_DETAIL_MUTATION, {
                protacionDealerDetails: response.data.data,
                protacionDealerDetailLoaded: true
            });
        }

    },

    async [POST_PROTACTION_DEALER_ACTION](context, payload) {
        context.commit(POST_PROTACTION_DEALER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/protaction_dealer/create`, payload);
        } catch (e) {
            context.commit(POST_PROTACTION_DEALER_MUTATION, false)
            console.log(e);
            if (e.response.status == 500) {
                throw 'اسم المندوب مضاف مسبقا للموديل';
            }
            return
        }
        if (response.status == 200) {
            context.commit(POST_PROTACTION_DEALER_MUTATION, false)
            console.log(response.data);

        }

    },

    async [UPDATE_PROTACTION_DEALER_ACTION](context, payload) {
        context.commit(POST_PROTACTION_DEALER_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/protaction_dealer/update`, payload);
        } catch (e) {
            context.commit(POST_PROTACTION_DEALER_MUTATION, false)
            console.log(e);
            if (e.response.status == 500) {
                throw 'اسم المندوب مضاف مسبقا للموديل';
            }
            return
        }
        if (response.status == 200) {
            context.commit(POST_PROTACTION_DEALER_MUTATION, false)
            console.log(response.data);

        }

    },

    async [DELETE_PROTACTION_DEALER_ACTION](context, payload) {
        context.commit(DELETEING_PROTACTION_DEALER_MUTATION, true);
        const id = payload.protactionId;

        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/protaction_dealer/delete/${id}`)
        } catch (e) {
            console.log(e);
            context.commit(DELETEING_PROTACTION_DEALER_MUTATION, false)
            if (e.response.status == 500)
                throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(DELETEING_PROTACTION_DEALER_MUTATION, false)
        }

    },

    async [DELETE_PROTACTION_DEALER_ONE_ACTION](context, payload) {

        context.commit(DELETEING_PROTACTION_DEALER_ONE_MUTATION, true);

        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/protaction_dealer/delete_one/${payload.clientId}`)
        } catch (e) {
            console.log(e);
            context.commit(DELETEING_PROTACTION_DEALER_ONE_MUTATION, false)
            if (e.response.status == 500)
                throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(DELETEING_PROTACTION_DEALER_ONE_MUTATION, false)
        }

    },

    async [SEARCH_PROTACTION_DEALER_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/protaction_dealer/index?search=${payload.text}`)
        } catch (e) {
            if (e.response.status == 500)
                throw ' هناك خطا ما'
        }

        if (response.status = 200) {
            console.log(response.data);
            context.commit(PROTACTION_DEALER_LOADED_MUTATION, {
                protacionDealers: response.data.data,
                protacionDealerLoaded: true
            });

        }

    },

}