import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced: true,
    state() {
        return {
            operatings:[],
            operatingPayments:[],
            finance:[],
            operatingLoaded:false,
            operatingPaymentLoaded:false,
            postOpreating:false,
            deleteing:false,
            postPayment:false,
            financeLoaded:false,
            total:0,
   
            


        }
    },
    mutations,
    getters,
    actions,
}
