import {POST_PAYMENT_RECEIPT_MUTATION,GET_PAYMENT_RECEIPT_MUTATION ,DELETE_PAYMENT_RECEIPT_MUTATION} from "@/store/storeConstants";
export default {
  
  [GET_PAYMENT_RECEIPT_MUTATION](state, payload) {
        state.paymentReceipts = payload.receipts;
        state.paymentLoaded = payload.loaded;
    },  
  [DELETE_PAYMENT_RECEIPT_MUTATION](state, payload) {
        state.deleting = payload;
    },  
    
    [POST_PAYMENT_RECEIPT_MUTATION](state, payload) {
        state.postPayment = payload;

    },   





}