import axiosInstance from "@/service/AxiosInstance";
import { DELETING_DELIVERY_mutations,
    CHNANGE_ِSTATUS_DELIVERY_MUTATION, CHNANGE_ِSTATUS_DELIVERY_ACTION, FILTER_DELIVERY_ORDER_ACTION,  DELETING_DELIVERY_ORDER_mutations, DELETING_DELIVERY_ORDER_ACTION, SEARCH_DELIVERY_ORDER_ACTION,GET_ِALL_DELIVERY_ORDER_MUTATION, POST_DELIVERY_ORDER_MUTATION, GET_ِALL_DELIVERY_ORDER_ACTION, SEARCH_DELIVERY_ACTION,DELETE_DELIVERY_ACTION,UPDATE_DELIVERY_ACTION,GET_ِALL_DELIVERY_MUTATION, GET_DELIVERY_MUTATION, POST_DELIVERY_ORDER_ACTION, POST_DELIVERY_MUTATION, GET_DELIVERY_ACTION, POST_DELIVERY_ACTION, GET_ِALL_DELIVERY_ACTION, BASE_URL } from "@/store/storeConstants";
export default {

    async [GET_DELIVERY_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/delivery/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }


        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                deliveries: response.data.data
            }
            context.commit(GET_DELIVERY_MUTATION, data)
        }


    }, 

    async [GET_ِALL_DELIVERY_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/delivery/all`);
        } catch (error) {
            console.log(error);
            return error
        }


        if (response.status == 200) {
            console.log(response);
            let data = {
                Alldeliveries: response.data.data
            }
            context.commit(GET_ِALL_DELIVERY_MUTATION, data)
        }


    },

    async [POST_DELIVERY_ACTION](context, payload) {
        context.commit(POST_DELIVERY_MUTATION, true)
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/delivery/create`, payload);
        } catch (e) {
            context.commit(POST_DELIVERY_MUTATION, false)
            console.log(e);
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
            return e
        }


        if (response.status == 200) {
            console.log(response);

            context.commit(POST_DELIVERY_MUTATION, false)
        }


    },

    async [UPDATE_DELIVERY_ACTION](context, payload) {
        context.commit(POST_DELIVERY_MUTATION, true)
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/delivery/update`, payload);
        } catch (e) {
            context.commit(POST_DELIVERY_MUTATION, false)
            console.log(e);
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
            return e
        }


        if (response.status == 200) {
            console.log(response);

            context.commit(POST_DELIVERY_MUTATION, false)
        }


    },

    async [DELETE_DELIVERY_ACTION](context, payload) {
       
        context.commit(DELETING_DELIVERY_mutations, true)
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/delivery/delete/${payload}`, );
        } catch (error) {
            context.commit(DELETING_DELIVERY_mutations, false)
            console.log(error);
            return error
        }


        if (response.status == 200) {
            console.log(response);

            context.commit(DELETING_DELIVERY_mutations, false)
        }


    },
    
    async [SEARCH_DELIVERY_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/delivery/index?search=${payload.text}`);
        } catch (e) {

            return e;
        }
        if (response.status == 200) {

            let data = {
                loaded: true,
                deliveries: response.data.data
            }
            context.commit(GET_DELIVERY_MUTATION, data)
        }


    },

/////////////////////////order delivery///////////
    async [GET_ِALL_DELIVERY_ORDER_ACTION](context, payload) {

        let response = ''
        try {
            if(payload.sortDelivery)
            {
                response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?page=${payload.currentPage}&sort_delivery=${payload.sortDelivery}`);

            }else{
                response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?page=${payload.currentPage}`);

            }
        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                deliveryOrders: response.data.data
            }
            context.commit(GET_ِALL_DELIVERY_ORDER_MUTATION, data)
        }

    },  
    
    async [CHNANGE_ِSTATUS_DELIVERY_ACTION](context, payload) {
     
   
        let response = ''
        try {
        response = await axiosInstance.post(`${BASE_URL}/app/delivery-order/received`,payload);

        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
             console.log(response.data);
            context.commit(CHNANGE_ِSTATUS_DELIVERY_MUTATION,true )
        }

    },  
     async [SEARCH_DELIVERY_ORDER_ACTION](context, payload) {

        let response = ''
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?search=${payload.text}`);
        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                deliveryOrders: response.data.data
            }
            context.commit(GET_ِALL_DELIVERY_ORDER_MUTATION, data)
        }

    },   
     async [FILTER_DELIVERY_ORDER_ACTION](context, payload) {
       
        let response = ''
        try {
            if (payload.sortDelivery && payload.sortStatus )
            {
                console.log(898989);
                response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?sort_delivery=${payload.sortDelivery}&sort_filter=${payload.sortStatus}&double=${true}`);

            }else if(payload.sortStatus){
                console.log(1212);
                response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?sort_filter=${payload.sortStatus}`);

            }else if(payload.sortDelivery){
                console.log(payload.sortDelivery);
                response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?sort_delivery=${payload.sortDelivery}`);

            }else{
                console.log(44444);
                response = await axiosInstance.get(`${BASE_URL}/app/delivery-order/index?search=${payload.text}`);

            }
        } catch (error) {
            console.log(error);
            return error
        }

        if (response.status == 200) {
            console.log(response);
            let data = {
                loaded: true,
                deliveryOrders: response.data.data
            }
            context.commit(GET_ِALL_DELIVERY_ORDER_MUTATION, data)
        }

    },   
    
    async [DELETING_DELIVERY_ORDER_ACTION](context, payload) {
    
        context.commit(DELETING_DELIVERY_ORDER_mutations, true)
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/delivery-order/delete/${payload.delivaryId}`);
        } catch (error) {
            context.commit(DELETING_DELIVERY_ORDER_mutations, false)
            console.log(error);
            return error
        }

        if (response.status == 200) {
           console.log(response.data);
           
            context.commit(DELETING_DELIVERY_ORDER_mutations, false)
        }

    }, 

    async [POST_DELIVERY_ORDER_ACTION](context, payload) {
        context.commit(POST_DELIVERY_ORDER_MUTATION, true)
        let response = ''
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/delivery-order/create`,payload);
        } catch (e) {

            context.commit(POST_DELIVERY_ORDER_MUTATION, false)
            console.log(e);
            for (var key in e.response.data.errors) {
                throw e.response.data.errors[key][0];
            }
            return e
        }


        if (response.status == 200) {
            console.log(response);
           
            context.commit(POST_DELIVERY_ORDER_MUTATION, false)
        }

    }, 
}