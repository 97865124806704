import { createStore } from 'vuex'
import auth from './modules/auth/auth'
import product from './modules/product/product'
import dealer from './modules/dealer/dealer'
import client from './modules/client/client'
import region from './modules/region/region'
import protaction from './modules/protaction/protaction'
import bonus from './modules/bonus/bonus'
import order from './modules/order/order'
import wholeSale from './modules/wholeSale/wholeSale'
import wholeSaleOrder from './modules/wholeSaleOrder/wholeSaleOrder'
import ratio from './modules/ratio/ratio'
import delivery from './modules/delivery/delivery'
import saleinvoice from './modules/saleinvoice/saleinvoice'
import fundn from './modules/fundn/fundn'
import dashboard from './modules/dashboard/dashboard'
import notification from './modules/notification/notification'
import purchaseInvoce from './modules/purchaseInvoce/purchaseInvoce'
import payment from './modules/payment/payment'
import catchReceipt from './modules/catchReceipt/catchReceipt'
import dealerOpen from './modules/dealerOpen/dealerOpen'
import budget from './modules/budget/budget'
import fabric from './modules/fabric/fabric'
import operating from './modules/operating/operating'
export default createStore({
   modules:{
    auth,
    product,
    dealer,
    client,
    region,
    protaction,
    bonus,
    order,
    wholeSale,
    wholeSaleOrder,
    ratio,
    delivery,
    notification,
    saleinvoice,
    fundn,
    dashboard,
    purchaseInvoce,
    payment,
    catchReceipt,
    dealerOpen,
    budget,
    fabric,
    operating,
   },
   state:{
    
   }
})
