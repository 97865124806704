import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced: true,
    state() {
        return {
            loadedBudget:false,
            productInvLoaded:false,
            orderInvLoaded:false,
            dealerInvLoaded:false,
            fundnInvLoaded:false,
            dataBudget:[],
            productInvs:[],
            orderInvs:[],
            fundnInvs:[],
            dealerInvs:[],
            delaerprofit: 0,
            wholesaleProfit: 0,
            orderProfit: 0,
            sumOrder: 0,
            fundn: 0,
            sumDebitF: 0,
            sumCreditF: 0,
            dealerLength: 0,
            sumProducts: 0,
            


        }
    },
    mutations,
    getters,
    actions,
}
