import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced: true,
    state() {
        return {
            ordersInternal: [],
            ordersExternal: [],
            returnOrdersInternal: [],
            returnOrdersExternal: [],
            orderDetail: [],
            ordersWaiting: [],
            orderInternalLoaded: false,
            uploadeReceipt: false,
            ordersWaitLoaded: false,
            postReceipt: false,
            deleteingReceipt: false,
            postCharged: false,
            postMoved: false,
            returnOrderInternalLoaded: false,
            orderDetailLoaded: false,
            orderExternalLoaded: false,
            returnOrderExternalLoaded: false,
            postOrder: false,
            changingStatus: false,
            deleteing: false,
            returning: false,
            count: null,
        }
    },
    mutations,
    getters,
    actions,
}
