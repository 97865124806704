import axiosInstance from "@/service/AxiosInstance";

import {DELETE_PURCHASEINVOCE_return_MUTATION,DELETE_PURCHASEINVOCE_return_ACTION, GET_DETAIL_PURCHASEINVOCE_MUTATION, GET_DETAIL_PURCHASEINVOCE_ACTION, FILTER_PURCHASEINVOCE_RETURN_ACTION, DELETE_PURCHASEINVOCE_ACTION, DELETE_PURCHASEINVOCE_MUTATION, RETURN_PURCHASEINVOCE_MUTATION, RETURN_PURCHASEINVOCE_ACTION, GET_PURCHASEINVOCE_RETURN_MUTATION, GET_PURCHASEINVOCE_RETURN_ACTION, SEARCH_PURCHASEINVOCE_ACTION, GET_PURCHASEINVOCE_MUTATION, POST_PURCHASEINVOCE_MUTATION, POST_PURCHASEINVOCE_ACTION, GET_PURCHASEINVOCE_ACTION, BASE_URL } from "@/store/storeConstants";
export default {

    async [GET_PURCHASEINVOCE_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            let data = {
                loaded: true,
                invoices: response.data.data
            }
            context.commit(GET_PURCHASEINVOCE_MUTATION, data)
        }
    },

    async [GET_DETAIL_PURCHASEINVOCE_ACTION](context, payload) {
        console.log('ameer');
        console.log(payload.id);
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/return/detail/${payload.id}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
        console.log(response.data)
            let data = {
                loaded: true,
                purcheaseDetails: response.data.data
            }
            context.commit(GET_DETAIL_PURCHASEINVOCE_MUTATION, data)
        }
    },

    async [GET_PURCHASEINVOCE_RETURN_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/return/index?page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoices: response.data.data
            }
            context.commit(GET_PURCHASEINVOCE_RETURN_MUTATION, data)
        }
    },

    async [FILTER_PURCHASEINVOCE_RETURN_ACTION](context, payload) {

        let response = '';
        try {
            if (payload.sortDealer) {
                response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/return/index?sort_dealer=${payload.sortDealer}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/return/index?search=${payload.text}`);
            }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            console.log(1232323);
            let data = {
                loaded: true,
                invoices: response.data.data
            }
            context.commit(GET_PURCHASEINVOCE_RETURN_MUTATION, data)
        }
    },

    async [POST_PURCHASEINVOCE_ACTION](context, payload) {
        context.commit(POST_PURCHASEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/purchase_invoce/create`, payload);
        } catch (error) {
            context.commit(POST_PURCHASEINVOCE_MUTATION, false)
            console.log(error);
            for (var key in error.response.data.errors) {
                console.log(error.response.data.errors);
                throw error.response.data.errors[key][0];
            }
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_PURCHASEINVOCE_MUTATION, false)
        }
    },

    async [DELETE_PURCHASEINVOCE_ACTION](context, payload) {
        context.commit(DELETE_PURCHASEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/purchase_invoce/delete/${payload.id}`);
        } catch (error) {
            context.commit(DELETE_PURCHASEINVOCE_MUTATION, false)
            console.log(error);

            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETE_PURCHASEINVOCE_MUTATION, false)
        }
    },  
    
    async [DELETE_PURCHASEINVOCE_return_ACTION](context, payload) {
        context.commit(DELETE_PURCHASEINVOCE_return_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/purchase_invoce/return/delete-restore/${payload.id}`);
        } catch (error) {
            console.log(error);
            context.commit(DELETE_PURCHASEINVOCE_return_MUTATION, false)
          

            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(DELETE_PURCHASEINVOCE_return_MUTATION, false)
        }
    },

    async [RETURN_PURCHASEINVOCE_ACTION](context, payload) {
        context.commit(RETURN_PURCHASEINVOCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/purchase_invoce/restore`, payload);
        } catch (error) {
            context.commit(RETURN_PURCHASEINVOCE_MUTATION, false)
            console.log(error);
            if (error.response.status = 408)
                throw error.response.data.message;
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(RETURN_PURCHASEINVOCE_MUTATION, false)
        }
    },

    async [SEARCH_PURCHASEINVOCE_ACTION](context, payload) {
        let response = '';
        try {
            if (payload.sortDealer) {
                response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/index?sort_dealer=${payload.sortDealer}`);

            } else if (payload.sortProduct) {

                response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/index?sort_product=${payload.sortProduct}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/purchase_invoce/index?search=${payload.text}`);

            }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                invoices: response.data.data
            }
            context.commit(GET_PURCHASEINVOCE_MUTATION, data)
        }
    },



}