import {GET_REPORT_DEALER_MUTATION ,POST_DEALER_OPEN_MUTATION,GET_DEALER_OPEN_MUTATION ,DELETE_DEALER_OPEN_MUTATION} from "@/store/storeConstants";
export default {
  
  [GET_DEALER_OPEN_MUTATION](state, payload) {
        state.dealerOpenings = payload.receipts;
        state.dealerOpeninLoaded = payload.loaded;
    },  
  [DELETE_DEALER_OPEN_MUTATION](state, payload) {
        state.deleting = payload;
    },  
    
    [POST_DEALER_OPEN_MUTATION](state, payload) {
        state.postOpen = payload;

    },  
      [GET_REPORT_DEALER_MUTATION](state, payload) {
        state.reportData = payload.reports;
        state.reportLoaded = payload.loaded;
        state.sumCredit = payload.sumCredit;
        state.sumDebit = payload.sumDebit;
        state.openingPalance = payload.openingPalance;


    },   





}