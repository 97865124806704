import axiosInstance from "@/service/AxiosInstance";
import {UPDATE_PROVINCE_ACTION,GET_PROVINCE_REGION_MUTATION,GET_PROVINCE_REGION_ACTION, GET_ALL_REGION_INERNAL_MUTATION, GET_ALL_REGION_INERNAL_ACTION, POST_PROVINCE_MUTATION, POST_PROVINCE_ACTION, DELETING_PROVINCE_MUTATION, DELETE_PROVINCE_ACTION, UPDATE_EXTERNAL_REGION_ACTION, POST_INTERNAL_REGION_MUTATION, POST_INTERNAL_REGION_ACTION, POST_EXTERNA_REGION_ACTION, POST_EXTERNAL_REGION_MUTATION, UPDATE_INTERNAL_REGION_ACTION, SEARCH_REGION_EXTERNAL_ACTION, SEARCH_PROVINCE_ACTION, PROVINCE_LOADED_MUTATION, GET_PROVINCE_ACTION, DELETING_REGION_MUTATION, REGION_EXTERNAL_LOADED_MUTATION, DELETE_REGION_ACTION, GET_REGION_EXTERNAL_ACTION, GET_REGION_INERNAL_ACTION, POST_REGION_ACTION, POST_REGION_MUTATION, SEARCH_REGION_INERNAL_ACTION, REGION_INERNAL_LOADED_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_REGION_INERNAL_ACTION](context, payload) {
        context.commit(REGION_INERNAL_LOADED_MUTATION, {loaded:false})
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/region/internal?page=${payload.currentPage}`);
        } catch (e) {
            context.commit(REGION_INERNAL_LOADED_MUTATION, {loaded:true})
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                regionsInternal: response.data.data
            }
            context.commit(REGION_INERNAL_LOADED_MUTATION, data)
        }

    },
    async [GET_ALL_REGION_INERNAL_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/region/internal/get_all`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log('smeeer');
            console.log(response.data);
            let data = {
                AllRegionsInternal: response.data.data,
                AllRegionsLoaded: true
            }
            context.commit(GET_ALL_REGION_INERNAL_MUTATION, data)
        }

    },
    async [GET_REGION_EXTERNAL_ACTION](context, payload) {
        context.commit(REGION_EXTERNAL_LOADED_MUTATION, {regionsExtrenal:false})
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/region/external?page=${payload.currentPage}`);
        } catch (e) {
            context.commit(REGION_EXTERNAL_LOADED_MUTATION, {regionsExtrenal:true})
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            let data = {
                loaded: true,
                regionsExtrenal: response.data.data
            }
            context.commit(REGION_EXTERNAL_LOADED_MUTATION, data)
        }

    },

    async [GET_PROVINCE_ACTION](context, payload) {
       
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/province/index`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            let data = {
                loaded: true,
                provinces: response.data.data
            }
            context.commit(PROVINCE_LOADED_MUTATION, data)
        }
    }, 
    
    async [GET_PROVINCE_REGION_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/province/get_region/${payload}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                provinceRegions: response.data.data
            }
            context.commit(GET_PROVINCE_REGION_MUTATION, data)
        }
    },  
    async [UPDATE_PROVINCE_ACTION](context, payload) {
        context.commit(POST_PROVINCE_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/province/update`,payload);
        } catch (e) {
            context.commit(POST_PROVINCE_MUTATION, false)
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            context.commit(POST_PROVINCE_MUTATION, false)
            console.log(response.data);
           
        }
    },
    async [SEARCH_REGION_INERNAL_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/region/internal?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                regionsInternal: response.data.data
            }
            context.commit(REGION_INERNAL_LOADED_MUTATION, data)
        }

    },
    async [SEARCH_REGION_EXTERNAL_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/region/external?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                regionsExtrenal: response.data.data
            }
            context.commit(REGION_EXTERNAL_LOADED_MUTATION, data)
        }

    },
    async [SEARCH_PROVINCE_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/region/province?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                regionsExtrenal: response.data.data
            }
            context.commit(PROVINCE_LOADED_MUTATION, data)
        }

    },
    async [UPDATE_INTERNAL_REGION_ACTION](context, payload) {
        context.commit(POST_INTERNAL_REGION_MUTATION, true);
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/region/internal/update`, payload);
        } catch (e) {
            context.commit(POST_INTERNAL_REGION_MUTATION, false);
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            return context.commit(POST_INTERNAL_REGION_MUTATION, false);

        }

    },
    async [UPDATE_EXTERNAL_REGION_ACTION](context, payload) {
        context.commit(POST_EXTERNAL_REGION_MUTATION, true);
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/region/external/update`, payload);
        } catch (e) {
            context.commit(POST_EXTERNAL_REGION_MUTATION, false);
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            return context.commit(POST_EXTERNAL_REGION_MUTATION, false);

        }

    },
    async [POST_INTERNAL_REGION_ACTION](context, payload) {
        context.commit(POST_INTERNAL_REGION_MUTATION, true);
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/region/internal/create`, payload);
        } catch (e) {
            context.commit(POST_INTERNAL_REGION_MUTATION, false);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_INTERNAL_REGION_MUTATION, false)
        }

    },
    async [POST_EXTERNA_REGION_ACTION](context, payload) {
        context.commit(POST_EXTERNAL_REGION_MUTATION, true);
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/region/internal/create`, payload);
        } catch (e) {
            context.commit(POST_EXTERNAL_REGION_MUTATION, false);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_EXTERNAL_REGION_MUTATION, false)
        }

    },
    async [POST_PROVINCE_ACTION](context, payload) {
        context.commit(POST_PROVINCE_MUTATION, true);
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/province/create`, payload);
        } catch (e) {
            context.commit(POST_PROVINCE_MUTATION, false);
            for (var key in e.response.data.errors) {
                console.log(e.response.data.errors);
                throw e.response.data.errors[key][0];
            }
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_PROVINCE_MUTATION, false)
        }

    },
    async [DELETE_REGION_ACTION](context, payload) {
        context.commit(DELETING_REGION_MUTATION, true)
        const id = payload.regionId;
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/region/internal/delete/${id}`)
        } catch (e) {
            context.commit(DELETING_REGION_MUTATION, false)
            return console.log(e);
        }
        if (response.status = 200) {
            console.log(response.data.name);
            context.commit(DELETING_REGION_MUTATION, false)
            return;
        }

    },
    async [DELETE_PROVINCE_ACTION](context, payload) {
        context.commit(DELETING_PROVINCE_MUTATION, true)
        const id = payload.id;
        let response = ''
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/province/delete/${id}`)
        } catch (e) {
            context.commit(DELETING_PROVINCE_MUTATION, false)
            if (e.response.status == 500)
                throw 'المحافظة مستخدمة لايمكن حذفها'
        }
        if (response.status = 200) {
            console.log(response.data);
            context.commit(DELETING_PROVINCE_MUTATION, false)
            return;
        }

    },
    async [SEARCH_PROVINCE_ACTION](context, payload) {
        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/province/index?search=${payload.text}`);
        } catch (e) {
            console.log(e);
            return e;
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                provinces: response.data.data
            }
            context.commit(PROVINCE_LOADED_MUTATION, data)
        }

    },
}