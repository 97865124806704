import axiosInstance from "@/service/AxiosInstance";

import { RESET_REPORT_DEALER_ACTION, GET_REPORT_DEALER_ACTION, GET_DEALER_OPEN_ACTION, GET_DEALER_OPEN_MUTATION, POST_DEALER_OPEN_MUTATION, POST_DEALER_OPEN_ACTION, FILTER_DEALER_OPEN_MUTATION, UPDATE_DEALER_OPEN_ACTION, DELETE_DEALER_OPEN_MUTATION, DELETE_DEALER_OPEN_ACTION, GET_REPORT_DEALER_MUTATION, BASE_URL } from "@/store/storeConstants";
export default {
    async [GET_DEALER_OPEN_ACTION](context, payload) {
        let response = '';
        try {
            if (payload.sortDealer) {
                response = await axiosInstance.get(`${BASE_URL}/app/dealer_open/index?page=${payload.currentPage}&sort_dealer=${payload.sortDealer}`);

            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/dealer_open/index?page=${payload.currentPage}`);

            }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            let data = {
                loaded: true,
                receipts: response.data.data
            }
            context.commit(GET_DEALER_OPEN_MUTATION, data)
        }
    },

    async [POST_DEALER_OPEN_ACTION](context, payload) {
        context.commit(POST_DEALER_OPEN_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/dealer_open/create`, payload);
        } catch (error) {
            context.commit(POST_DEALER_OPEN_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            context.commit(POST_DEALER_OPEN_MUTATION, false)
        }
    },

    async [DELETE_DEALER_OPEN_ACTION](context, payload) {
        context.commit(DELETE_DEALER_OPEN_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.delete(`${BASE_URL}/app/dealer_open/delete/${payload.id}`);
        } catch (error) {
            context.commit(DELETE_DEALER_OPEN_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(DELETE_DEALER_OPEN_MUTATION, false)
        }
    },

    async [UPDATE_DEALER_OPEN_ACTION](context, payload) {
        context.commit(POST_DEALER_OPEN_MUTATION, true)
        let response = '';
        try {
            response = await axiosInstance.post(`${BASE_URL}/app/dealer_open/update`, payload);
        } catch (error) {
            context.commit(POST_DEALER_OPEN_MUTATION, false)
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);

            context.commit(POST_DEALER_OPEN_MUTATION, false)
        }
    },

    async [GET_REPORT_DEALER_ACTION](context, payload) {

        let response = '';
        try {
            response = await axiosInstance.get(`${BASE_URL}/app/dealer_finance/report?start_date=${payload.startDate}&end_date=${payload.endDate}&dealer_id=${payload.dealerId}&page=${payload.currentPage}`);
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                reports: response.data.data,
                sumCredit: response.data.sumCredit,
                sumDebit: response.data.sumDebit,
                openingPalance: response.data.openingPalance,
            }

            context.commit(GET_REPORT_DEALER_MUTATION, data)
        }
    },

    async [RESET_REPORT_DEALER_ACTION](context, payload) {

        let data = {
            loaded: true,
            reports: [],
            sumCredit: 0,
            sumDebit: 0,
            openingPalance: 0,
        }

        context.commit(GET_REPORT_DEALER_MUTATION, data)

    },

    async [FILTER_DEALER_OPEN_MUTATION](context, payload) {

        let response = '';
        try {
            if (payload.sortDealer) {
                response = await axiosInstance.get(`${BASE_URL}/app/dealer_open/index?sort_dealer=${payload.sortDealer}`);
            } else {
                response = await axiosInstance.get(`${BASE_URL}/app/dealer_open/index?search=${payload.text}`);
            }
        } catch (error) {
            console.log(error);
            return error
        }
        if (response.status == 200) {
            console.log(response.data);
            let data = {
                loaded: true,
                receipts: response.data.data
            }
            context.commit(GET_DEALER_OPEN_MUTATION, data)
        }
    },

}