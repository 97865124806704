<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useRouter } from "vue-router";
import store from "@/store";
import moment from "moment";
import {
  Get_UNREAD_NOTIFICATIONS_ACTION,
  USER_LOG_OUT,
  MARK_AS_READ_NOTIFICATIONS_ACTION,
} from "@/store/storeConstants";
import route from "@/router";
const { layoutConfig, onMenuToggle } = useLayout();
import mit from "@/service/External";
const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const loading = ref(false);
const router = useRouter();

onMounted(() => {
  bindOutsideClickListener();
  loading.value = true;
  store.dispatch(`notification/${Get_UNREAD_NOTIFICATIONS_ACTION}`).then(() => {
    loading.value = false;
  });
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});
const username = computed(() => {
  store.state["auth"].fullname;
});

const logout = () => {
  store.dispatch(`auth/${USER_LOG_OUT}`);
};
const goToAdminRoute = ()=>{
  router.push({name:'admin'})
}
const logoUrl = computed(() => {
  return require(`@/assets/images/${
    layoutConfig.darkTheme.value ? "logo-white" : "logo-dark"
  }.svg`);
});

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
  topbarMenuActive.value = false;
  mit.emit('configState')
  // router.push("/documentation");
};
const topbarMenuClasses = computed(() => {
  return {
    "layout-topbar-menu-mobile-active": topbarMenuActive.value,
  };
});
const imageUrl = computed(() => {
  return require(`@/assets/images/profile.png`);
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const menu = ref();
const menuRight = ref();
const items = ref([
  {
    label: "تحديد الكل كـمقروء",
    icon: "pi  pi-file-edit",
    command: () => {
      loading.value = true;
      store
        .dispatch(`notification/${MARK_AS_READ_NOTIFICATIONS_ACTION}`)
        .then(() => {
          store.dispatch(`notification/${Get_UNREAD_NOTIFICATIONS_ACTION}`);
          loading.value = false;
        });
    },
  },
  {
    label: "عرض كل الاشعارات",
    icon: "pi pi-copy",
    command: () => {
      route.push({ name: "notifications" });
    },
  },
]);

const itemNull = ref([
  {
    data: {
      label: "لايوجد اشعارات للعرض",
      icon: "pi  pi-folder-open",
      color: "#60a5fa",
    },
  },
]);

const dataFormated = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

const onRightClick = (event) => {
  menuRight.value.show(event);
};

const toggle = (event) => {
  menu.value.toggle(event);
};

const down = ref(false);
const counter = ref(0);

const toggleNotifi = () => {
  down.value = !down.value;
};

const outside = () => {
  if (down.value) {
    return (down.value = false);
  } else {
  }
};

const VClickOutside = {
  mounted(el, binding) {
    el.__ClickOutsideHandler__ = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };

    document.body.addEventListener("click", el.__ClickOutsideHandler__);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.__ClickOutsideHandler__);
  },
};
</script>



<template>
  <div class="card" v-if="store.state['auth'].logingOut">
    <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
  </div>

  <div class="layout-topbar" v-if="!store.state['auth'].logingOut">
    <router-link to="/" class="layout-topbar-logo">
      <img :src="logoUrl" alt="logo" />
      <span>ALLAHAM</span>
    </router-link>

    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="onTopBarMenuButton()"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <div class="flex align-items-center">
        <Avatar
          @contextmenu="onRightClick"
          v-show="!loading"
          :image="`${
            store.state['auth'].image
              ? 'https://app.f-dahab.com/uploads/users/' +
                store.state['auth'].image
              : imageUrl
          }`"
          class="mr-2"
          size="large"
          shape="circle"
        />
        <p style="color: #90848a" >
          {{ store.state["auth"].fullname }}
        </p>

        <ContextMenu ref="menuRight" :model="items" />
      </div>
      <div class="outSide " v-click-outside="outside">
        
        <div
          style="width: 20%; padding-top: 1%; text-align: center"
          v-show="loading"
        >
          <ProgressSpinner
            strokeWidth="1"
            animationDuration=".5s"
            aria-label="Custom ProgressSpinner"
            style="height: 40px"
          />
        </div>
        <Avatar
          @contextmenu="onRightClick"
          v-show="!loading"
          icon="pi pi-bell"
          class="ml-2 "
          shape="circle"
          size="large"
          v-badge.danger="store.state['notification'].totalNotifiction"
          @click="toggleNotifi()"
          v-tooltip.bottom="{
            value: 'الاشعارات ',
            pt: {
              arrow: {
                style: {
                  borderBottomColor: 'var(--primary-color)',
                },
              },
              text: 'bg-primary font-medium',
            },
          }"
          style="cursor: pointer"
        />
        <div :class="`notifi-box ${down?'active_notify':''}`" >
          <h2>
            Notifications
            <span>{{ store.state["notification"].totalNotifiction }}</span>
          </h2>
          <router-link
            v-for="item in store.state['notification'].notifications"
            :key="item.id"
            v-slot="{ href, navigate }"
            :to="{ name: item.data.route }"
            custom
          >
            <a v-ripple :href="href" @click="navigate" class="d-flex gap-3">
              <div class="notifi-item">
                <Avatar
                  :icon="item.data.icon"
                  shape="circle"
                  :style="`  background-color:${item.data.color};
                      color: #f0f0f0;
                      min-width: 35px;
                      margin:10px;
                     `"
                />
                <div class="text">
                  <h6 >{{ item.data.message }}</h6>
                  <p>{{ dataFormated(item.created_at) }}</p>
                </div>
              </div>
            </a>
          </router-link>
        </div>
      </div>
      <button
        v-tooltip.bottom="{
          value: 'الملف الشخصي',
          pt: {
            arrow: {
              style: {
                borderBottomColor: 'var(--primary-color)',
              },
            },
            text: 'bg-primary font-medium',
          },
        }"
        @click="goToAdminRoute()"
        class="p-link layout-topbar-button"
      >
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>
      <button
        @click="onSettingsClick()"
        v-tooltip.bottom="{
          value: 'الاعدادات',
          pt: {
            arrow: {
              style: {
                borderBottomColor: 'var(--primary-color)',
              },
            },
            text: 'bg-primary font-medium',
          },
        }"
        class="p-link layout-topbar-button"
      >
        <i class="pi pi-cog"></i>
        <span>Settings</span>
      </button>
      <button
        @click="logout()"
        v-tooltip.bottom="{
          value: 'تسجيل الخروج',
          pt: {
            arrow: {
              style: {
                borderBottomColor: 'var(--primary-color)',
              },
            },
            text: 'bg-primary font-medium',
          },
        }"
        class="p-link layout-topbar-button"
      >
        <i class="pi pi-power-off"></i>
        <span>تسجيل الخروج</span>
      </button>
    </div>
  </div>
</template>

<style scoped >
.icon {
  cursor: pointer;
  margin-right: 50px;
  line-height: 60px;
}
.icon span {
  background: #f00;
  padding: 7px;
  border-radius: 50%;
  color: #fff;
  vertical-align: top;
  margin-left: -25px;
}
.icon img {
  display: inline-block;
  width: 40px;
  margin-top: 20px;
}
.icon:hover {
  opacity: 0.7;
}

.logo {
  flex: 1;
  margin-left: 50px;
  color: #eee;
  font-size: 20px;
  font-family: monospace;
}

.notifi-box {
  width: 420px;
	height: 400px;
  overflow-y: scroll;
	position: absolute;
  background-color: #071529;
	top: 63px;
  display:none;
	right: 35px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notifi-box h2 {
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
  color: #ededed;
}
.notifi-box h2 span {
  color: #f00;
}
.notifi-item {
  display: flex;
  border-bottom: 1px solid #949494;
  padding: 15px 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.notifi-item:hover {
  background-color: #02714c;
}

.notifi-item img {
  display: block;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
}
.notifi-item .text h6{
  color: #ffffff;
  font-size: 13px;
  margin-top: 10px;
}
.notifi-item .text p {
  color: #f1f1f1;
  font-size: 12px;
}

/* @media only screen and (max-width: 600px) {
  .menu_notification {
    width: 200px;
    height: 150px;
    font-size: 12px;
    overflow-y: scroll;
  }
} */
.active_notify{
  height:512px;
  opacity:1;
  
  display:inline;

}
</style>
