import {DELETE_PURCHASEINVOCE_return_MUTATION, GET_DETAIL_PURCHASEINVOCE_MUTATION, DELETE_PURCHASEINVOCE_MUTATION, RETURN_PURCHASEINVOCE_MUTATION, GET_PURCHASEINVOCE_RETURN_MUTATION, GET_PURCHASEINVOCE_MUTATION, POST_PURCHASEINVOCE_MUTATION } from "@/store/storeConstants";
export default {
    [GET_PURCHASEINVOCE_MUTATION](state, payload) {
        state.purchaseInvoices = payload.invoices;
        state.purchaseInvoicesLoaded = payload.loaded;
    },
    [GET_PURCHASEINVOCE_RETURN_MUTATION](state, payload) {
        state.purchaseInvoicesReturn = payload.invoices;
        state.purchaseInvoicesReturnLoaded = payload.loaded;
    },
    [POST_PURCHASEINVOCE_MUTATION](state, payload) {
        state.postInvoce = payload;
    },
    [RETURN_PURCHASEINVOCE_MUTATION](state, payload) {
        state.postReturn = payload;
    },
    [DELETE_PURCHASEINVOCE_MUTATION](state, payload) {
        state.deleting = payload;
    },   
    
    [DELETE_PURCHASEINVOCE_return_MUTATION](state, payload) {
        state.deleting = payload;
    },
    [GET_DETAIL_PURCHASEINVOCE_MUTATION](state, payload) {
        state.purchaseInvoiceDetails = payload.purcheaseDetails;
        state.purchaseInvoiceDetailLoaded = payload.loaded;
    },



}