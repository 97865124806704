import {POST_CATCH_RECEIPT_MUTATION, DELETE_CATCH_RECEIPT_MUTATION,GET_CATCH_RECEIPT_MUTATION} from "@/store/storeConstants";
export default {
  
  [GET_CATCH_RECEIPT_MUTATION](state, payload) {
        state.catchReceipts = payload.receipts;
        state.catchReceiptsLoaded = payload.loaded;
    },  
  [DELETE_CATCH_RECEIPT_MUTATION](state, payload) {
        state.deleting = payload;
    },  
    
    [POST_CATCH_RECEIPT_MUTATION](state, payload) {
        state.postCatch = payload;

    },   





}