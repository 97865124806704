
export class Validation {


    static checkEmail(email) {
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!email.match(mailFormat)) {
            return false
        }
        return true
    }
    static checkPassword(vlaue) {
        if (vlaue.length < 6) {
            return false
        }
        return true
    }
    static checkEmpty(val) {
        if (val == '') {
            return false
        }
        return true
    }
    static getErrorMessage(errorCode) {
        switch (errorCode) {
            case 'The email has already been taken.':
                return 'Email already exists';
            case 'Unauthorized':
                return 'يرجى التحقق من الايميل وكلمة المرور';
            case 'The code has already been taken.':
                return 'رقم الموديل موجود مسبقا';
            case 'The name has already been taken.':
                return 'اسم الموديل موجود مسبقا'
        }
    }

}