import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            bonusClients: [],
            bonusClientDetails: [],
            bonusDetailCleints: [],
            protacionBonusId: 0,
            bonusClientLoaded:false,
            bonusClientDetailLoaded:false,
            bonusDetailClientsLoaded:false,
            postBonus:false,
            deleteing:false,
 

        }
    },
    mutations,
    getters,
    actions,
}
