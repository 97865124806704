import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
export default {
    namespaced:true ,
    state(){
        return{
            notifications : [],
            AllNotifications : [],
            notificationLoaded : false,
            AllNotificationLoaded : false,
            deleting : false,
            totalNotifiction:0,


        }
    },
    mutations,
    getters,
    actions,
}
